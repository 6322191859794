
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from './../../shared/shared.module';
import { SobreComponent } from './sobre.component';
import { AppService } from 'app/_services/app.service';
import { SobreRoutingModule } from './sobre.routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        SobreRoutingModule,
        ModalModule.forRoot(),
        SharedModule
    ],
    exports: [],
    declarations: [
        SobreComponent
    ],
    providers: [
        AppService
    ]
})
export class SobreModule { }