import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from "ngx-bootstrap/modal";

import { Booking } from 'app/_models/booking';


import { DatePickerModule } from 'app/shared/ng2-datepicker/ng2-datepicker.module';

import { GroupbyService } from "app/_services/groupby.service";
import { DataService } from "app/_services/data.service";
import { ItemAgendamentoModule } from "app/cliente/booking/solicitar-booking/step3-agendamento-coleta/lista-agendamento/item-agendamento/item-agendamento.module";
import { DatepickerService } from "app/_services/datepicker.service";
import { TextMaskModule } from "angular2-text-mask";
import { FiltrosPropostasComerciaisService } from "app/_services/filtros-propostas-comerciais.service";

import { UsuarioService } from 'app/_services/usuario.service';
import { CotacaoService } from 'app/_services/cotacao.service';
import { ParametroService } from 'app/_services/parametro.service';
import { UploadArquivoService } from 'app/_services/upload-arquivo.service';
import { AppService } from 'app/_services/app.service';
import { ProgramacaoNaviosService } from 'app/_services/programacao-navios.service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MascaraModule } from "app/shared/mascara/mascara.module";
import { BookingRequest } from 'app/_models/booking-request';
import { PropostaComercial } from 'app/_models/proposta-comercial';
import { AnalyticsService } from 'app/_services/analytics.service';
import { BookingService } from 'app/_services/booking.service';
import { PropostaComercialService } from 'app/_services/proposta-comercial.service';
import { MultiselectDropdownModule } from 'app/shared/multiselect-dropdown/multiselect-dropdown.module';
import { SharedModule } from 'app/shared/shared.module';
import { BookingRoutingModule } from '../../booking.routing.module';






@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TextMaskModule,
        MultiselectDropdownModule,
        DatePickerModule,
        CurrencyMaskModule,
        SharedModule,
        ItemAgendamentoModule,
        ModalModule.forRoot(),
        BookingRoutingModule,
        MascaraModule
    ],
    exports: [MultiselectDropdownModule],
    declarations: [
        
        
    ],
    providers: [
        AppService,
        BookingService,
        PropostaComercialService,
        BookingRequest,
        Booking,
        GroupbyService,
        DataService,
        DatepickerService,
        PropostaComercial,
        FiltrosPropostasComerciaisService,
        AnalyticsService,
        UsuarioService,
        CotacaoService,
        ParametroService,
        UploadArquivoService,
        ProgramacaoNaviosService
    ],
})
export class RelatorioPixModule {

}
