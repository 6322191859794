import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Booking } from 'app/_models/booking';
import { Cliente } from "app/_models/cliente";
import { Endereco } from './../../../../_models/endereco';
import { Municipio } from "app/_models/municipio";
import { TipoContainer } from '../../../../_models/tipo-container';
import { ClienteRequest } from "app/_models/cliente-request";
import { ServicoProposta } from '../../../../_models/servico-proposta';
import { PropostaComercial } from '../../../../_models/proposta-comercial';

import { AppService } from 'app/_services/app.service';
import { SharedService } from '../../../../_services/shared.service';
import { PropostaComercialService } from '../../../../_services/proposta-comercial.service';

import swal from 'sweetalert2';
import { BaseComponent } from './../../../../shared/base.component';
import { EnderecoComponent } from 'app/shared/endereco/endereco.component';
import { Feriado } from 'app/_models/feriado';

@Component({
  selector: 'step2-detalhes-operacao',
  templateUrl: './step2-detalhes-operacao.component.html',
  styleUrls: ['./step2-detalhes-operacao.component.scss']
})
export class Step2DetalhesOperacaoComponent extends BaseComponent implements OnInit{

  @Input() novoBooking: boolean;
  @Input() edicaoBooking: boolean;
  @Input() booking: Booking;
  @Input() duplicar: boolean;
  @Input() loadingProposta: boolean = false;
  @Input() qtdeContainersInicial: number;
  @Input() qtdeHorasAgendamentoPA: number;
  @Input() qtdeHorasAgendamentoPO: number;
  @Input() feriados: Feriado[];
  @Output() quantidadeContainersAlterada: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(EnderecoComponent, {static: true}) endereco: EnderecoComponent;

  municipios: Municipio[] = [];
  clientes: Cliente[];
  cliente: Cliente;
  loadingClientes: boolean = false;
  loadingMunicipios: boolean = false;
  proposta: PropostaComercial = new PropostaComercial();
  servicosProposta: ServicoProposta[] = [];
  telefoneEmbarcador: string;
  enderecoEmbSelecionado: Endereco;

  _municipioEmb: Municipio;
  get municipioEmb(): Municipio {
    return this._municipioEmb;
  }
  set municipioEmb(value: Municipio) {
    this._municipioEmb = value;
    this.booking.municipioEmb = value.descricao;
    this.booking.idMunicEmb = value.codigo;
    this.booking.idMunicipioEmb = value.codigo;
    this.booking.ufEmb = value.uf;
  }

  constructor(private _propostaService: PropostaComercialService,
    private _sharedService: SharedService,
    private _appService: AppService,
    private _sanitizer: DomSanitizer) {
    super();
    this.proposta.tipoDeContainer = new TipoContainer();
  }

  ngOnInit(): void {
    this.ObterMunicipios();
  }

  autocompleListFormatter = (data: any) => {
    let html = `<span>${data.Descricao}/${data.UF}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
    //return html;
  }

  get bloquearContatoEmbarcador(): boolean{
    //  var temEnd: boolean = ((this.cliente != null ) && (this.cliente.Enderecos != null) && (this.cliente.Enderecos.length > 0)); //&& this.cliente.Logradouro != null) );
    //  return (temEnd || (this.proposta.logradouroEmb!= "" && this.proposta.logradouroEmb!= undefined));

    var propostaTemEnderecoEmb: boolean = (this.proposta.logradouroEmb!= "" && this.proposta.logradouroEmb!= undefined);
    var encontrouClienteEndereco: boolean = ((this.cliente != null ) && (this.cliente.enderecos != null) && (this.cliente.enderecos.length > 0));

    return propostaTemEnderecoEmb || (!propostaTemEnderecoEmb && encontrouClienteEndereco);
  }

  get bloquearCNPJIEEmbarcador(): boolean{
    return (this.proposta.logradouroEmb!= "" && this.proposta.logradouroEmb!= undefined);
  }

  propostaAlterada(proposta: PropostaComercial){
    if (!proposta)
      return;

    var naoAlterouProposta = (this.proposta) && ((proposta.numProposta == this.proposta.numProposta) && (proposta.idTrecho == this.proposta.idTrecho))

    this._sharedService.copyProperties(proposta, this.proposta);
    this.proposta.servicosDaProposta = proposta.servicosDaProposta;
    this.clientes = null;

    if ((!this.duplicar) && (this.novoBooking) && (!naoAlterouProposta)) {
        this.preencherBookingComDadosDaProposta();
    }
  }

  public ObterMunicipios() {
    this.loadingMunicipios = true;
    this._sharedService.getMunicipios()
      .subscribe(m => {
        this.municipios = m;
        this.loadingMunicipios = false;
      });
  }

  public ObterClientes() {
    if (this.edicaoBooking)
      return;

    this.loadingClientes = true;
    this.clientes = null;
    var filtroCliente = new ClienteRequest();
    filtroCliente.cnpj = this.booking.cnpjEmb;
    filtroCliente.inscricaoEstadual = this.proposta.ieEmb;
    this._sharedService.getClientes(filtroCliente)
      .subscribe(c => {
        this.loadingClientes = false;
        if (c == null) {
          this.cliente = null;
          this.clientes = null;
          this.booking.municipioEmb = null;
          this.booking.ufEmb = null;
          this.carregaClienteNoBooking();
          return;
        }

        this.clientes = c.filter(x => x.cnpj == filtroCliente.cnpj);
        if (this.clientes && this.clientes.length == 0)
          this.clientes = null;
        this.cliente = ((this.clientes != undefined) && (this.clientes.length == 1)) ? this.clientes[0] : null;
        this.carregaClienteNoBooking();
      });
  }

  ieEmbarcadorSelecionada(ie: string){
    this.cliente = this.clientes ? this.clientes.find(c => c.inscricaoEstadual == ie) : null;
    this.carregaClienteNoBooking();
  }

  atualizaNomeEmbarcador() {
    this.booking.nomeEmbarcador = this.booking.nomeEmb;
  }

  atualizaNumeroEmbarcador() {
    this.booking.numeroEmb = this.booking.numeroEndEmb;
  }

  atualizaEmailEmbarcador() {
    this.booking.emailEmb = (!this.booking.emailEmb) ? this.booking.emailEmb : this.booking.emailEmb.toLowerCase();
    this.booking.email = this.booking.emailEmb;
  }

  carregaClienteNoBooking(){
    this.booking.ieEmb = this.cliente ? this.cliente.inscricaoEstadual : '';
    this.booking.nomeEmb = this.cliente ? this.cliente.nome : '';
    this.booking.nomeEmbarcador = this.cliente ? this.cliente.nome : '';
    // >>>>>>>>>>>>>>>>>> Gylci 22/01 - Melhorias
    if (this.cliente && this.cliente.enderecos != undefined && this.cliente.enderecos.length > 0)
    {
      this.booking.embarcador = this.cliente;
      this.enderecoEmbSelecionado = this.cliente.enderecos[0];
      this.booking.cepEmb = this.cliente.enderecos[0].cep;
      this.booking.logradouroEmb = this.cliente.enderecos[0].logradouro;
      this.booking.numeroEndEmb = this.cliente.enderecos[0].numero;
      this.booking.numeroEmb = this.cliente.enderecos[0].numero;
      this.booking.complementoEmb = this.cliente.enderecos[0].complemento;
      this.booking.bairroEmb = this.cliente.enderecos[0].bairro;
      if (!this.booking.municipioEmb)
        this.booking.municipioEmb = this.cliente.enderecos[0].municipio;
      if (!this.booking.ufEmb)
        this.booking.ufEmb = this.cliente.enderecos[0].uf;
      if (!this.booking.idMunicEmb)
        this.booking.idMunicEmb = this.cliente.enderecos[0].idMunicipio;
    }
    else {
      this.booking.cepEmb = '';
      this.booking.logradouroEmb = '';
      this.booking.numeroEndEmb = '';
      this.booking.numeroEmb = '';
      this.booking.complementoEmb = '';
      this.booking.bairroEmb = '';
      if (!this.booking.municipioEmb)
        this.booking.municipioEmb = '';
      if (!this.booking.ufEmb)
        this.booking.ufEmb = '';
      if (!this.booking.idMunicEmb)
        this.booking.idMunicEmb = null;
    }
    /* >>>>>>>>>>>>>>>>>> Gylci 22/01 - Melhorias
    this.booking.cepEmb = this.cliente ? this.cliente.CEP : '';
    this.booking.logradouroEmb = this.cliente ? this.cliente.Logradouro : '';
    this.booking.numeroEndEmb = this.cliente ? this.cliente.Numero : '';
    this.booking.numeroEmb = this.cliente ? this.cliente.Numero : '';
    this.booking.complementoEmb = this.cliente ? this.cliente.Complemento : '';
    this.booking.bairroEmb = this.cliente ? this.cliente.Bairro : '';
    if (!this.booking.municipioEmb)
      this.booking.municipioEmb = this.cliente ? this.cliente.Municipio : '';
    if (!this.booking.ufEmb)
      this.booking.ufEmb = this.cliente ? this.cliente.UF : '';
    if (!this.booking.idMunicEmb)
      this.booking.idMunicEmb = this.cliente ? this.cliente.IdMunicipio : null;
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */

    this.booking.contatoEmb = this._appService.UsuarioLogado.nome.substring(0,30);
    this.booking.emailEmb = this._appService.UsuarioLogado.email.toLowerCase();
    this.booking.email = this._appService.UsuarioLogado.email;
    this.telefoneEmbarcador = (this.novoBooking || this.duplicar) ? this._appService.UsuarioLogado.telefone : this.booking.telefone;
    this.formataTelefoneEmbarcador(this.telefoneEmbarcador);
  }

  private ExibeNomeCliente(ie: string) {
    var cli: Cliente = this.clientes.find( c => c.cnpj == this.booking.cnpjEmb && c.inscricaoEstadual == ie);
    this.booking.nomeEmb = (cli != undefined) ? cli.nome : '';
    this.booking.nomeEmbarcador = this.booking.nomeEmb;
  }

  qtdeContainersAlterada(quantidade: number){
    if (this.booking.qtdeContainer >= this.qtdeContainersInicial)
      this.quantidadeContainersAlterada.emit(quantidade);
  }

  public preencherBookingComDadosDaProposta(): void {
    this.booking.cnpjCli = this.proposta.cnpjCli;
    this.booking.ieCli = this.proposta.ieCli;
    this.booking.modal = this.proposta.modal;
    this.booking.idProposta = this.proposta.idProposta;
    this.booking.idTrecho = this.proposta.idTrecho;
    this.booking.numProposta = this.proposta.numProposta.toString();
    this.booking.idMunicOri = this.proposta.idMunicOrigem;
    this.booking.municOri = this.proposta.municOrigem;
    this.booking.ufMunicOri = this.proposta.ufOrigem;
    this.booking.idMunicDes = this.proposta.idMunicDestino;
    this.booking.municDes = this.proposta.municDestino;
    this.booking.ufDes = this.proposta.ufDestino;
    this.booking.idPortoOri = this.proposta.idPortolOrigem;
    this.booking.idPortoDes = this.proposta.idPortoDestino;
    this.booking.tipoDeContainer = this.proposta.tipoDeContainer;
    this.booking.idCategoriaContainer = this.proposta.idCategoriaContainer;
    this.booking.tamanhoContainer = this.proposta.tamanhoContainer;
    this.booking.flagCargaOver = this.proposta.flagCargaOver;
    this.booking.flagCargaRefrigerada = this.proposta.flagCargaRefrigerada;
    this.booking.flagCargaPerigosa = this.proposta.flagCargaPerigosa;
    this.booking.temperatura = this.proposta.tempCargaRefrigerada;
    this.booking.numUno = this.proposta.numeroUNO;
    this.booking.classe = this.proposta.classe;
    this.booking.sequencia = this.proposta.sequencia;
    this.booking.psn = this.proposta.psn;
    this.booking.cargaOverAltura = this.proposta.cargaOverAltura;
    this.booking.cargaOverDireita = this.proposta.cargaOverDireita;
    this.booking.cargaOverEsquerda = this.proposta.cargaOverEsquerda;
    this.booking.cargaOverTraseiro = this.proposta.cargaOverTraseiro;
    this.booking.cargaOverFrontal = this.proposta.cargaOverFrontal;
    this.booking.obsCargaOver = this.proposta.obsCargaOver;
    this.booking.obsCargaRefrigerada = this.proposta.obsCargaRefrigerada;
    this.booking.obsCargaPerigosa = this.proposta.obsCargaPerigosa;
    this.booking.pesoBruto = this.proposta.pesoContainer;
    this.booking.nomeEmb = this.proposta.nomeEmb;
    this.booking.nomeEmbarcador = this.proposta.nomeEmb;
    this.booking.cnpjEmb = this.proposta.cnpjEmb;
    this.booking.ieEmb = this.proposta.ieEmb;
    this.booking.cepEmb = this.proposta.cepEmb;
    this.booking.logradouroEmb = this.proposta.logradouroEmb;
    this.booking.numeroEndEmb = this.proposta.numeroEndEmb;
    this.booking.numeroEmb = this.proposta.numeroEndEmb;
    this.booking.bairroEmb = this.proposta.bairroEmb;
    this.booking.contatoEmb = this._appService.UsuarioLogado.nome.substring(0,30);
    this.booking.complementoEmb = this.proposta.complementoEmb;
    this.booking.email = this._appService.UsuarioLogado.email;
    this.booking.emailEmb = this._appService.UsuarioLogado.email.toLowerCase();
    this.booking.idMunicEmb = this.proposta.idMunicipioEmb;
    this.booking.municipioEmb = this.proposta.municipioEmb;
    this.booking.ufEmb = this.proposta.ufEmb;
    this.telefoneEmbarcador = (this.novoBooking || this.duplicar) ? this._appService.UsuarioLogado.telefone : this.booking.telefone;
    this.formataTelefoneEmbarcador(this.telefoneEmbarcador);

    var filtroCliente = new ClienteRequest();
    filtroCliente.cnpj = this.booking.cnpjEmb;
    filtroCliente.inscricaoEstadual = this.proposta.ieEmb;
    this._sharedService.getClientes(filtroCliente)
      .subscribe(c => {
        this.loadingClientes = false;
        if (c == null) {
          this.booking.embarcador = null;
          return;
        }

        var clientes = c.filter(x => x.cnpj == filtroCliente.cnpj && x.inscricaoEstadual == filtroCliente.inscricaoEstadual);
        if (clientes && clientes.length != 0) {
          this.booking.embarcador = ((clientes != undefined) && (clientes.length == 1)) ? clientes[0] : null;
        }
    });

    this.booking.proposta = this.proposta;
  }

  ValidarStep(): string[]{
    var mensagens: string[] = [];

    if (!this._sharedService.validaInteiro(this.booking.temperatura))
      mensagens.push('A Temperatura deve ser um número inteiro!');
    else if (this.booking.temperatura < -100 || this.booking.temperatura > 100)
      mensagens.push('A Temperatura deve estar entre -100°C e 100°C!');

    if (!this._sharedService.validaInteiro(this.booking.cargaOverAltura))
      mensagens.push('O excesso de Altura deve ser um número inteiro!');

    if (!this._sharedService.validaInteiro(this.booking.cargaOverEsquerda))
      mensagens.push('O excesso à Esquerda deve ser um número inteiro!');

    if (!this._sharedService.validaInteiro(this.booking.cargaOverDireita))
      mensagens.push('O excesso à Direta deve ser um número inteiro!');

    if (!this._sharedService.validaInteiro(this.booking.cargaOverTraseiro))
      mensagens.push('O excesso Traseiro deve ser um número inteiro!');

    if (!this._sharedService.validaInteiro(this.booking.cargaOverFrontal))
      mensagens.push('O excesso Frontal deve ser um número inteiro!');

    if (!this.booking.qtdeContainer || this.booking.qtdeContainer == 0)
      mensagens.push("Informe a quantidade de contêineres");
    else {
      if (this.booking.qtdeContainer > 300)
        mensagens.push('A Quantidade de Contêineres não pode ser maior que 300!');

      if (!this._sharedService.validaInteiro(this.booking.qtdeContainer) || this.booking.qtdeContainer <= 0)
        mensagens.push('A Quantidade de Contêineres deve ser um número inteiro maior que 0 (zero)!');
    }

    //-- Validação dos dados do embarcador - chamado 6004 --
    if ((!this.booking.cnpjEmb || this.booking.cnpjEmb == '') && (!this.edicaoBooking))
      mensagens.push("Informe o CNPJ do embarcador");

    if ((!this.booking.ieEmb || this.booking.ieEmb == '') && (!this.edicaoBooking))
      mensagens.push("Informe a Inscrição Estadual do embarcador");

    if ((!this.booking.nomeEmbarcador || this.booking.nomeEmbarcador == '') && (!this.edicaoBooking))
      mensagens.push("Informe a Razão Social do embarcador");

      if ((!this.booking.cepEmb || this.booking.cepEmb == '') && (!this.edicaoBooking))
      mensagens.push("Informe o CEP do embarcador");

    if ((!this.booking.logradouroEmb || this.booking.logradouroEmb == '') && (!this.edicaoBooking))
      mensagens.push("Informe o Logradouro do embarcador");

    if ((!this.booking.numeroEndEmb || this.booking.numeroEndEmb == '') && (!this.edicaoBooking))
      mensagens.push("Informe número do endereço do embarcador");

    if ((!this.booking.municipioEmb || this.booking.municipioEmb == '') && (!this.edicaoBooking))
      mensagens.push("Informe o Município do embarcador");
    //------------------------------------------------------


    if ((!this.booking.contatoEmb || this.booking.contatoEmb == '') && (!this.edicaoBooking))
      mensagens.push("Informe o contato do embarcador");

    if ((!this.telefoneEmbarcador || this.telefoneEmbarcador == '') && (!this.edicaoBooking))
      mensagens.push("Informe o telefone do embarcador");
    else
      if (!this._sharedService.validaTelefone(this.telefoneEmbarcador) && (!this.edicaoBooking))
        mensagens.push("Telefone do embarcador inválido");
      else
        this.formataTelefoneEmbarcador(this.telefoneEmbarcador);

    if ((!this.booking.emailEmb || this.booking.emailEmb == '') && (!this.edicaoBooking))
      mensagens.push("Informe o e-mail do embarcador");
    else {
      if (!this._sharedService.validaMailFormat(this.booking.emailEmb.toLowerCase()))
        mensagens.push("E-mail do embarcador inválido");
    }

    if (this.booking.qtdeContainer && this.booking.qtdeContainer > 300)
      mensagens.push("A quantidade de contêineres não pode ser superior a 300");
    else if (this.booking.qtdeContainer && this.booking.qtdeContainer != this.qtdeContainersInicial && this.edicaoBooking && !this.duplicar) {
      // se modificou a quantidade de conteiners
      var dataReferencia = (this.booking.oriPorto) ?
        this._sharedService.CalculaProximoDiaUtil(this.feriados, new Date(), this.qtdeHorasAgendamentoPO) :
        this._sharedService.addHours(new Date(), this.qtdeHorasAgendamentoPA);

      //verfica se a data de deadline permite a alteração
      if (this.booking.dataDeadlineEntregaEmb != undefined) {
        if (dataReferencia.getTime() > this.booking.dataDeadlineEntregaEmb.getTime()) {
            mensagens.push("Sua solicitação de alteração de quantidade do booking não é permitida devido à data de deadline da entrega da carga para o embarque!");
            this.booking.qtdeContainer = this.qtdeContainersInicial;
            this.quantidadeContainersAlterada.emit(this.qtdeContainersInicial);
        }
      }

      if (this.booking.qtdeContainer > this.qtdeContainersInicial) {
        // se dentro do prazo, exibe mensagem apenas para aumento de conteiners
        if (mensagens.length == 0){
          swal({
            title: 'Alerta',
            text: 'Sua solicitação de aumento de quantidade do booking será avaliada pela nossa equipe de atendimento do cliente e retornaremos em breve!',
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: 'OK'
          }).then(success => {
          }, dismiss => {
          });
        }
        else
          mensagens.push("Sua solicitação de aumento de quantidade do booking será avaliada pela nossa equipe de atendimento do cliente e retornaremos em breve!");
      }
      else if (!this.booking.oriPorto) {
          mensagens.push("Por favor entrar em contato com nosso 0800 para solicitar o cancelamento destes agendamentos, pois já enviamos as Ordens de Serviço para os provedores");
          this.booking.qtdeContainer = this.qtdeContainersInicial;
          this.quantidadeContainersAlterada.emit(this.qtdeContainersInicial);
      }
    }

    return mensagens;
  }

  formataTelefoneEmbarcador(telefone: string): void {
    if (telefone) {
      telefone = telefone.replace(/\D/g, '');
      if (telefone && telefone.length > 9) {
        this.booking.dddEmb = telefone.substr(0, 2);

        const tamanhoTelefone = telefone.length;
        const numTelefone = telefone.substr(2, telefone.length - 1);

        this.booking.foneEmb = numTelefone;
        this.booking.telefone = this.booking.dddEmb + this.booking.foneEmb;
      }
    }
  }

  getDescricaoOrigemDestino(servicoProposta: ServicoProposta): string {
        var descricao = "";
        if  (servicoProposta.indicOriDes == "O")
            descricao = " (Origem)";

        if  (servicoProposta.indicOriDes == "D")
            descricao = " (Destino)";

        return servicoProposta.descTipoServico + descricao;
  }

  getServicosProposta(tipo: string){
    return this.proposta.servicosDaProposta.filter(x => x.indicOriDes == tipo);
  }

  exibeLinha() {
    return ((this.proposta.flagEquipePropriaOri == 'S') ||
            (this.proposta.flagEquipePropriaDes == 'S') ||
            (this.proposta.flagConferenteOri == 'S') ||
            (this.proposta.flagConferenteDes == 'S') ||
            (this.proposta.flagEscoltaOri == 'S') ||
            (this.proposta.flagEscoltaDes == 'S') ||
            (this.proposta.flagShipperOwnContainer == 'S'));

  }
}
