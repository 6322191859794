
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DetalhesContainerModule } from 'app/shared/detalhes-container/detalhes-container.module';
import { DatePickerModule } from "app/shared/ng2-datepicker/ng2-datepicker.module";
import { SharedModule } from 'app/shared/shared.module';
import { MultiselectDropdownModule } from './../../shared/multiselect-dropdown/multiselect-dropdown.module';

import { AgendamentoRastreamentoComponent } from 'app/cliente/rastreamento/agendamento-rastreamento/agendamento-rastreamento.component';
import { BuscaRastreamentoComponent } from 'app/cliente/rastreamento/busca-rastreamento/busca-rastreamento.component';
import { FiltroRastreamentoComponent } from 'app/cliente/rastreamento/filtro-rastreamento/filtro-rastreamento.component';
import { OrdenacaoRastreamentoComponent } from 'app/cliente/rastreamento/ordenacao-rastreamento/ordenacao-rastreamento.component';
import { ListaRastreamentoComponent } from 'app/cliente/rastreamento/lista-rastreamento/lista-rastreamento.component';
import { TrackingRastreamentoComponent } from 'app/cliente/rastreamento/lista-rastreamento/tracking-rastreamento/tracking-rastreamento.component';
import { ItemEntregaAgendamentoComponent } from 'app/cliente/rastreamento/agendamento-rastreamento/item-entrega-agendamento/item-entrega-agendamento.component';
import { RastreamentoComponent } from 'app/cliente/rastreamento/rastreamento.component';
import { ItemTrackingComponent } from './lista-rastreamento/tracking-rastreamento/item-tracking/item-tracking.component';
import { ListaDocsComponent } from './lista-rastreamento/lista-docs/lista-docs.component';
import { PortoRotaComponent } from './navio-rota/porto-rota/porto-rota.component';
import { NavioRotaComponent } from './navio-rota/navio-rota.component';
import { AgendaEntrega } from 'app/_models/agenda-entrega';
import { AgendaEntregaSalvar } from 'app/_models/agenda-entrega-salvar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TextMaskModule } from 'angular2-text-mask';

import { AgendaEntregaService } from 'app/_services/agenda-entrega.service';
import { DatepickerService } from 'app/_services/datepicker.service';
import { RastreamentoService } from 'app/_services/rastreamento.service';
import { MessageService } from 'app/_services/message.service';
import { ParametroService } from './../../_services/parametro.service';
import { SlickSliderComponent } from 'app/shared/slick-slider/slick-slider-component';
import { NavioViagemService } from 'app/_services/navio-viagem.service';
import { RastreamentoRoutingModule } from './rastreamento.routing.module';
import { UsuarioService } from 'app/_services/usuario.service';
import { AppService } from 'app/_services/app.service';
import { BookingService } from 'app/_services/booking.service';
import { GroupbyService } from 'app/_services/groupby.service';
import { MapsService } from 'app/_services/maps.service';
import { OkEntregaService } from 'app/_services/ok-entrega.service';
import { InfoSilModalComponent } from './lista-rastreamento/tracking-rastreamento/info-sil-modal/info-sil-modal.component';
import { InfosSilComponent } from './lista-rastreamento/infos-sil/infos-sil.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    DetalhesContainerModule,
    DatePickerModule,
    MultiselectDropdownModule,
    ModalModule.forRoot(),
    SharedModule,
    RastreamentoRoutingModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    RastreamentoComponent,
    AgendamentoRastreamentoComponent,
    BuscaRastreamentoComponent,
    FiltroRastreamentoComponent,
    ListaRastreamentoComponent,
    OrdenacaoRastreamentoComponent,
    TrackingRastreamentoComponent,
    ItemEntregaAgendamentoComponent,
    ItemTrackingComponent,
    ListaDocsComponent,
    SlickSliderComponent,
    PortoRotaComponent,
    NavioRotaComponent,
    InfoSilModalComponent,
    InfosSilComponent
  ],
  exports: [
    SharedModule
  ],
  providers: [
    DatepickerService,
    RastreamentoService,
    ParametroService,
    AgendaEntregaService,
    NavioViagemService,
    AgendaEntrega,
    AgendaEntregaSalvar,
    MessageService,
    UsuarioService,
    BookingService,
    GroupbyService,
    MapsService,
    AppService,
    OkEntregaService
  ]
})
export class RastreamentoModule { }
