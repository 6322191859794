import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { AppService } from 'app/_services/app.service';
import { SharedService } from './../../_services/shared.service';
import { BookingService } from './../../_services/booking.service';
import { MessageService } from './../../_services/message.service';
import { MunicipioService } from './../../_services/municipio.service';
import { DatepickerService } from "app/_services/datepicker.service";
import { GenericOrderbyService } from "app/_services/generic-orderby.service";
import { FiltrosLateraisService } from 'app/_services/filtros-laterais.service';
import { PropostaComercialService } from './../../_services/proposta-comercial.service';

import { Porto } from './../../_models/porto';
import { Municipio } from './../../_models/municipio';
import { Booking } from 'app/_models/booking';
import { MeusBookings } from "app/_models/meus-bookings";
import { BookingRequest } from './../../_models/booking-request';
import { filtrosLaterais } from "app/_models/filtros-laterais";
import { ServicoProposta } from './../../_models/servico-proposta';
import { MunicipioRequest } from './../../_models/municipio-request';
import { PropostaComercial } from '../../_models/proposta-comercial';

import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { BaseComponent } from './../../shared/base.component';
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { MessageComponent } from './../../shared/message/message.component';
import { PaginacaoComponent } from './../../shared/paginacao/paginacao.component';
import { CnpjDropdownComponent } from './../../shared/cnpj-dropdown/cnpj-dropdown.component';
import { FiltrosLateraisComponent } from "./../filtros-laterais/filtros-laterais.component";
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";
import { DetalhesContainerComponent } from '../../shared/detalhes-container/detalhes-container.component';

import { AnalyticsTela } from '../../_enums/analytics-tela';
import { AnalyticsService } from '../../_services/analytics.service';
import { AnalyticsDownload } from '../../_enums/analytics-download';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { GrupoEconomicoService } from 'app/_services/grupo-economico.service';
import { InfoPgtoAdicionalService } from 'app/_services/info-pgto-adicional.service';
import { Router } from '@angular/router';
import { RedirectService } from 'app/_services/redirect.service';


@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild(CnpjDropdownComponent, {static: true}) cnpjdropdown: CnpjDropdownComponent;
  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  @ViewChild(FiltrosLateraisComponent, {static: true}) filtroslaterais: FiltrosLateraisComponent;
  @ViewChild(DetalhesContainerComponent, {static: true}) detalhesContainer : DetalhesContainerComponent;

  loading: boolean = false;
  downloading: boolean = false;
  loadingPortos: boolean = false;
  loadingMunicipios: boolean = false;
  empty: boolean = true;
  noresult: boolean = false;
  listaStatus: string[];
  statusSelecionado: string;

  filtroMunicipio: MunicipioRequest = new MunicipioRequest();

  filtrosLaterais = new filtrosLaterais();
  consulta: BookingRequest = new BookingRequest();
  meusBookings: MeusBookings = new MeusBookings(new filtrosLaterais(), []);
  listaBookingsOriginal: Booking[];
  pageSize: number = 10;
  optionsInicio: DatePickerOptions;
  optionsFim: DatePickerOptions;
  dataInicio: DateModel;
  dataFim: DateModel;

  filtroGenerico: string = "";
  portos: Porto[] = new Array();
  municipios: Municipio[] = new Array();

  municipiosOrigem: number[];
  portosOrigem: number[];
  municipiosDestino: number[];
  portosDestino: number[];

  ordenarpor: string = 'idBooking';
  eAdmin: boolean = this._appService.isAdmin();
  eCliente: boolean = this._appService.isCliente();
  maisDeUmCNPJ = this._appService.maisDeUmCNPJ();
  cssClassAdmin = '';

  comboCidades: FilterComboConfiguration;
  comboPortos: FilterComboConfiguration;
  selecao: number[];
  dhInstantPayment: string = '';

  constructor(
    private _bookingService: BookingService,
    private _grupoService: GrupoEconomicoService,
    private _sharedService: SharedService,
    private _propostaService: PropostaComercialService,
    private _municipioService: MunicipioService,
    private _appService: AppService,
    private _msgService: MessageService,
    private _genericOrderbyService: GenericOrderbyService,
    private _datepickerService: DatepickerService,
    private _filtroslateraisService: FiltrosLateraisService,
    private _analyticsService: AnalyticsService,
    private _infoPgtoAdicionalService: InfoPgtoAdicionalService,
    private _router: Router,
    private _redirectService: RedirectService
  ) {

    super();

    this.optionsInicio = this._datepickerService.GenerateOptionsConfig(this._sharedService.addDays(new Date(), -30));
    this.optionsFim = this._datepickerService.GenerateOptionsConfig(new Date());

    this.comboCidades = new FilterComboConfiguration("Cidade(s)...");
    this.comboCidades.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 2,
      enableSearch: true
    };

    this.comboPortos = new FilterComboConfiguration("Porto(s)...");
    this.comboPortos.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 2,
      showCheckAll: true,
      showUncheckAll: true
    };

    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      });
  }

  ngAfterViewInit(): void {
    this._grupoService.grupoEconomicoSelecionadoEvent.subscribe((cnpjs: string) => {
        if (cnpjs)
          this.Pesquisar();
        else
          this.zeraPesquisa();
      });
  }

  ngOnInit() {
    this.cssClassAdmin = this.eAdmin || (this.eCliente && this.maisDeUmCNPJ)  ? "" : " customer-profile";

    this.cnpjdropdown.SelecionaMultiplos = true;
    this.cnpjdropdown.modoEdicao = false;
    this.ObterPortos();

    if (!this.eAdmin){
      this.ObterMunicipios(null);
      this.ObterBookings();
    }
    else {
      this.cnpjdropdown.cnpjsGrupos = localStorage.getItem('cnpjGrupos');
      if (this.cnpjdropdown.cnpjsGrupos)
        this.Pesquisar(false);
    }

    let msg = this._msgService.getMessageRedirect();
    if (msg.show)
    this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.Booking, Operacao.Consulta, this.consulta);

    this._analyticsService.RegistrarAcesso(AnalyticsTela.Booking);
  }

  zeraPesquisa() {
    this.empty = true;
    this.noresult = false;
    this.statusSelecionado = "";
    this.meusBookings = new MeusBookings(new filtrosLaterais(), []);
    this.paginacao.pagedItems = [];
    this.paginacao.pager = {};
  }

  Pesquisar(registrarAnalytics: boolean = true) {
    this._msgService.clearMessage();
    if (this.eAdmin && ((this.cnpjdropdown.CNPJDigitado == undefined) || (this.cnpjdropdown.CNPJDigitado == "")) && (!this.cnpjdropdown.cnpjsGrupos)) {
      this._msgService.addMessage("Meus Bookings", "Informe o CNPJ do Cliente ou selecione ao menos um Grupo Econômico.", "error", LogFrontFuncionalidade.Booking, Operacao.Consulta, this.consulta);
      this.zeraPesquisa();
      return;
    }
    else {
      if (this.eAdmin && this.cnpjdropdown.cnpjsGrupos && this.cnpjdropdown.CNPJDigitado) {
        if (!this.cnpjdropdown.validaCNPJnoGrupo()) {
          this._msgService.addMessage('Meus Bookings', 'CNPJ informado não pertence ao(s) Grupo(s) Econômico(s) selecionado(s)!', 'error', LogFrontFuncionalidade.Booking, Operacao.Consulta, this.consulta);
          this.zeraPesquisa();
          return;
        }
      }

      //limpa mensagens de erros
      this._msgService.clearMessage();
      this.ObterBookings();
    }
  }

	private ObterData(dateModel: DateModel, dateOffset: number) {
		return (dateModel != undefined) ?
			this._datepickerService.ObtemData(dateModel) :
			this._sharedService.addDays(new Date(), dateOffset);
	}


  ObterBookings(registrarAnalytics: boolean = false) {
    if (this.dataInicio)
      this.consulta.dataIni = this.ObterData(this.dataInicio, 0);
    else
      this.consulta.dataIni = this._sharedService.addDays(new Date(), -30);

    if (this.dataFim)
      this.consulta.dataFim = this.ObterData(this.dataFim, 0);
    else
      this.consulta.dataFim = new Date();

    var ini = this.consulta.dataIni.valueOf();
    var fim = this.consulta.dataFim.valueOf();

    if (fim < ini) {
      this._msgService.addMessage("Meus Bookings", "A data início não pode ser maior que a data fim.", "error", LogFrontFuncionalidade.Booking, Operacao.Consulta, this.consulta);
      this.zeraPesquisa();
      return;
    }

    var diasDiff =  Math.floor((ini - fim) / ( 1000 * 60 * 60 * 24) );

    if (diasDiff > 30){
      this._msgService.addMessage("Meus Bookings", "O filtro por período de emissão não deve ser superior à 30 dias.", "error", LogFrontFuncionalidade.Booking, Operacao.Consulta, this.consulta);
      this.zeraPesquisa();
      return;
    }

    this.filtrosLaterais = new filtrosLaterais();
    this.loading = true;

    // Converte Array em String Delimitado por Ponto e Vírgula (formato recebido na procedure)
    this.consulta.idBooking = 0;
    this.consulta.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    this.consulta.cnpjCli = this.cnpjdropdown.CNPJs ? this.cnpjdropdown.CNPJs.join(";") :
      this.cnpjdropdown.CNPJDigitado ? this.cnpjdropdown.CNPJDigitado :  this.cnpjdropdown.cnpjsGrupos ? this.cnpjdropdown.cnpjsGrupos : "";

    this.consulta.filtroGenerico = this.filtroGenerico;
    this.consulta.idsMunicOrigem = this.municipiosOrigem ? this.municipiosOrigem.join(";") : "";
    this.consulta.idsPortoOrigem = this.portosOrigem ? this.portosOrigem.join(";") : "";
    this.consulta.idsMunicDestino = this.municipiosDestino ? this.municipiosDestino.join(";") : "";
    this.consulta.idsPortoDestino = this.portosDestino ? this.portosDestino.join(";") : "";

    this.ObterMunicipios(this.consulta.cnpjCli);

    if (registrarAnalytics)
      this._analyticsService.RegistrarFiltroBooking(this.consulta);


    this._bookingService.getBookings(this.consulta)
      .subscribe(meusBookings => {
        if (meusBookings != null)


          this.meusBookings = meusBookings;

        this.statusSelecionado = "";
        this.listaBookingsOriginal = meusBookings.Bookings.slice();
        this.listaStatus = meusBookings.Bookings.map(b => b.statusBooking)
            .filter((value, index, self) => self.indexOf(value) === index);
        if (this.listaStatus.length == 1)
          this.statusSelecionado = this.listaStatus[0];

        this.empty = false;
        this.noresult = ((this.meusBookings == undefined) || (this.meusBookings.Bookings.length == 0));
        this.ordernarListaPorCampo(this.ordenarpor, true);
        this.loading = false;
      });
  }

  FiltrarPorStatus(status: string){
    if (!status)
      this.meusBookings.Bookings = this.listaBookingsOriginal;
    else
      this.meusBookings.Bookings = this.listaBookingsOriginal.filter(b => b.statusBooking == status);

    this.ordernarListaPorCampo(this.ordenarpor, true);
  }

  public ObterPortos() {
    this.loadingPortos = true;
    this._sharedService.getPortos()
      .subscribe(m => {
        if (m != null)
          this.portos = m;
        if (this.portos != null) {
          this.portos.forEach(porto => {
            this.comboPortos.options.push({ id: porto.codigo, name: porto.descricao });
          });
        }
        this.loadingPortos = false;
      });
  }

  public ObterMunicipios(cnpj: string) {
    this.loadingMunicipios = true;
    this.filtroMunicipio.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    this.filtroMunicipio.tipoConsulta = "2";
    this.filtroMunicipio.cnpjCli = cnpj;

    var dtIni = (!this.dataInicio) ?
      this._sharedService.addDays(new Date(), -30) :
      this._datepickerService.ObtemData(this.dataInicio);
    this.filtroMunicipio.dataIni = dtIni;

    var dtFim = (!this.dataFim) ? new Date(): this._datepickerService.ObtemData(this.dataFim);
    this.filtroMunicipio.datafim = dtFim;

    this.municipios = [];
    //this.comboCidades.options = [];
    this._municipioService.getMunicipiosEspecial(this.filtroMunicipio)
      .subscribe(m => {
        this.municipios = m;
        this.comboCidades.options = [];
        if (this.municipios != null) {
          this.municipios.forEach(municipio => {
            this.comboCidades.options.push({ id: municipio.codigo, name: municipio.descricao + " / " + municipio.uf });
          });
        }
        this.loadingMunicipios = false;
      });
  }

  cnpjAlterado(cnpj: string){
    this.ObterMunicipios(cnpj);
    this.Pesquisar(false);
  }

  ObterDetalhesBooking(book: Booking) {
    book.loading = true;
    this._bookingService.getBooking({ bookingID: book.idBooking, usuarioID: this._appService.UsuarioLogado.usuarioIDeCargo })
      .subscribe(bookingRetorno => {

        //book.detalheDoBooking = bookingRetorno;
        this._sharedService.copyProperties(bookingRetorno, book.detalheDoBooking);
        book.detalheDoBooking.tipoDeContainer = book.tipoDeContainer;
        if (!book.detalheDoBooking.proposta)
          book.detalheDoBooking.proposta = new PropostaComercial();

        this._propostaService.getServicos({ propostaID: book.numProposta, usuarioID: this._appService.UsuarioLogado.usuarioIDeCargo})
          .subscribe(servicos => {
            book.detalheDoBooking.proposta.servicosDaProposta = servicos;
            book.loading = false;
          });


          this.isInstantPaymentCreditado(book.numBooking);

      });
  }

  getServicosBooking(book: Booking, tipo: string): ServicoProposta[] {
    return book.detalheDoBooking.proposta.servicosDaProposta.filter(f => f.indicOriDes == tipo);
  }

  exibeServicosBooking(book: Booking, tipo: string): boolean {
    var servicosDaProposta = book.detalheDoBooking.proposta.servicosDaProposta;
    if (!servicosDaProposta)
      return false;

    if (servicosDaProposta.length == 0)
      return false;

    return (servicosDaProposta.filter(f => f.indicOriDes == tipo).length > 0);
  }

  ordernarListaPorCampo(campo: string, desc: boolean): void {
    this.paginacao.setAllData(this._genericOrderbyService.ordenarPorCampo(this.meusBookings.Bookings, campo, desc));
  }

  obtemClasseDoStatus(status: string): string {
    status = status.toLowerCase();

    if (status.indexOf('emitido') !== -1)
      return 'quotation-status active';
    else if (status.indexOf('pendente') !== -1)
      return 'quotation-status waiting';
    else if (status.indexOf('ativo') !== -1)
      return 'quotation-status info';
    return 'quotation-status expired';
  }

  obtemTextoEmbarque(book: Booking) {
    if (book.modal.startsWith('PO'))
      return (book.portoOri + ' - ' + book.ufPortoOri).toUpperCase();
    else
      return (book.municOri + ' - ' + book.ufMunicOri).toUpperCase();
  }

  obtemTextoDesembarque(book: Booking) {
    if (book.modal.endsWith('PO'))
      return (book.portoDes + ' - ' + book.ufPortoDes).toUpperCase();
    else
      return (book.municDes + ' - ' + book.ufDes).toUpperCase();
  }

  exportarRelatorioCSV() {
    this.downloading = true;
    let listaFiltrada = this._filtroslateraisService.filtrarDados(this.meusBookings.Bookings, this.filtrosLaterais);
    this._bookingService.exportarRelatorioCSV(listaFiltrada);
    this._analyticsService.RegistrarDownload(AnalyticsTela.Booking, AnalyticsDownload.ExportacaoCSV);
    this.downloading = false;
  }

  handleDetail(book: any) {
    console.log('book,', book)
  }


  isInstantPaymentCreditado(numBooking: string) {

    if(numBooking == undefined || numBooking.trim().length == 0)
      return;


    this._infoPgtoAdicionalService.getInfoPgtoAdicionalByNumBooking(numBooking)
      .subscribe(infoPgtoAdicional => {

        if (infoPgtoAdicional !=   null)
        {
          const bookingUpd =  this.meusBookings.Bookings.find(item=> item.numBooking === numBooking );
          bookingUpd.isInstantPaymentCreditado = infoPgtoAdicional.paymentStatus == 'COMPLETED';
          let dtTmp: string = infoPgtoAdicional.paidAt.toString().substr(0,10);
          let ano: string = dtTmp.substr(0, 4);
          let mes: string = dtTmp.substr(5, 2);;
          let dia:string = dtTmp.substr(8, 2);;

          bookingUpd.dhInstantPayment = dia + '/' + mes + '/' + ano;

        }
        else
        {
          this.meusBookings.Bookings.find(item=> item.numBooking === numBooking ).isInstantPaymentCreditado = false;
        }

      });
  }
  
  duplicarBooking(booking: Booking): void {
    // PO-PO Bookings with Credit Payment must be created on PCAB 3.0
    // others continue in 2.0  

    // feature switch, only redirect to PCAB 3.0 if enabled
    if(!this._redirectService.isRedirectEnabled()) {      
      this.continueDuplicarBooking(booking.idBooking);
      return;
    }

    console.log('duplicar booking', booking);	    

    // continueDuplicarBooking() is called to ensure the assyncronous nature of isCreditPayment is handled correctly
    if (this._redirectService.isPortoPorto(booking)) {
      this._redirectService.isCreditPayment$(booking.cnpjCli).subscribe(isCredit => {
        if (isCredit) {
          console.log('selecionou modal PO/PO');
          console.log('redirecionando para PCAB 3.0');
          console.log('cnpjCli', booking.cnpjCli);
          console.log('numBooking', booking.numBooking);
          //this._redirectService.redirectWithModalToBookingOnlineCopy(booking.numBooking); // when 3.0 is ready
          this._redirectService.redirectWithModalToBookingOnlineNew();
          return;
        }
        else {
          this.continueDuplicarBooking(booking.idBooking)
        }
      });
    }
    else {
      this.continueDuplicarBooking(booking.idBooking);
    }
  }
  
  // continue in 2.0
  private continueDuplicarBooking(idBooking: number) {
    this._router.navigate(['/booking/solicitar', idBooking, 'true']);
  }

}
