import { TextMaskModule } from 'angular2-text-mask';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { EnderecoComponent } from './endereco.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TextMaskModule
    ],
    exports: [
        EnderecoComponent,
        TextMaskModule
    ],
    declarations: [
        EnderecoComponent
    ],
    providers: [],
})
export class EnderecoModule { }