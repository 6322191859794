import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { SharedService } from './shared.service';
import { MessageService } from './message.service';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { Operacao } from '../_enums/operacao';
import { TipoTaxaEsporadica } from '../_models/tipo-taxa-esporadica';
import { ValidationResult } from '../_models/validation-result';

@Injectable({
  providedIn: 'root'
})
export class TipoTaxaEsporadicaService {
  urlPath: string;

  constructor(
    private baseService: BaseService,
    private _msgService: MessageService) { }


  salvar(tipoTaxaEsporadica: TipoTaxaEsporadica): Observable<ValidationResult> {
    return this.baseService.Post('sporadic-tax-type', tipoTaxaEsporadica).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Taxa adicional - Salvar', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Inclusao, tipoTaxaEsporadica);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getTipoTaxaEsporadica(id: number): Observable<TipoTaxaEsporadica> {
    return this.baseService.Get('sporadic-tax-type/' + id).pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;

            if (retorno.IsValid)
                return retorno.Data as TipoTaxaEsporadica;
            else
                return null;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
}

  update(tipoTaxaEsporadica: TipoTaxaEsporadica[]): Observable<ValidationResult> {
    return this.baseService.Put('sporadic-tax-type', tipoTaxaEsporadica).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Taxa Adicional - Atualização', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Alteracao, tipoTaxaEsporadica);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }


  delete(id: number): Observable<ValidationResult> {
    return this.baseService.Delete('sporadic-tax-type/delete?id=' + id).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Taxa adicional - Exclusão', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Exclusao, id);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }


getAll(): Observable<TipoTaxaEsporadica[]> {
  return this.baseService.Get('sporadic-tax-type').pipe(
    map((response: ValidationResult) => {
      let retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._msgService.addMessageValidationError('Tipo taxas esporádicas', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Consulta, null);
      }

      return retorno.Data as TipoTaxaEsporadica[];

    }),catchError(e => {
      return this.baseService.handleError(e);
    }),);
}


  findLastExpirationDate(taxTypeId: number, originRegionId: number, originModalId: string, destinationRegionId: number, destinationModalId: string): Observable<TipoTaxaEsporadica> {

    this.urlPath = "sporadic-tax-type/find-last-expiration-date?taxTypeId=" + taxTypeId + "&originRegionId=" + originRegionId + "&originModalId=" + originModalId + "&destinationRegionId=" +  destinationRegionId + "&destinationModalId=" + destinationModalId;

    return this.baseService.Get(this.urlPath).pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;

            if (retorno.IsValid)
                return retorno.Data as TipoTaxaEsporadica;
            else
                return null;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
}

findByTypeIdStatus(tipoTaxaEsporadicaTypeId: number, isEnabled: number): Observable<TipoTaxaEsporadica[]> {

  this.urlPath = "sporadic-tax-type/find-by-type-id-status?TipoTaxaEsporadicaTypeId=" + tipoTaxaEsporadicaTypeId + "&isEnabled=" + isEnabled;

  return this.baseService.Get(this.urlPath).pipe(
      map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (retorno.IsValid)
              return retorno.Data as TipoTaxaEsporadica[];
          else
              return null;
      }),catchError(e => {
          return this.baseService.handleError(e);
      }),);
}

obterTipoTaxaEsporadicaes(): Observable<TipoTaxaEsporadica[]> {
  return this.baseService.Get('sporadic-tax-type').pipe(
    map((response: ValidationResult) => {
      let retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._msgService.addMessageValidationError('Tipo taxas esporádicas', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Consulta, null);
      }

      return retorno.Data as TipoTaxaEsporadica[];

    }),catchError(e => {
      return this.baseService.handleError(e);
    }),);
}


exportarRelatorioCSV(tipoTaxaEsporadicas: TipoTaxaEsporadica[]): Observable<any> {
  if (!tipoTaxaEsporadicas || tipoTaxaEsporadicas.length === 0) {
    return;
  };


  const titulos: any[] = [
    'ID',
    'E-mail',
    'Nome',
    'Razão Social',
    'CNPJ',
    'Nome Fantasia',
    'Inscrição Estadual',
    'Logradouro',
    'Número',
    'Complemento',
    'Bairro',
    'UF',
    'Município',
    'CEP',
    'Telefone',
    'Perfil Portal',
    'Perfil E-cargo',
    'CNPJ(s) Cadastrados',
    'Status do Usuário',
    'Data de Solicitação',
    'Data de Aprovação',
    'Data de Reprovação',
    'Data Última Alteração de Senha',
    'Situação Senha Login'];

      this.baseService.DownloadXLSX(tipoTaxaEsporadicas, titulos, 'TaxasEsporadicas.xlsx');
};

}
