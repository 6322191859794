import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DoctoFaturaArquivo } from '../../../../../_models/doctoFaturaArquivo';
import { DoctoFatura } from '../../../../../_models/doctoFatura';
import { BaseComponent } from './../../../../../shared/base.component';

@Component({
  selector: 'download-documentacao',
  templateUrl: './download-documentacao.component.html',
  styleUrls: ['./download-documentacao.component.scss']
})
export class DownloadDocumentacaoComponent extends BaseComponent {

  loadingArquivos: boolean = false;
  @Input() classeIcone: string;
  @Input() doctoFatura: DoctoFatura;
  @Output() clickArquivo: EventEmitter<DoctoFaturaArquivo> = new EventEmitter<DoctoFaturaArquivo>();
  @Output() clickTodosArquivos: EventEmitter<DoctoFatura> = new EventEmitter<DoctoFatura>();
  @Output() clickBuscarArquivos: EventEmitter<DoctoFatura> = new EventEmitter<DoctoFatura>();

  clickDownloadArquivo(arquivo: DoctoFaturaArquivo) {
    this.clickArquivo.emit(arquivo);
  }

  clickDownloadTodosArquivos() {
    this.clickTodosArquivos.emit(this.doctoFatura);
  }

  archivesMail(){
    return this.doctoFatura.arquivos.filter(res => res.tipoArquivo != "PDF Canhoto")
  }

  obterNomeImagemArquivo(arquivo: DoctoFaturaArquivo): string {
    var tipo = (arquivo.tipoArquivo == undefined) ? "" : arquivo.tipoArquivo.toLowerCase();
    switch(tipo){
      case "pdf cte":
      case "pdf fatura boleto":
      case "pdf boleto":
      case "pdf rps":
      case "pdf canhoto":
        return "pdf";
      case "canhoto":
        return "image";
      case "xml cte":
      default:
        return "document";
    }
  }

  buscarArquivos(){
    this.loadingArquivos = true;
    if (this.doctoFatura.arquivos == undefined)
      this.clickBuscarArquivos.emit(this.doctoFatura);
  }

  getLabelTodos() {
    return (this.classeIcone == 'download') ? 'Baixar todos (ZIP)' : 'Enviar todos (ZIP)';
  }

}
