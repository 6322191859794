import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from "app/_services/shared.service";
import { MessageService } from "./../../_services/message.service";
import { BaseComponent } from "app/shared/base.component";
import { MessageComponent } from "./../../shared/message/message.component";
import { Operacao } from "app/_enums/operacao";
import { LogFrontFuncionalidade } from "app/_enums/log-front-funcionalidade";
import * as moment from "moment-timezone";
import { UsuarioPesquisaRequest } from "app/_models/usuario-pesquisa-request";
import { DataService } from "./../../_services/data.service";
// Models
import { UF } from "app/_models/uf";
import { ValidationError } from "../../_models/validation-error";
import { debug, Console } from "console";
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'app/_services/app.service';
import swal from 'sweetalert2';
import { Parser } from '@angular/compiler';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


import { GenericOrderbyService } from 'app/_services/generic-orderby.service';
import { PaginacaoComponent } from 'app/shared/paginacao/paginacao.component';
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";
import { BaseService } from 'app/_services/base.service';
import { NgForm } from '@angular/forms';
import { PortoService } from 'app/_services/porto.service';
import { PortoInstantPaymentRequest } from 'app/_models/PortoInstantPaymentRequest';
import { ListItem } from 'app/_models/listItem';



@Component({
  selector: "app-porto",
  templateUrl: "./porto.component.html",
  styleUrls: ["./porto.component.scss"],
})
export class PortoComponent extends BaseComponent implements OnInit {
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;

  loading: boolean = false;
  private pageSize: number = 50;

  private portoLista: PortoInstantPaymentRequest[] =  new Array;
  private portoInstantPayment: PortoInstantPaymentRequest;


  regioesGeograficas: ListItem[] = new Array();


  constructor(
    private baseService: BaseService,
    private _sharedService: SharedService,
    private _msgService: MessageService,
    private _dataService: DataService,
    private _genericOrderbyService: GenericOrderbyService,
    private _portoService: PortoService,
    private _appService: AppService ) {

    super();

    this._msgService.emitirMensagem.subscribe(
      msg => {
          this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
  );
  }

  ngOnInit() {
    this.loading = true;
    this.ObterDataFields();
    this.getAllPortos();
    this.setPageSize(this.pageSize);
    this.loading = false;

    let msg = this._msgService.getMessageRedirect();
    if (msg.show)
      this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao);
  }


  getAllPortos() {
    this.loading = true;

    this._portoService.getAll().subscribe(
      (lista) => {
        this.portoLista = lista;
        this.paginacao.setAllData(this.portoLista);
        this.paginacao.setPage(1);
     },
      (err) => {}
    );

    this.loading = false;

  }


  ObterDataFields() {
    this._dataService.getRegiaoGeografica().subscribe((lista) => {
      this.regioesGeograficas = lista;
    });
  }

  setRegiaoGeografica(regiaoGeograficaId: number): string {
    let item1 = this.regioesGeograficas.find(i => i.codigo === regiaoGeograficaId.toString());
    return item1.descricao;
  }

  setPageSize(value: number) {
    this.pageSize = value;
    this.paginacao.setAllData(this.portoLista);
    this.paginacao.setPageSize(this.pageSize);
    this.paginacao.setPage(1);
  }


  onSavePorto() {


      this._portoService.salvar(this.portoInstantPayment).subscribe((result) => {

        this.getAllPortos();

        console.log("sucesso");
      });
    }



  // exportarRelatorioCSV() {
  //   this.downloading = true;

  //   const titulos: any[] = [
  //     "CODIGO",
  //     "UF ORIGEM",
  //     "UF DESTINO",
  //     "% CIF",
  //     "% CIF - NÃO CONTRIBUINTE",
  //     "% FOB",
  //     "% FOB - NAÃO CONTRIBUINTE",
  //     "CRIADO POR",
  //     "CRIADO EM",
  //     "ATUALIZADO POR",
  //     "ATUALIZADO EM"];


  //     const data: any[] = [];

  //     this.portoLista.forEach(x => {
  //       data.push([
  //         x.codeIcms,
  //         x.departureUf,
  //         x.destinationUf,
  //         x.percCif,
  //         x.percCifNonTaxPayer,
  //         x.percFob,
  //         x.percFobNonTaxPayer,
  //         x.createdBy,
  //         x.createdOn,
  //         x.updatedBy,
  //         x.updatedOn
  //       ]);
  //     });

  //   this.downloading = false;
  //   this.baseService.DownloadXLSX(data, titulos, 'Portos.xlsx');

  // }
}
