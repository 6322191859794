
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmModule } from './../../shared/confirm/confirm.module';
import { SharedModule } from './../../shared/shared.module';

import { AnelRotaRoutingModule } from './anel-rota.routing.module';
import { AnelRotaComponent } from './anel-rota.component';
import { AnelRotaDetalheComponent } from './anel-rota-detalhe/anel-rota-detalhe.component';
import { AnelRotaService } from './../../_services/anel-rota.service';

import { RotasAnelComponent } from './anel-rota-detalhe/rotas-anel/rotas-anel.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        AnelRotaRoutingModule,
        ModalModule.forRoot(),
        ConfirmModule,
        SharedModule
    ],
    exports: [],
    declarations: [
        AnelRotaComponent,
        AnelRotaDetalheComponent,
        RotasAnelComponent
    ],
    providers: [
        AnelRotaService
    ]
})
export class AnelRotaModule { }