export class SporadicTax {

  id: number;
  sporadicTaxName: string= '';
  sporadicTaxTypeId: number;
  originRegionId: number;
  originPortId: number;
  originModalId: string= '';
  destinationRegionId: number;
  destinationPortId: number;
  destinationModalId: string= '';
  containerType20Value: number = 0.0;
  containerType40Value: number = 0.0;
  startDate: Date =  new Date();
  expirationDate: Date =  new Date();
  validity: string= '';

  createdBy: string = '';
  // createdOn: Date = new Date();
  // updatedOn: Date = new Date();
  updatedBy: string = '';
  isEnabled: number;
  acao:string =''
}
