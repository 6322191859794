import { Component, OnInit, ViewChild } from '@angular/core';

import { ConfirmService } from './confirm.service';

const KEY_ESC = 27;

@Component({
  selector: 'modal-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  exportAs: 'child'
})
export class ConfirmComponent implements OnInit {

@ViewChild('staticModal', {static: true}) staticModal;

  private _defaults = {
        title: 'Confirmação',
        message: 'Deseja cancelar as alterações?',
        textbutton1: 'OK',
        textbutton2: 'Cancelar'
    };

    title:string;
    message:string;
    textbutton1:string;
    textbutton2:string;

    private _confirmElement:any;
    private _button1:any;
    private _button2:any;


  constructor(confirmService:ConfirmService) {
    confirmService.activate = this.activate.bind(this);
  }

  _setLabels(message = this._defaults.message, title = this._defaults.title, textbutton1 = this._defaults.textbutton1, textbutton2 = this._defaults.textbutton2) {
        this.title = title;
        this.message = message;
        this.textbutton1 = textbutton1;
        this.textbutton2 = textbutton2;
    }

    activate(message = this._defaults.message, title = this._defaults.title, textbutton1 = this._defaults.textbutton1, textbutton2 = this._defaults.textbutton2) {
        this._setLabels(message, title, textbutton1, textbutton2);

        let promise = new Promise<boolean>(resolve => {
            this._show(resolve);
        });
        return promise;
    }

    private _show(resolve:(boolean) => any) {
        document.onkeyup = null;

        let negativeOnClick = (e:any) => resolve(false);
        let positiveOnClick = (e:any) => resolve(true);

        if (!this._confirmElement || !this._button2 || !this._button1) return;

        //this._confirmElement.style.opacity = 0;
        //this._confirmElement.style.zIndex = 9999;

        this._button2.onclick = ((e:any) => {
            e.preventDefault();
            if (!negativeOnClick(e)) this._hideDialog();
        })

        this._button1.onclick = ((e:any) => {
            e.preventDefault();
            if (!positiveOnClick(e)) this._hideDialog()
        });

        this._confirmElement.onclick = () => {
            this._hideDialog();
            return negativeOnClick(null);
        };

        document.onkeyup = (e:any) => {
            if (e.which == KEY_ESC) {
                this._hideDialog();
                return negativeOnClick(null);
            }
        };

        //this._confirmElement.style.opacity = 1;

        this.staticModal.show();
    }

    private _hideDialog() {
        document.onkeyup = null;
        //this._confirmElement.style.opacity = 0;
        //window.setTimeout(() => this._confirmElement.style.zIndex = -1, 400);
        this.staticModal.hide();
    }

    ngOnInit():any {
        this._confirmElement = document.getElementById('confirmationModal');
        this._button2 = document.getElementById('button2');
        this._button1 = document.getElementById('button1');
    }

}
