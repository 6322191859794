import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { BaseComponent } from './../../shared/base.component';
import { MessageComponent } from 'app/shared/message/message.component';
import { FiltrosLateraisComponent } from './../filtros-laterais/filtros-laterais.component';
import { ListaRastreamentoComponent } from 'app/cliente/rastreamento/lista-rastreamento/lista-rastreamento.component';
import { BuscaRastreamentoComponent } from 'app/cliente/rastreamento/busca-rastreamento/busca-rastreamento.component';
import { FiltroRastreamentoComponent } from 'app/cliente/rastreamento/filtro-rastreamento/filtro-rastreamento.component';
import { OrdenacaoRastreamentoComponent } from 'app/cliente/rastreamento/ordenacao-rastreamento/ordenacao-rastreamento.component';
import { DetalhesContainerComponent } from 'app/shared/detalhes-container/detalhes-container.component';
import { NavioRotaComponent } from './navio-rota/navio-rota.component';

import { TrackingLista } from './../../_models/tracking-lista';
import { TipoEventoTracking } from './../../_models/tipoEventoTracking';
import { TrackingListaRequest } from 'app/_models/trackinglista-request';
import { RastreamentoCargasVM } from 'app/_services/rastreamento-cargas-vm';
import { NavioViagensRotas } from 'app/_models/navio-viagens-rotas';
import { AnalyticsTela } from '../../_enums/analytics-tela';
import { AnalyticsDownload } from '../../_enums/analytics-download';

import { AppService } from 'app/_services/app.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from 'app/_services/message.service';
import { ParametroService } from 'app/_services/parametro.service';
import { RastreamentoService } from 'app/_services/rastreamento.service';
import { filtrosLaterais } from '../../_models/filtros-laterais';
import { FiltrosLateraisService } from 'app/_services/filtros-laterais.service';
import { AnalyticsService } from 'app/_services/analytics.service';
import { GrupoEconomicoService } from 'app/_services/grupo-economico.service';
import { MapsService } from 'app/_services/maps.service';
import { Co2Total } from 'app/_models/co2-total';

@Component({
  selector: 'app-rastreamento',
  templateUrl: './rastreamento.component.html',
  styleUrls: ['./rastreamento.component.scss']
})
export class RastreamentoComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  @ViewChild(BuscaRastreamentoComponent, {static: true}) buscaRastreamento: BuscaRastreamentoComponent;
  @ViewChild(FiltroRastreamentoComponent, {static: true}) filtroRastreamento: FiltroRastreamentoComponent;
  @ViewChild(ListaRastreamentoComponent, {static: true}) listaRastreamento: ListaRastreamentoComponent;
  @ViewChild(OrdenacaoRastreamentoComponent, {static: true}) ordenacaoRastreamento: OrdenacaoRastreamentoComponent;
  @ViewChild(FiltrosLateraisComponent, {static: true}) filtroLateral: FiltrosLateraisComponent;

  @ViewChild(DetalhesContainerComponent, {static: true}) detalhesContainer : DetalhesContainerComponent;
  @ViewChild(NavioRotaComponent, {static: true}) navioRota : NavioRotaComponent;

  @ViewChild('modalVerMapa', {static: true}) modalVerMapa;

  rastreamentoCargasVM: RastreamentoCargasVM = new RastreamentoCargasVM();
  rastVM_filtrado: RastreamentoCargasVM = new RastreamentoCargasVM();
  empty = true;
  loading = false;
  loadingMapa = false;
  downloading = false;
  noresult = false;
  eAdmin: boolean = this._appService.isAdmin();
  filtroPesquisa: TrackingListaRequest;
  pageSize: number = 10;
  paginacao: any;
  FiltrosLaterais: filtrosLaterais = new filtrosLaterais();
  tracking: TrackingLista;
  rotas: NavioViagensRotas[];
  totalCo2: Co2Total;

  constructor(private _rastreamentoService: RastreamentoService,
              private _grupoService: GrupoEconomicoService,
              private _parametroService: ParametroService,
              private _sharedService: SharedService,
              private _appService: AppService,
              private _messageService: MessageService,
              private _filtroLateralService: FiltrosLateraisService,
              private _analyticsService: AnalyticsService,
              private _mapsService: MapsService) {
    super();
  }

  ngOnInit() {
    this.obterParametrosPorto();

    if (!this.eAdmin) {
      this.pesquisaInicial();
    }
    else {
      this.buscaRastreamento.cnpjdropdown.cnpjsGrupos = localStorage.getItem('cnpjGrupos');
      if (this.buscaRastreamento.cnpjdropdown.cnpjsGrupos)
        this.pesquisaInicial();
    }

    this.buscaRastreamento.carregaFiltrosDeRastreamento.subscribe(
      (filtro: TrackingListaRequest) => {
        if (filtro) {
          this.pesquisar(filtro);
        }
    });

    this.buscaRastreamento.zeraPesquisa.subscribe(
      (a: any) => {
        this.zeraPesquisa();
    });

    this.listaRastreamento.carregaFiltrosDeRastreamento.subscribe(
      (filtro: TrackingListaRequest) => {
        if (this.filtroPesquisa && this.filtroPesquisa.cnpjCli) {
          this.pesquisar(this.filtroPesquisa);
        }
        else {
          this.pesquisaInicial();
        }
    });

    // Exportar arquivo em formato CSV
    this.ordenacaoRastreamento.exportarArquivo.subscribe(
      (exportar: boolean) => {
        if (exportar) {
          this.exportarRelatorioCSV();
        }
    });

    // Ordenação da Lista do Tracking
    this.ordenacaoRastreamento.ordenarTrackins.subscribe(
      (campoDeOrdenacao: string) => {
        this.listaRastreamento.obtemListaTrackingOrdenadaPorCampo(campoDeOrdenacao);
    });

    this.ordenacaoRastreamento.quantidadePorPagina.subscribe(
      (quantidadePorPagina: number) => {
        this.listaRastreamento.setQuantidadeRegistrosPorPagina(quantidadePorPagina);
    });

    // Envia mensagens de erro conforme navegação e interação com a tela
    this._messageService.emitirMensagem
      .subscribe(
        msg => {
          if (this.modalVerMapa)
            this.modalVerMapa.hide();
          if (msg.titulo == 'Modal'){
            this.listaRastreamento.exibirErroModal(msg.mensagem);
            this.modalVerMapa.hide();
          }
          else{
            this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
          }
        });

    //Filtros Laterais
    this.filtroLateral.filtroAplicado.subscribe(
      (lista: any) => {
        let listaFiltrada: TrackingLista[] = lista;
        let rastVM: RastreamentoCargasVM = new RastreamentoCargasVM();
        let tipoEventos: TipoEventoTracking[] = [];

        listaFiltrada.forEach( track => {
          if (tipoEventos.filter(f => f.descricao.trim().toLowerCase() == track.statusBooking.trim().toLowerCase()).length == 0) {
            let tp: TipoEventoTracking = new TipoEventoTracking();
            tp.descricao = track.statusBooking;
            tp.quantidade = 1;
            tipoEventos.push(tp);
          }
          else {
            let tp: TipoEventoTracking = tipoEventos.find(f => f.descricao.trim().toLowerCase() == track.statusBooking.trim().toLowerCase())
            tp.quantidade = tp.quantidade + 1;
          }
        });

        rastVM.tiposEventos = tipoEventos;
        rastVM.trackings = listaFiltrada;
        this.rastVM_filtrado = rastVM;
        this.filtroRastreamento.carregaDadosEmComponente(rastVM);
        //this.aplicarFiltro(this.filtroRastreamento.stepAtivo);
        this.filtroRastreamento.stepClick('total');
    });

    this._analyticsService.RegistrarAcesso(AnalyticsTela.Rastreamento);
  }

  ngAfterViewInit(): void {
    this._grupoService.grupoEconomicoSelecionadoEvent.subscribe((cnpjs: string) => {
        if (cnpjs)
          this.buscaRastreamento.cnpjAlterado(cnpjs); //.Pesquisar();
        else
          this.buscaRastreamento.zeraPesquisa.emit();
      });

      this.listaRastreamento.visualizarMapaEmmiter.subscribe(
        (tracking: TrackingLista) => {
          this.loadingMapa = true;
          this.tracking = tracking;
          this.obtemRotasRastreamentos(tracking);
      });

      this._mapsService.RemoveMapaExistente();
  }

  zeraPesquisa() {
    this.ordenacaoRastreamento.resetOrdenacao();
    this.empty = true;
    this.noresult = false;
    this.rastreamentoCargasVM = new RastreamentoCargasVM();
    this.FiltrosLaterais = new filtrosLaterais();
    this.filtroRastreamento.zeraQuantidades();
    if (this.paginacao) {
      this.paginacao.pagedItems = [];
      this.paginacao.pager = {};
    }
  }

  pesquisaInicial(): void {
    const filtro: TrackingListaRequest = new TrackingListaRequest();
    filtro.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    //filtro.cnpjCli = this._appService.UsuarioLogado.CNPJs.join(";");
    filtro.cnpjCli = this.buscaRastreamento.cnpjdropdown.CNPJs ? this.buscaRastreamento.cnpjdropdown.CNPJs.join(";") :
                    this.buscaRastreamento.cnpjdropdown.CNPJDigitado ? this.buscaRastreamento.cnpjdropdown.CNPJDigitado :
                    this.buscaRastreamento.cnpjdropdown.cnpjsGrupos ? this.buscaRastreamento.cnpjdropdown.cnpjsGrupos : "";

    filtro.dataFim = null   // Default: Todos os trackings que ainda não foram realizados
    filtro.dataIni = null;
    filtro.status = true;
    this.pesquisar(filtro);
  }

  pesquisar(consulta: TrackingListaRequest): void {
    this.listaRastreamento.totalCo2.emit(null);
    this.ordenacaoRastreamento.resetOrdenacao();
    this.loading = true;
    this.FiltrosLaterais = new filtrosLaterais();
    this.filtroPesquisa = consulta;

    //this.consulta = consulta;
    this.filtroRastreamento.zeraQuantidades();
    // Obtem listagem de trackings
    this.buscaRastreamento.ObterMunicipios(this._appService.isAdmin() ? consulta.cnpjCli : null);
    this._rastreamentoService.obtemRastreamentosDeCargas(consulta)
      .subscribe(x => {
        this.rastreamentoCargasVM = x;
        this.rastVM_filtrado = x;
        this.listaRastreamento.filtroPesquisaPrincipal = this.filtroPesquisa;
        this.listaRastreamento.setTiposEventos(x.tiposEventos);
        this.filtroRastreamento.carregaDadosEmComponente(x);
        //this.aplicarFiltro.(this.filtroRastreamento.stepAtivo);
        this.filtroRastreamento.stepClick('total');  // -- Solicitação da Belle 01/03 -- Homologação SP2
        this.empty = false;
        this.noresult = (!this.rastreamentoCargasVM.trackings || (this.rastreamentoCargasVM.trackings.length === 0));
        this.paginacao = this.listaRastreamento.paginacao;
      },
      (err) => {
        this.loading = false;
      },
      () => this.loading = false);
  }

  exportarRelatorioCSV() {
    this.downloading = true;
    //this._rastreamentoService.exportarRelatorioCSV(this.listaRastreamento.rastreamentos);

    this._analyticsService.RegistrarDownload(AnalyticsTela.Rastreamento, AnalyticsDownload.ExportacaoCSV);

    // Obtem listagem de trackings específica para download
    this._rastreamentoService.obtemTrackingsDownload(this.filtroPesquisa)
      .subscribe(x => {
        if (x != null)
          var lista = this._rastreamentoService.filtraListaPorTipoDeEvento(x, this.filtroRastreamento.stepAtivo)
          lista = this._filtroLateralService.filtrarDados(lista, this.FiltrosLaterais);
          this._rastreamentoService.exportarRelatorioCSV(lista);
          this.downloading = false;
      });
  }

  aplicarFiltro(tipoEvento: string) {
    var rastVM: RastreamentoCargasVM = this.rastVM_filtrado ? this.rastVM_filtrado : this.rastreamentoCargasVM;
    this.listaRastreamento.carregaListaTrackins(
      this._rastreamentoService.filtraListaPorTipoDeEvento(rastVM.trackings, tipoEvento)
    );
  }

  obterParametrosPorto() {
    this._parametroService.carregarPagina()
      .subscribe(pagina => {
        this.listaRastreamento.paginaParametroPorto = pagina;
        this.filtroRastreamento.paginaParametroPorto = pagina;
      });
  }

  //Construir método que chamará BuscaScheduleParaMontarRotas
  obtemRotasRastreamentos(tracking: TrackingLista) {
    const filtro: TrackingListaRequest = new TrackingListaRequest();
    filtro.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    //filtro.cnpjCli = this._appService.UsuarioLogado.CNPJs.join(";");
    filtro.cnpjCli = tracking.cnpjCli;
    filtro.filtroGenerico2 = tracking.numContainer;
    filtro.numBooking = tracking.numBooking;
    filtro.idBooking = tracking.idBooking;
    filtro.numContainer = tracking.numContainer;

    filtro.dataFim = new Date();   // Default: data atual - 10 dias
    filtro.dataIni = this._sharedService.addDays(filtro.dataFim, -10);


    this._rastreamentoService.obtemRotasRastreamentos(filtro).subscribe(x => {
      this.rotas = x.ListaNavioViagensRotas;
      this._mapsService.ConfiguraMapa(x.ListaPontosGeograficos);
      this.loadingMapa = false;
    });
  }

  limparMapa(){
    this._mapsService.LimpaMarcadores();
  }
}
