import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';

import { OrdemServico } from 'app/_models/ordemServico';
import { OrdemServicoRequest } from 'app/_models/ordemServicoRequest';
import { TipoEventoTracking } from 'app/_models/tipoEventoTracking';

import { BaseComponent } from '../../shared/base.component';
import { ItemProvedorComponent } from './item-provedor/item-provedor.component';
import { FecharOrdemComponent } from './fechar-ordem/fechar-ordem.component';
import { PaginacaoComponent } from '../../shared/paginacao/paginacao.component';

import { SharedService } from '../../_services/shared.service';
import { OrdemServicoService } from 'app/_services/ordem-servico.service';
import { GenericOrderbyService } from 'app/_services/generic-orderby.service';
import { AppService } from '../../_services/app.service';

@Component({
  selector: 'lista-provedor',
  templateUrl: './lista-provedor.component.html',
  styleUrls: ['./lista-provedor.component.scss']
})

export class ListaProvedorComponent extends BaseComponent implements OnInit {
  @ViewChild('botaoCancelar', {static: true}) botaoCancelar;
  @ViewChild(ItemProvedorComponent, {static: true}) itemProvedor: ItemProvedorComponent;
  @ViewChild(FecharOrdemComponent, {static: true}) fecharOrdem: FecharOrdemComponent;
  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;

  @Input() classePrincipal: string;
  @Input() exibirSelecao: boolean;
  @Input() exibirNumOs: boolean;
  @Input() exibirFatura: boolean;
  @Input() exibirTipoServico: boolean;
  @Input() exibirDataProgramacao: boolean;
  @Input() exibirEmbarque: boolean;
  @Input() exibirNavio: boolean;
  @Input() exibirContainer: boolean;
  @Input() exibirAcoes: boolean;
  @Input() exibirTracking: boolean;
  @Input() exibirMunicipio: boolean;

  @Output() carregarFiltros: EventEmitter<OrdemServicoRequest> = new EventEmitter();

  @Output() emitirFecharOSsSelecionadas: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() emitirSelecionarTodasOSs: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() emitirVincularNF: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() emitirSalvarOSs: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() emitirFecharOS: EventEmitter<OrdemServico> = new EventEmitter();

  loadingOrdens: boolean = false;
  ocultarSelecao: boolean = true;

  ordens: OrdemServico[] = [];
  tpEventos: TipoEventoTracking[] = [];

  constructor(private _sharedService: SharedService,
    private _genericOrderbyService: GenericOrderbyService,
    private _appService: AppService) {

    super();
  }

  ngOnInit() {
  }

  carregaListaOrdens(ordens: OrdemServico[], ordenacao: string = "") {
    this.ordens = ordens;

    if (this.ordens && this.ordens.length > 0) {
      if (this.exibirSelecao) {
        let filterToHide = this.ordens.find(item => item.descTipoServico !== "Transporte Rodoviário" && item.statusOs.toLowerCase() === "aberta");
        this.ocultarSelecao = filterToHide === undefined || filterToHide === null;
      }

      this.ordens.forEach(ord => {
        ord.numContainer = (ord.numContainer != null) ? ord.numContainer.toUpperCase() : null;
        ord.numFaturaOrdenacao = (ord.numFatura != null && ord.numFatura != "") ? Number(ord.numFatura.trim()) : 0;
      });
    }

    if (ordenacao != null && ordenacao != "")
      this.paginacao.setAllData(this._genericOrderbyService.ordenarPorCampo(this.ordens, ordenacao, true));
    else    
      this.paginacao.setAllData(this.ordens);
    
    this.paginacao.setPageSize(10);
    this.paginacao.setPage(1);
  }

  obtemListaOrdensOrdenadaPorCampo(campo: string) {
    if (this.ordens) {
      this.paginacao.setAllData(this._genericOrderbyService.ordenarPorCampo(this.ordens, campo, true));
    }
  }

  setTiposEventos(tiposEventos: TipoEventoTracking[]): void {
    this.tpEventos = tiposEventos;
  }

  setQuantidadeRegistrosPorPagina(quantidadePorPagina: number) {
    this.paginacao.setPageSize(quantidadePorPagina);
  }

  fecharOrdensServicoSelecionadas(OS: OrdemServico) {
    this.emitirFecharOSsSelecionadas.emit(OS);
  }

  selecionarTodasOrdensServico() {
    this.emitirSelecionarTodasOSs.emit();
  }

  salvarOrdensServico() {
    this.emitirSalvarOSs.emit();
  }

  vincularNF(OS: OrdemServico) {
    this.emitirVincularNF.emit(OS);
  }

  fecharOS(OS: OrdemServico) {
    this.emitirFecharOS.emit(OS);
  }
}
