import { CommonModule } from '@angular/common';

import { DatePickerModule } from "app/shared/ng2-datepicker/ng2-datepicker.module";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';

//COTACAO
import { Cotacao } from "app/_models/cotacao";
import { CotacaoComponent } from './cotacao.component';
import { CotacaoRequest } from "app/_models/cotacao-request";
import { CotacaoRoutingModule } from './cotacao.routing.module';
import { CotacaoService } from './../../_services/cotacao.service';
import { SolicitarCotacaoComponent } from './solicitar-cotacao/solicitar-cotacao.component';
import { UploadArquivoService } from '../../_services/upload-arquivo.service';
import { AnalyticsService } from '../../_services/analytics.service';
import { DatepickerService } from 'app/_services/datepicker.service';
import { UsuarioService } from 'app/_services/usuario.service';
import { AppService } from 'app/_services/app.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        CotacaoRoutingModule,
        CurrencyMaskModule,
        ModalModule.forRoot(),
        DatePickerModule,
        SharedModule
    ],
    exports: [],
    declarations: [
        CotacaoComponent,
        SolicitarCotacaoComponent
    ],
    providers: [
        AppService,
        CotacaoService,
        UploadArquivoService,
        CotacaoRequest,
        Cotacao,
        AnalyticsService,
        DatepickerService,
        UsuarioService
    ],
})
export class CotacaoModule { }
