import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import swal from 'sweetalert2';
import { Prioridade } from 'app/_models/prioridade';
import { BaseComponent } from 'app/shared/base.component';

import { Porto } from 'app/_models/porto';
import { Navio } from 'app/_models/navio';
import { FilterComboConfiguration } from 'app/_models/filterComboConfiguration';

import { MessageService } from 'app/_services/message.service';
import { PrioridadeService } from 'app/_services/prioridade.service';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { Operacao } from 'app/_enums/operacao';

@Component({
  selector: 'lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent extends BaseComponent implements OnInit {

  @Input() prioridades: Prioridade[];
  @Output() atualizaLista: EventEmitter<any> = new EventEmitter<any>();

  portos: Porto[];
  navios: Navio[];

  loading: boolean = false;

  comboNavios: FilterComboConfiguration;
  comboPortos: FilterComboConfiguration;

  constructor(private _msgService: MessageService,
              private _prioridadeService: PrioridadeService) {
    super();

    this.comboNavios = new FilterComboConfiguration('Navio(s)...');
    this.comboNavios.settings = {
      checkedStyle: 'glyphicon',
      showCheckAll: false, //true,
      showUncheckAll: false, //true,
      dynamicTitleMaxItems: 1,
      enableSearch: false
    };

    this.comboPortos = new FilterComboConfiguration('Porto...');
    this.comboPortos.settings = {
      checkedStyle: 'glyphicon',
      selectionLimit: 1,
      showCheckAll: false,
      showUncheckAll: false,
      dynamicTitleMaxItems: 1,
      enableSearch: false,
      closeOnSelect: true
    };
   }

  ngOnInit() {

  }

  populaCombos() {

    this.comboNavios.options = [];
    if (this.navios != null) {
      this.navios.forEach(navio => {
        this.comboNavios.options.push({ id: navio.codigo, name: navio.descricao });
      });
    }

    this.comboPortos.options = [];
    if (this.portos != null) {
      this.portos.forEach(porto => {
        this.comboPortos.options.push({ id: porto.codigo, name: porto.descricao });
      });
    }
  }

  DeletarPrioridade(prioID: number) {
    this._msgService.clearMessage();
    swal({
      title: 'Confirmação',
      text: 'Deseja excluir a prioridade cadastrada?',
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(success => {

        this.loading = true;
        this.prioridades = [];
        this._prioridadeService.excluirPrioridade(prioID)
          .subscribe(result => {
            this.loading = false;
            if (result.IsValid) {
              this._msgService.addMessage('Prioridades', result.Message, "success");
              this.atualizaLista.emit();
            }
            else {
              var msgErr: string[] = [];
              result.Erros.forEach(element => msgErr.push(element.Message));
              this._msgService.addMessageArray("Prioridades", msgErr, "error", LogFrontFuncionalidade.Parametros, Operacao.Exclusao, prioID);
            }
        });
      }, dismiss => {
      });
    }
}
