import { Funcionalidade } from './funcionalidade';

export class Perfil{
    perfilID: number;
    descricao: string;
    ativo: boolean;
    funcionalidades: Funcionalidade[];
    totalUsuarios: number;
    cliente: boolean;
    provedor: boolean;
    admin: boolean;
}