import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor() { }
  public activate: (message?: string, title?: string, textbutton1?: string, textbutton2?: string) => Promise<boolean>;
}
