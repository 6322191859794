import { Component, Input, ViewChild } from '@angular/core';
import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";

import * as moment from 'moment/moment';
import 'moment/locale/pt-br';

import { TipoContainer } from '../../../../../../_models/tipo-container';
import { AgendamentoColeta } from '../../../../../../_models/agendamento-coleta';
import { DetalhesContainerComponent } from "app/shared/detalhes-container/detalhes-container.component";
import { SharedService } from './../../../../../../_services/shared.service';
import { DatepickerService } from 'app/_services/datepicker.service';
import { Booking } from 'app/_models/booking';

@Component({
  selector: 'item-agendamento',
  templateUrl: './item-agendamento.component.html',
  styleUrls: ['./item-agendamento.component.scss']
})
export class ItemAgendamentoComponent {

  @Input() index: number;
  @Input() modal: string;
  @Input() cargaPerigosa: string;
  @Input() tipoContainer: TipoContainer;
  @Input() agendamento: AgendamentoColeta;
  @Input() edita: boolean;
  @Input() booking: Booking;

  @ViewChild(DetalhesContainerComponent, {static: true}) detalhesContainer : DetalhesContainerComponent;

  dataSelecionada: DateModel;
  options: DatePickerOptions;
  mascaraHora: Array<string | RegExp> = [/[0-2]/,/[0-9]/, ':', /[0-5]/, /[0-9]/];
  
  constructor(private _datePickerService: DatepickerService, 
              private _sharedService: SharedService) {
    this.options = _datePickerService.GenerateOptionsConfig(this._sharedService.addDays(new Date(), 1));
  }

  changeRegex(input_time){
    if(input_time.charAt(0) == '2'){
       this.mascaraHora[1]=new RegExp('[0-3]')
    }else{
       this.mascaraHora[1]=new RegExp('[0-9]')
    }
  }

  podeEditar(): boolean {
    return ((this.agendamento.numAgendamento == 0) && this.edita);
  }

  formataData(formato: string): string{
    return (!this.agendamento || this.agendamento.numAgendamento == 0) ? "" : moment(this.agendamento.dataColeta).format(formato);
  }
}
