import { SporadicTaxItem } from './SporadicTaxItem';
export class BookingShippingRequest {
  cteIssueDate: string;
  containersQuantity: number;
  equipmentSize: number;
  region: string;
  modalDeparture: string;
  serviceType: string;
  classification: string;
  paymentType: string;
  shipArrivalDate: string;
  departureUf: string;
  destinationUf: string;

  adValoremRate: number;
  adValorem: number;

  nfTotalAmount: number;
  negotiatedShipping: number;
  originPortId: number;
  destinationPortId: number;
  originRegionId: number;
  destinationRegionId: number;
  originModalId: string;
  destinationModalId: string;

  icms: number;
  icmsRate: number;
  icmsRateNotes: string;
  totalPaymentAmount: number;
  totalShipping: number;
  additionalTaxes: number;
  bafTotalValue: number;
  textoPix: string;
  qrCode: string;
  numBooking: string;


  sporadicTaxList: SporadicTaxItem[];
}
