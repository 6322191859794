
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';

import { CtmNf } from 'app/_models/ctmNf';
import { Incidente } from 'app/_models/incidente';
import { OrdemServico } from 'app/_models/ordemServico';
import { OrdemServicoVM } from './../_models/ordemServicoVM';
import { IncidenteRequest } from './../_models/incidente-request';
import { ValidationResult } from "app/_models/validation-result";
import { OrdemServicoRequest } from 'app/_models/ordemServicoRequest';
import { AtualizarOrdemServico } from 'app/_models/atualizar-ordem-servico';

import { BaseService } from "app/_services/base.service";
import { MessageService } from './message.service';

import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsTela } from '../_enums/analytics-tela';
import { Operacao } from '../_enums/operacao';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { OrdemServicoDownload } from 'app/_models/ordemServicoDownload';
import { SharedService } from './shared.service';
import { TipoContainer } from 'app/_models/tipo-container';

@Injectable({
  providedIn: 'root'
})
export class OrdemServicoService {

  constructor(private baseService: BaseService,
    private _sharedService: SharedService,
    private _msgService: MessageService,
    private _analyticsService: AnalyticsService) {
  }

  atualizarNotasFiscais(nfs: CtmNf[]): Observable<CtmNf[]> {
    return this.baseService.Put('ordemservico/ctmnf', nfs).pipe(map((response: ValidationResult) => {
      const retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._analyticsService.RegistrarErro(AnalyticsTela.VincularNF, retorno);
        // this._msgService.addMessageValidationError('Ordens de Serviço', retorno.Erros, 'error');
        throw retorno.Erros;
      }

      return retorno.Data as CtmNf[];
    }),catchError(e => {
      return observableThrowError(e);
    }),);
  }

  atualizarOrdensDeServico(ordens: AtualizarOrdemServico[]): Observable<AtualizarOrdemServico[]> {
    return this.baseService.Put('ordemservico', ordens).pipe(map((response: ValidationResult) => {
      const retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._analyticsService.RegistrarErro(AnalyticsTela.OrdemServico, retorno);
        //this._msgService.addMessageValidationError('Ordens de Serviço', retorno.Erros, 'error');
        throw retorno.Erros;
      }

      return retorno.Data as AtualizarOrdemServico[];
    }),catchError(e => {
      //return this.baseService.handleError(e);
      return observableThrowError(e);
    }),);
  }

  obtemOrdensDeServico(consulta: OrdemServicoRequest, tela: AnalyticsTela): Observable<OrdemServicoVM> {
    var func = consulta.vincularNF ? LogFrontFuncionalidade.VincularNF : LogFrontFuncionalidade.OrdemDeServico;
    return this.baseService.Post('ordemservico', consulta).pipe(map((response: ValidationResult) => {
      const retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._analyticsService.RegistrarErro(tela, retorno);
        this._msgService.addMessageValidationError('Ordens de Serviço', retorno.Erros, 'error', func, Operacao.Consulta, consulta);
      }

      return retorno.Data as OrdemServicoVM;
    }),catchError(e => {
      return this.baseService.handleError(e);
    }),);
  }

  obtemOrdensDeServicoCSV(consulta: OrdemServicoRequest): Observable<OrdemServicoDownload[]> {
    return this.baseService.Post('ordemservico/csv', consulta).pipe(map((response: ValidationResult) => {
      const retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._msgService.addMessageValidationError('Ordens de Serviço', retorno.Erros, 'error');
      }

      return retorno.Data as OrdemServicoDownload[];
    }),catchError(e => {
      return this.baseService.handleError(e);
    }),);
  }

  obtemCtmNfs(consulta: OrdemServicoRequest): Observable<CtmNf[]> {
    var func = consulta.vincularNF ? LogFrontFuncionalidade.VincularNF : LogFrontFuncionalidade.OrdemDeServico;

    return this.baseService.Post('ordemservico/ctmnf', consulta).pipe(map((response: ValidationResult) => {
      const retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._analyticsService.RegistrarErro(AnalyticsTela.VincularNF, retorno);
        this._msgService.addMessageValidationError('Ordens de Serviço - CTM', retorno.Erros, 'error', func, Operacao.Consulta, consulta);
      }

      return retorno.Data as CtmNf[];
    }),catchError(e => {
      return this.baseService.handleError(e);
    }),);
  }

  getTipoContainersOS(idFamiliaProduto: number): Observable<TipoContainer[]> {
    return this.baseService.Get('ordemservico/containers/' + idFamiliaProduto).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as TipoContainer[];
        else
          return null;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  obtemIncidentes(consulta: IncidenteRequest): Observable<Incidente[]> {
    return this.baseService.Post('ordemservico/incidente', consulta).pipe(map((response: ValidationResult) => {
      const retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._analyticsService.RegistrarErro(AnalyticsTela.OrdemServico, retorno);
        this._msgService.addMessageValidationError('Ordens de Serviço - Incidentes', retorno.Erros, 'error', LogFrontFuncionalidade.OrdemDeServico, Operacao.Consulta, consulta);
      }

      return retorno.Data as Incidente[];
    }),catchError(e => {
      return this.baseService.handleError(e);
    }),);
  }

  exportarRelatorioCSV(ordens: OrdemServicoDownload[]) {

    if (!ordens || ordens.length === 0)
      return;

    const data: any[] = [];
    const titulos: any[] = [
      'OS',
      'Tipo de OS',
      'Fatura',
      'Número do Booking',
      'Tipo de Serviço',
      'Navio / Viagem',
      'Origem/Destino',
      'Embarcador/Destinatário',
      'Município',
      'Data da Programação',
      'Contêiner',
      'Status',
      'Retirada do Terminal',
      'Chegada no Cliente',
      'Saída do Cliente',
      'Entrega no Terminal',
      'Status NF',
      'Número do BL',
      'Número do CT-e Aliança',
      'Número da Apólice de Seguro',
      'Número da Averbação do Seguro',
      'Número Cte',
      'Número Ctm',
      'Número NF',
      'Chave Cte',
      'Chave Ctm',
      'Chave Nfe'
    ];

    ordens.forEach(x => {
      var munic = (x.municOriDes) ? x.municOriDes.trim() + "/" + x.ufOriDes : "";
      data.push([
        x.numOs,
        x.tipo,
        x.numFatura,
        x.numBooking,
        x.descTipoServico,
        x.descNavioViagem,
        x.portoOriDes,
        x.nomeEmbDest,
        munic,
        this._sharedService.formataDataCSV(x.dhProgramacao),
        x.numContainer,
        x.statusOs,
        this._sharedService.formataDataCSV(x.dtRetiradaTerminal),
        this._sharedService.formataDataCSV(x.dtInicioExecucao),
        this._sharedService.formataDataCSV(x.dtFimExecucao),
        this._sharedService.formataDataCSV(x.dtEntregaTerminal),
        x.statusPortal,
        x.numBL,
        x.numCte,
        x.numApolice,
        x.numAverbacao,
        x.numCte,
        x.numCtm,
        x.numNf,
        x.chaveCte,
        x.chaveCtm,
        x.chaveNfe
      ]);
    });

    this.baseService.DownloadCSV(data, titulos, 'Ordens de Serviço.csv');
  }

  filtrarPorStep(ordens: OrdemServico[], tipoEventoDaImagem: string): OrdemServico[] {
    if (!ordens || tipoEventoDaImagem == 'total') {
      return ordens;
    }

    const tipoEvento = tipoEventoDaImagem.trim().toLowerCase();
    return ordens.filter(x => x.statusPortal && x.statusPortal.trim().toLowerCase() == tipoEvento);
  }

  validarNfe(nfe: CtmNf) {
    if (nfe === undefined || nfe === null || !nfe.chaveNfe) {
      return false;
    }

    return true;
  }
}
