import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from '../_services/app.service'; // Importe o serviço necessário

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(private appService: AppService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {


        if (error.status === 401) {
          this.appService.acessoNegado();
        }
        return throwError("ocorreu um erro na requisição");
      })
    );
  }
}
