import { Component, Input, OnInit, ViewChild, NgModule } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MessageService } from "./../../../_services/message.service";
import { MessageComponent } from "./../../../shared/message/message.component";
import { DataService } from "./../../../_services/data.service";
import { SharedService } from "./../../../_services/shared.service";
import { BaseComponent } from "./../../../shared/base.component";



import { Observable } from "rxjs";
import { Subscription } from "rxjs";


//DATEPICKER
import { DatepickerService } from "app/_services/datepicker.service";
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DateExtensions } from 'app/shared/extensions/extensions.module';


// MAERSK
import { ListItem } from "./../../../_models/listItem";
import { LogFrontFuncionalidade } from "app/_enums/log-front-funcionalidade";
import { Operacao } from "app/_enums/operacao";
import { SubTributariaService } from "./../../../_services/sub-tributaria.service";
import { SubTributaria } from "../../../_models/sub-tributaria";
import { UF } from "../../../_models/uf";
import { CustomerInfo } from "app/_models/customer-info";
import { CustomerInfoService } from "app/_services/customer-info.service";
import { AppService } from "app/_services/app.service";


@Component({
  selector: "sub-tributaria-detalhe",
  templateUrl: "./sub-tributaria-detalhe.component.html",
  styleUrls: ["./sub-tributaria-detalhe.component.scss"],
})
export class SubTributariaDetalheComponent extends BaseComponent
  implements OnInit {
  inscricaoGet: Subscription;
  @ViewChild(MessageComponent, { static: true }) mensagem: MessageComponent;

  titulo: string = "Nova Substituição Tributária";
  // //listItem: ListItem;
  isNovaTaxa: boolean = true;

  regioesGeograficas: UF[] = new Array();
  tiposModais: ListItem[] = new Array();
  listGrupoEconomico: ListItem[] = new Array();

  subTributaria: SubTributaria = new SubTributaria();

  loading: boolean = false;

  dataInicioOptions: DatePickerOptions;
  optionsFim: DatePickerOptions;

  dataInicio: DateModel;
  dataFim: DateModel;

  startDate: Date = new Date();
  expirationDate: Date = new Date();
  isPeriodoInvalido: boolean = false;


  // MAERSK
  id: number = 0;
  year: number = 0;
  month: number = 0;
  day: number = 0;
  qtdDiasFim: number = 360;



  constructor(
    private _dataService: DataService,
    private _route: ActivatedRoute,
    private _msgService: MessageService,
    private _sharedService: SharedService,
    private _router: Router,
    private _datepickerService: DatepickerService,
    private _subTributariaService: SubTributariaService,
    private _customerInfoService: CustomerInfoService,
    private route: ActivatedRoute,
    private _appService: AppService

  ) {
    super();

    this.dataInicio = DateExtensions.toDateModel(new Date(), "DD/MM/YYYY");
    this.dataInicioOptions = this._datepickerService.GenerateOptionsConfig(new Date());
    this.startDate = new Date();

    this.optionsFim = this._datepickerService.GenerateOptionsConfig(
      this._sharedService.addDays(new Date(), this.qtdDiasFim));

    this._msgService.emitirMensagem.subscribe((msg) => {
      this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
    });



    this.year = this.startDate.getFullYear();
    this.month = this.startDate.getMonth();
    this.day = this.startDate.getDay();

  }

  ngOnInit(): void {

    this.ObterDataFields();

    this.inscricaoGet = this._route.params.subscribe((params: any) => {
      this.id = params["id"];
      if (this.id) {
        this.titulo = "Editar Substituição Tributária";
        this.loading = true;

        this.getSubstituicaoTributaria(this.id);
      }
      else {
        this.titulo = "Nova Substituição Tributária";
        this.subTributaria = new SubTributaria();
      }
    });
  }

  formataData(formato: string): string {
    return "";
  }

  // Eventos dos Objetos
   OnChangeDepartureUfCmb(value: any) {
     this.subTributaria.departureUf = value.target.value;
   }

  OnChangeUfTomadorCmb(value: any) {
     this.subTributaria.destinationUf = value.target.value;
   }

   OnChangeModalCmb(value: any) {
     this.subTributaria.modal = value.target.value;
   }

  OnChangeGrupoEconomicoCmb(value: any) {
    this.subTributaria.groupEconomy = value.target.value;
  }

  // OnChangeDestinationModalCmb(value: any) {
  //   this.subTributaria.destinationModalId = value.target.value;
  //   this.getDataDisponivel();
  // }

  // Metodos auxiliares
  ObterDataFields() {
    this._dataService.getUFs().subscribe((lista) => {
      this.regioesGeograficas = lista;
    });

    this._dataService.getTipoModal().subscribe((lista) => {
      this.tiposModais = lista;
    });

    this.listGrupoEmpresarial();

  }


  setUfs(uf: number):string
  {
    let item1 = this.regioesGeograficas.find(i => i.codigo === uf);
    return item1.descricao;
  }

  setGrupoEmpresarial(corporationId: string):string
  {
    let item1 = this.listGrupoEconomico.find(i => i.codigo === corporationId);
    return item1.descricao;
  }


  listGrupoEmpresarial() {
    this._customerInfoService
      .listGrupoEmpresarial()
      .subscribe((lista) => {


        this.listGrupoEconomico = lista;
        this.loading = false;

      });
  }

  private ObterData(dateModel: DateModel, dateOffset: number) {
    return dateModel != undefined
      ? this._datepickerService.ObtemData(dateModel)
      : this._sharedService.addDays(new Date(), dateOffset);
  }

  onSaveSubTributaria() {
    this._msgService.clearMessage();


    this.subTributaria.isEnabled = true;

    if(this.subTributaria.id > 0)
    {
      this.subTributaria.updatedBy = this._appService.UsuarioLogado.nome.substring(0,15);
    }
    else{
      this.subTributaria.createdBy = this._appService.UsuarioLogado.nome.substring(0,15);
    }

    this._subTributariaService.salvar(this.subTributaria).subscribe((result) => {
      this.loading = false;
      if (result.IsValid) {
        this._router.navigate(["sub-tributaria"]);
        this._msgService.addMessageRedirect(
          "Substituiça Tributária",
          result.Message,
          "success"
        );
      } else {
        var msgErr: string[] = [];
        result.Erros.forEach((element) => msgErr.push(element.Message));
        this._msgService.addMessageArray(
          "Substituiça Tributária",
          msgErr,
          "error",
          LogFrontFuncionalidade.TaxaEsporadica,
          Operacao.Alteracao,
          this.subTributaria
        );
        this.loading = false;
      }
    });
  }

  getSubstituicaoTributaria(id: number) {
    this._subTributariaService
      .getSubTributaria(this.id)
      .subscribe((subTributaria) => {

        subTributaria.diferencyICMS = parseFloat(subTributaria.diferencyICMS.toString().replace(',', '.'));

        this.subTributaria = subTributaria;

        this.loading = false;

      });
  }




  validaDataInicial() {
    let _year: number = parseInt(this.dataInicio.year);
    let _month: number = parseInt(this.dataInicio.month);
    let _day: number = parseInt(this.dataInicio.day);

    let dataTmp: Date = new Date(_year, _month - 1, _day);

    if (dataTmp < this.startDate) {
      this.dataInicioOptions = this._datepickerService.GenerateOptionsConfig(this.startDate);
      this.dataInicio = DateExtensions.toDateModel(this.startDate, "DD/MM/YYYY");
      this.dataInicioOptions.minDate = this.startDate;
    }
  }

  validaDataFinal() {
    let _year: number = parseInt(this.dataFim.year);
    let _month: number = parseInt(this.dataFim.month);
    let _day: number = parseInt(this.dataFim.day);

    let dataTmp: Date = new Date(_year, _month - 1, _day);

    if (dataTmp <= this.startDate) {
      this.isPeriodoInvalido = true;
    }
    else {
      this.isPeriodoInvalido = false;
    }


  }

  // getDataDisponivel() {
  //   // let isSporadicTaxTypeSelected: boolean = this.subTributaria.sporadicTaxTypeId > 0;
  //   // let isOriginRegionSelected: boolean = this.subTributaria.originRegionId > 0;
  //   // let isOriginModalSelected: boolean = this.subTributaria.originModalId != '';
  //   // let isDestinationRegionSelected: boolean = this.subTributaria.destinationRegionId > 0;
  //   // let isDestinationModaSelected: boolean = this.subTributaria.destinationModalId != '';

  //   if (isSporadicTaxTypeSelected && isOriginRegionSelected && isOriginModalSelected && isDestinationRegionSelected && isDestinationModaSelected) {
  //     this._subTributariaService
  //       .findLastExpirationDate(this.subTributaria.sporadicTaxTypeId, this.subTributaria.originRegionId, this.subTributaria.originModalId, this.subTributaria.destinationRegionId, this.subTributaria.destinationModalId)
  //       .subscribe((sporadicTax) => {

  //         if (sporadicTax != null) {
  //           var data = sporadicTax.expirationDate.toString();

  //           this.year = parseInt(data.substr(0, 4));
  //           this.month = parseInt(data.toString().substr(5, 2));
  //           this.day = parseInt(data.toString().substr(8, 2));

  //           this.startDate = new Date(this.year, this.month - 1, this.day);
  //           this.startDate.setDate(this.startDate.getDate() + 1);

  //           this.dataInicioOptions = this._datepickerService.GenerateOptionsConfig(this.startDate);
  //           this.dataInicio = DateExtensions.toDateModel(this.startDate, "DD/MM/YYYY");
  //           this.dataInicioOptions.minDate = this.startDate;

  //           this.loading = false;
  //         }
  //         else {
  //           this.startDate = new Date();
  //           this.year = this.startDate.getFullYear();
  //           this.month = this.startDate.getMonth();
  //           this.day = this.startDate.getDay();

  //           this.dataInicioOptions = this._datepickerService.GenerateOptionsConfig(this.startDate);
  //           this.dataInicio = DateExtensions.toDateModel(this.startDate, "DD/MM/YYYY");
  //           this.dataInicioOptions.minDate = this.startDate;
  //         }

  //         this.optionsFim = this._datepickerService.GenerateOptionsConfig(this._sharedService.addDays(this.startDate, this.qtdDiasFim));
  //         this.dataFim = DateExtensions.toDateModel(this._sharedService.addDays(this.startDate, this.qtdDiasFim), "DD/MM/YYYY");

  //       });
  //   }
  //   else {
  //     console.log('Selecione todos os ids.');
  //   }
  // }

}
