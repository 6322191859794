
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';

import { Booking } from './../_models/booking';
import { Operacao } from '../_enums/operacao';
import { BookingRequest } from './../_models/booking-request';
import { BaseService } from './base.service';
import { ValidationResult } from './../_models/validation-result';
import { MeusBookings } from "app/_models/meus-bookings";
import { MessageService } from './message.service';
import { SharedService } from 'app/_services/shared.service';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { Schedule } from 'app/_models/schedule';
import { BuscaViagensBookingViewModel } from 'app/_models/busca-viagens-booking';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private baseService: BaseService,
              private _msgService: MessageService,
              private _sharedService: SharedService) { }

  getBookings(consulta: BookingRequest): Observable<MeusBookings> {
    return this.baseService.Post('bookings', consulta).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as MeusBookings;
        else {
          var erros: string[] = [];
          erros.push("Erro ao consultar Bookings! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Meus Bookings', erros, "error", LogFrontFuncionalidade.Booking, Operacao.Consulta, consulta);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getBooking({ bookingID, usuarioID }): Observable<Booking> {
    return this.baseService.Post('bookings/detalhe', { idBooking: bookingID, idUsuario: usuarioID }).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Booking;
        else {
          var erros: string[] = [];
          erros.push("Erro ao consultar Bookings! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Booking', erros, "error", LogFrontFuncionalidade.Booking, Operacao.Consulta, { idBooking: bookingID, idUsuario: usuarioID });
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  buscaViagens(consulta: BuscaViagensBookingViewModel): Observable<Schedule[]> {
    return this.baseService.Post('bookings/viagens', consulta).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Schedule[];
        else {
          var erros: string[] = [];
          erros.push("Erro ao Buscar Viagens! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Booking', erros, "error", LogFrontFuncionalidade.Booking, Operacao.Consulta, consulta);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  Solicitar(booking: Booking): Observable<Booking> {
    return this.baseService.Post('bookings/enviar', booking).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Booking;
        else {
          var erros: string[] = [];
          erros.push("Erro ao Solicitar Booking! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Booking', erros, "error", LogFrontFuncionalidade.Booking, Operacao.Inclusao, booking);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  AlterarBooking(booking: Booking): Observable<Booking> {
    return this.baseService.Put('bookings', booking).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Booking;
        else{
          var erros: string[] = [];
          erros.push("Erro ao Alterar a Booking! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Booking', erros, "error", LogFrontFuncionalidade.Booking, Operacao.Alteracao, booking);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  exportarRelatorioCSV(bookings: Booking[]) {
    if (!bookings || bookings.length === 0) {
      return bookings;
    };

    const data: any[] = [];
    const titulos: any[] = ['Número do Booking',
      'Status Booking',
      'Usuário Solicitante do booking',
      'Data e Hora de Solicitação',
      'Viagem Navio',
      'Razão Social Embarcador',
      'CNPJ Embarcador',
      'Quantidade de Contêineres',
      'Modalidade',
      'Número da Proposta comercial',
      'Município Origem',
      'UF Município Origem',
      'Porto Origem',
      'Porto Destino',
      'Município Destino',
      'UF Município Destino',
      'Contêiner (Categoria – Tamanho e tipo)',
      'Flag Carga com excesso',
      'Flag Carga Perigosa',
      'Flag Carga Refrigerada',
      'Serviços Adicionais (separados por vírgula)'];

      bookings.forEach(x => {
      data.push([
        x.numBooking,
        x.statusBooking,
        x.usuarioSolicitantePortal,
        this._sharedService.formataDataCSV(x.dataHoraSolicitacao),
        x.viagemNavio,
        x.nomeEmb,
        x.cnpjEmb,
        x.qtdeContainer,
        x.modal,
        x.numProposta,
        x.municOri,
        x.ufMunicOri,
        x.portoOri,
        x.portoDes,
        x.municDes,
        x.ufDes,
        x.categoriaContainer + ' - ' + x.tamanhoContainer + ' - ' + x.tipoContainer,
        x.flagCargaOver,
        x.flagCargaPerigosa,
        x.flagCargaRefrigerada,
        x.servicosAdicionais
      ]);
    });

    this.baseService.DownloadCSV(data, titulos, 'Bookings.csv');
  }

}
