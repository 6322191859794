
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';


import { NivelLog } from 'app/_enums/nivel-log';
import { BaseService } from './base.service';
import { ValidationResult } from './../_models/validation-result';
import { AppService } from './app.service';
import { Log } from './../_models/log';
import { LogFront } from 'app/_models/log-front';
import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private baseService: BaseService) {

  }

  getLogs(filtro: string): Observable<Log[]> {
    return this.baseService.Post('log', { filtro: filtro }).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Log[];
        else
          return null;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getDescricaoNivel(nivel: NivelLog): string {
    switch (nivel) {
      case NivelLog.Debug:
        return "Debug";
      case NivelLog.Error:
        return "Erro";
    }
  }

  logFront(dados: any, func: LogFrontFuncionalidade, msg: string, oper: Operacao, tela: string) {
    var log = new LogFront();
    log.Dados = JSON.stringify(dados);
    log.Funcionalidade = func;
    log.Mensagem = msg;
    log.Operacao = oper;
    log.Tela = tela;
    log.Browser = this.baseService.getBrowserVersao();
    log.VersaoPortal = this.baseService.getVersaoWebPortal();
    this.addLogFront(log).subscribe();
  }
  
  addLogFront(log: LogFront): Observable<ValidationResult> {
    return this.baseService.Post('log/front', log).pipe(
      map((response: ValidationResult) => {
          let retorno: ValidationResult = response;
          return retorno;
      }),catchError(e => {
          return this.baseService.handleError(e);
      }),);
  }
}
