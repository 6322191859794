import { Injectable } from '@angular/core';
import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class DatepickerService {

  public ObtemData(data: DateModel, formato: string = "DD/MM/YYYY"): Date{
    return moment(data.formatted, formato, true).toDate();
  }

  public IsValid(data: DateModel, formato: string = "DD/MM/YYYY"): boolean{
    return moment(data.formatted, formato, true).isValid();
  }

  public GenerateOptionsConfig(initialDate: Date): DatePickerOptions{
    var config = new DatePickerOptions();
    config.locale = "pt-br";
    config.format = "DD/MM/YYYY";
    config.selectYearText = "Ano";
    config.todayText = "Hoje";
    config.clearText = "Limpar";
    config.initialDate = initialDate;
    config.firstWeekdaySunday = true;

    return config;
  }

  public ObtemDataHora(dt: DateModel, hora: string): Date {
    var data = this.ObtemData(dt);
    return this.ObtemDataHora2(data, hora);
  }

  public ObtemDataHora2(data: Date, hora: string): Date {
    var dia = this.fillZeros(data.getDate());
    var mes = this.fillZeros(data.getMonth()+1);
    var ano = data.getFullYear();

    let _hora = (hora != undefined) ? ((hora.length == 4) ? hora.substr(0,2) + ':' + hora.substr(2,2) : hora) : hora; // se veio sem os ":", coloca.

    let hrs = (_hora != undefined) ? _hora.split(':') : null;
    let hr = (hrs != undefined) ? hrs[0] : '00';
    let minuto = (hrs != undefined) ? hrs[1] : '00';

    return moment.tz(ano + "-" + mes + "-" + dia + " " + hr + ":" + minuto, "America/Bahia").toDate();
  }

  private fillZeros(n: any): string {
    return ('00'+n).slice(-2);
  }

}
