import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MascaraModule } from "app/shared/mascara/mascara.module";
import { RegistrarComponent } from "app/public/registrar/registrar.component";
import { SharedModule } from "app/shared/shared.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { RegistrarRoutingModule } from './registrar.routing.module';

@NgModule({
  imports: [
    CommonModule,
    MascaraModule,
    SharedModule,
    FormsModule,
    ModalModule.forRoot(),
    RegistrarRoutingModule
  ],
  exports: [ RegistrarComponent ],
  declarations: [ RegistrarComponent ],
  providers: [],
})
export class RegistrarModule { }
