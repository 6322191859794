import { Component, ViewChild, QueryList, Input, Output, EventEmitter } from '@angular/core';

import { DoctoFatura } from '../../../_models/doctoFatura';
import { DoctoFaturaArquivo } from "app/_models/doctoFaturaArquivo";
import { ItemDocumentacaoComponent } from "app/cliente/documentacao/lista-documentacao/item-documentacao/item-documentacao.component";
import { PaginacaoComponent } from "app/shared/paginacao/paginacao.component";

@Component({
  selector: 'lista-documentacao',
  templateUrl: './lista-documentacao.component.html',
  styleUrls: ['./lista-documentacao.component.scss']
})
export class ListaDocumentacaoComponent {

  listaDoctoFatura: DoctoFatura[];

  @Output() enviarArquivoEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() enviarTodosEmail: EventEmitter<DoctoFatura> = new EventEmitter<DoctoFatura>();
  @Output() downloadArquivo: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadTodosArquivos: EventEmitter<DoctoFatura> = new EventEmitter<DoctoFatura>();
  @Output() clickBuscarArquivos: EventEmitter<DoctoFatura> = new EventEmitter<DoctoFatura>();

  @ViewChild(ItemDocumentacaoComponent, {static: true}) itemDocto: QueryList<ItemDocumentacaoComponent>;
  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;

  clickEnviarArquivoEmail(doctoFatura: DoctoFatura, arquivo: DoctoFaturaArquivo) {
    this.enviarArquivoEmail.emit(this.obterRetornoArquivo(doctoFatura, arquivo));
  }

  clickEnviarTodosEmail(doctoFatura: DoctoFatura) {
    this.enviarTodosEmail.emit(doctoFatura);
  }

  clickDownloadArquivo(doctoFatura: DoctoFatura, arquivo: DoctoFaturaArquivo) {
    this.downloadArquivo.emit(this.obterRetornoArquivo(doctoFatura, arquivo));
  }

  clickDownloadTodosArquivos(doctoFatura: DoctoFatura) {
    this.downloadTodosArquivos.emit(doctoFatura);
  }

  buscarArquivos(doctoFatura: DoctoFatura) {
    this.clickBuscarArquivos.emit(doctoFatura);
  }

  selecionarTodos() {
    this.listaDoctoFatura.forEach(doctoFatura => {
      if (doctoFatura.selecionado && typeof doctoFatura.selecionado === 'boolean') {
        doctoFatura.selecionado = !doctoFatura.selecionado;
      }
      else {
        doctoFatura.selecionado = true;
      }
    });
  }

  alteradoPageSize(pageSize: number) {
    this.paginacao.setPageSize(pageSize);
  }

  alteradaOrdenacao(ordenacao: string) {
    //TODO: implementar a ordenação
  }

  obterRetornoArquivo(doctoFatura: DoctoFatura, arquivo: DoctoFaturaArquivo): any {
    var retorno =
      {
        doctoFatura: doctoFatura,
        arquivo: arquivo
      };

    return retorno;
  }

  carregaListaDocumentacao(listaDocumentacao: DoctoFatura[]) {
    this.listaDoctoFatura = listaDocumentacao;
    this.paginacao.setAllData(this.listaDoctoFatura);
    this.paginacao.setPageSize(10);
    this.paginacao.setPage(1);
  }

}
