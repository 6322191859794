import { Endereco } from "app/_models/endereco";

export class Cliente
{
    cnpj: string;
    inscricaoEstadual: string;
    nome: string;
    nomeFantasia: string;
    contato: string;
    email: string;
    ddd: string;
    fone: string;

    enderecos: Endereco[];
}