import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HomeRoutingModule } from './home.routing.module';


@NgModule({
    imports: [
        CommonModule,
        CarouselModule.forRoot(),
        HomeRoutingModule
    ],
    exports: [],
    declarations: [ HomeComponent ],
    providers: [],
})
export class HomeModule { 
}