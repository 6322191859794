import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CarouselConfig, CarouselComponent } from 'ngx-bootstrap/carousel';
import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsTela } from 'app/_enums/analytics-tela';
import { AnalyticsDownload } from '../../_enums/analytics-download';

declare var carregaControleMenu: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [{ provide: CarouselConfig, useValue: { interval: 4000, noPause: true } }]
})
export class HomeComponent implements OnInit, AfterViewInit {

  modal: boolean = false;
  path: string = 'home';

  @ViewChild('carousel', {static: true}) carousel: CarouselComponent;


  constructor(private _analyticsService: AnalyticsService) { }

  ngAfterViewInit(): void {
  }

  nextSlide(){
    this.carousel.activeSlide = 0;
    this.carousel.interval = 6000;
  }

  setSlide(val) {
    this.carousel.interval = 6000;
  }

  ngOnInit() {
    carregaControleMenu();
    this._analyticsService.RegistrarAcesso(AnalyticsTela.Home);
  }

  downloadManual(){
    this._analyticsService.RegistrarDownload(AnalyticsTela.Home, AnalyticsDownload.Manual);
  }

  acessoLink(numeroLink: number){
    this._analyticsService.RegistrarAcessoLinkHome(numeroLink);
  }
}
