import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';

import { AppService } from 'app/_services/app.service';
import { OrdemServico } from 'app/_models/ordemServico';
//import { SharedService } from './../../../_services/shared.service';
import { GroupbyService } from 'app/_services/groupby.service';
import { OrdemServicoService } from './../../../_services/ordem-servico.service';

import { CtmNf } from './../../../_models/ctmNf';
import { StatusOS } from 'app/_enums/status-os.enum';
import { BaseComponent } from 'app/shared/base.component';
import { OrdemServicoRequest } from 'app/_models/ordemServicoRequest';
import { ItemDetalhesProvedorComponent } from './item-detalhes-provedor/item-detalhes-provedor.component';

@Component({
  selector: 'item-provedor',
  templateUrl: './item-provedor.component.html',
  styleUrls: ['./item-provedor.component.scss']
})

export class ItemProvedorComponent extends BaseComponent implements OnInit {
  @ViewChild(ItemDetalhesProvedorComponent, {static: true}) itemDetalhesProvedor: ItemDetalhesProvedorComponent;

  @Input() index: number;
  @Input() ordemServico: OrdemServico;
  @Input() exibirSelecao: boolean;
  @Input() exibirNumOs: boolean;
  @Input() exibirFatura: boolean;
  @Input() exibirTipoServico: boolean;
  @Input() exibirDataProgramacao: boolean;
  @Input() exibirEmbarque: boolean;
  @Input() exibirNavio: boolean;
  @Input() exibirContainer: boolean;
  @Input() exibirAcoes: boolean;
  @Input() exibirTracking: boolean;
  @Input() exibirMunicipio: boolean;

  @Output() emitirSelecionarTodasOSs: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() emitirVincularNF: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() emitirSalvarOSs: EventEmitter<OrdemServico> = new EventEmitter();
  @Output() emitirFecharOS: EventEmitter<OrdemServico> = new EventEmitter();

  loadingCtmAverbacao: boolean = false;
  listaCtmAverbacao: CtmNf[];
  primeirosTresCtmAverbacao: CtmNf[];

  constructor(
    private _ordemServicoService: OrdemServicoService,
    //private _sharedService: SharedService,
    private _appService: AppService,
    private _groupBy: GroupbyService) {
    super();
  }

  ngOnInit() {
    this.exibirSelecao = this.ordemServico.descTipoServico !== "Transporte Rodoviário" && this.ordemServico.statusOs.toLowerCase() === "aberta";
    this.defineStatus();
  }

  selecionarTodasOSs() {
    this.emitirSelecionarTodasOSs.emit();
  }

  salvarOSs() {
    this.emitirSalvarOSs.emit();
  }

  vincularNF(OS: OrdemServico) {
    this.emitirVincularNF.emit(OS);
  }

  fecharOrdemServico(OS: OrdemServico) {
    this.emitirFecharOS.emit(OS);
  }

  _status: string = '';
  _tituloBotaoAcoes: string = '';
  _acao: string = '';
  _classeLinha: string = '';
  _classeFundoLinha: string = '';

  //	exibeCheckbox() {
  //		return this.exibirSelecao && (this.ordemServico.descTipoServico.toLowerCase() == 'transporte rodoviário') &&
  //		       (this.ordemServico.statusPortal.toLowerCase() == 'aberta');
  //	}

  public defineStatus() {
    this._acao = '';
    this._tituloBotaoAcoes = '';
    let status: StatusOS;
    let statusPortal: string = this.ordemServico.statusPortal.toLowerCase();

    if (statusPortal == 'ct-e emitido') {
      status = StatusOS.CTeEmitido;
      this._tituloBotaoAcoes = 'Ct-e Aliança Emitido';
      this._classeLinha = 'icon icon-checklist';
      this._classeFundoLinha = 'step-ctes-emitidos';
      this._acao = 'vincular';
    }
    else if (statusPortal == 'pendente') {
      status = StatusOS.Pendente;
      this._tituloBotaoAcoes = '';
      this._classeLinha = 'icon icon-clock';
      this._classeFundoLinha = 'step-pendentes';
      this._acao = '';
    }
    else if (statusPortal == 'a vincular') {
      status = StatusOS.AVincular;
      this._tituloBotaoAcoes = 'Vincular NF';
      this._classeLinha = 'icon icon-page-export';
      this._classeFundoLinha = 'step-a-vincular';
      this._acao = 'vincular';
    }
    else if (statusPortal == 'vinculada') {
      status = StatusOS.Vinculada;
      this._tituloBotaoAcoes = 'Editar NF Vinculada';
      this._classeLinha = 'icon icon-check';
      this._classeFundoLinha = 'step-vinculadas';
      this._acao = 'vincular';
    }
    else if (statusPortal == 'aberta') {
      this._tituloBotaoAcoes = (this.ordemServico.descTipoServico.toLowerCase() == 'transporte rodoviário') ? 'Fechar OS' : '';
      this._classeLinha = 'icon icon-clock';
      this._classeFundoLinha = 'step-a-vincular';
      this._acao = (this.ordemServico.descTipoServico.toLowerCase() == 'transporte rodoviário') ? 'fecharOS' : '';
    }
    else if (statusPortal == 'fechada') {
      this._tituloBotaoAcoes = '';
      this._classeLinha = 'icon icon-check';
      this._classeFundoLinha = 'step-vinculadas';
      this._acao = '';
    }
    else if (statusPortal == 'liberada para pagamento') {
      this._tituloBotaoAcoes = '';
      this._classeLinha = 'icon icon-dollar';
      this._classeFundoLinha = 'step-ctes-emitidos';
      this._acao = '';
    }
		/*
		if ((this.ordemServico.flagPossuiCTe == 'S') || (this.ordemServico.flagPossuiCTM == 'S')) {
			this._status = 'ct-e emitido';
			this._tituloBotaoAcoes = 'Ct-e Aliança Emitido';
			status = StatusOS.CTeEmitido;
		}

		if ((this.ordemServico.flagPossuiNF == 'N') && (this.ordemServico.flagPossuiCTe == 'N') && (this.ordemServico.flagPossuiCTM == 'N')) {

			if ((this.ordemServico.tipo == 'Coleta') &&
			    (
				(!this.ordemServico.numContainer || this.ordemServico.numContainer == null) ||
				(!this.ordemServico.tara || this.ordemServico.tara == null) ||
				((!this.ordemServico.lacre1 || this.ordemServico.lacre1 == null) &&
				(!this.ordemServico.lacre2 || this.ordemServico.lacre2 == null) &&
				(!this.ordemServico.lacre3 || this.ordemServico.lacre3 == null)) ||
				(!this.ordemServico.placa || this.ordemServico.placa == null) ||
				(!this.ordemServico.dtRetiradaTerminal || this.ordemServico.dtRetiradaTerminal == null) ||
		         (!this.ordemServico.dtInicioExecucao || this.ordemServico.dtInicioExecucao == null)) ) {
					this._status = 'Pendente';
					this._tituloBotaoAcoes = '';
					status = StatusOS.Pendente;
			}
			else {
				this._status = 'a vincular';
				this._tituloBotaoAcoes = 'Vincular NF';
				status = StatusOS.AVincular;
			}
		}

		if ((this.ordemServico.flagPossuiNF == 'S') && (this.ordemServico.flagPossuiCTe == 'N') && (this.ordemServico.flagPossuiCTM == 'N')) {
			this._status = 'vinculada';
			this._tituloBotaoAcoes = 'Editar NF Vinculada';
			status = StatusOS.Vinculada;
		}
		*/

    this.ordemServico.status = status;
  }

  exibeBL(): boolean {
    return this.ordemServico.numBL && this.ordemServico.numBL.length > 0;
  }

  exibeDaConsPrincipal(): boolean {
    return this.exibeBL() || (this.primeirosTresCtmAverbacao == undefined)  || (this.primeirosTresCtmAverbacao.length == 0);
  }

  exibeVerMais(): boolean {
    return this.listaCtmAverbacao && this.listaCtmAverbacao.length > 3;
  }

  carregarCtmNfs() {
    this.loadingCtmAverbacao = true;
    var filtro = new OrdemServicoRequest();
    filtro.idOs = this.ordemServico.idOs;
    filtro.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;

    this._ordemServicoService.obtemCtmNfs(filtro).subscribe(result => {
      if (result) {
        this.listaCtmAverbacao = [];
        this.primeirosTresCtmAverbacao = [];

        if (!result) {
          this.loadingCtmAverbacao = false;
          return;
        }

        this.listaCtmAverbacao = this._groupBy.AgruparPorCampo(result, "numCtm");
        this.primeirosTresCtmAverbacao = this.listaCtmAverbacao.slice(0,3);
        /*
        result.forEach(item => {

          let nfe: CtmNf = new CtmNf();
          this._sharedService.copyProperties(item, nfe);
          if (nfe) {
            nfe.idUsuario = filtro.idUsuario;
            this.listaCtmAverbacao.push(nfe);
            //if (this.primeirosTresCtmAverbacao.length < 3)
            //  this.primeirosTresCtmAverbacao.push(nfe);
          }

        });
        */
        this.loadingCtmAverbacao = false;
      }
    }, (errors) => {
      if (errors && errors.length > 0) {
        let mensagemAgrupada;
        errors.forEach(element => {
          mensagemAgrupada += element + '\n';
        });
        //this.exibirMensagem(mensagemAgrupada);
      }
      this.loadingCtmAverbacao = false;
    });

  }

}
