
import {catchError, map} from 'rxjs/operators';


import { Response } from '@angular/http';

import { BaseService } from './base.service';
import { Cotacao } from './../_models/cotacao';
import { CotacaoRequest } from "app/_models/cotacao-request";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ValidationResult } from './../_models/validation-result';
import { MinhasCotacoes } from "app/_models/minhas-cotacoes";
import { MessageService } from './message.service';
import { CotacaoDownload } from 'app/_models/cotacao-download';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { Operacao } from '../_enums/operacao';

@Injectable({
  providedIn: 'root'
})
export class CotacaoService {

  constructor(private baseService: BaseService,
              private _msgService: MessageService) { }

  getCotacoes(consulta: CotacaoRequest): Observable<MinhasCotacoes> {
    return this.baseService.Post('cotacao', consulta).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as MinhasCotacoes;
        else {
          this.ProcessaErros(retorno, "Erro ao consultar Cotações! Contate o administrador!", LogFrontFuncionalidade.Cotacao, Operacao.Consulta, consulta);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getCotacao(consulta: CotacaoRequest): Observable<Cotacao> {

    return this.baseService.Post('cotacao/detalhe', consulta).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Cotacao;
        else {
          this.ProcessaErros(retorno, "Erro ao consultar Cotações! Contate o administrador!", LogFrontFuncionalidade.Cotacao, Operacao.Consulta, consulta);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getCotacoesDownload(consulta: CotacaoRequest): Observable<CotacaoDownload[]> {
    return this.baseService.Post('cotacao/csv', consulta).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as CotacaoDownload[];
        else {
          this.ProcessaErros(retorno, "Erro ao consultar Cotações - csv! Contate o administrador!", LogFrontFuncionalidade.Cotacao, Operacao.Consulta, consulta);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  Solicitar(cotacao: Cotacao): Observable<Cotacao> {
    return this.baseService.Post('cotacao/solicitar', cotacao).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Cotacao;
        else {
          this.ProcessaErros(retorno, "Erro ao Solicitar Cotação! Contate o administrador!", LogFrontFuncionalidade.Cotacao, Operacao.Inclusao, cotacao);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  UploadDocumentos(arquivos): Observable<boolean> {
    let formData: FormData = new FormData();
    for (var j = 0; j < arquivos.length; j++) {
        formData.append("file[]", arquivos[j], arquivos[j].name);
    }

    return this.baseService.Post('cotacao/upload', formData).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;
        if (!retorno.IsValid)
          this.ProcessaErros(retorno, "Erro ao realizar upload dos documentos! Contate o administrador!", LogFrontFuncionalidade.Cotacao, Operacao.Upload, arquivos);
        return retorno.IsValid;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  AlterarCotacao(cotacao: Cotacao): Observable<Cotacao> {
    return this.baseService.Put('cotacao/solicitar', cotacao).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Cotacao;
        else{
          this.ProcessaErros(retorno, "Erro ao Alterar a Cotação! Contate o administrador!", LogFrontFuncionalidade.Cotacao, Operacao.Alteracao, cotacao);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  private ProcessaErros(retorno: ValidationResult, mensagem: string, func: LogFrontFuncionalidade = LogFrontFuncionalidade.Invalida,
           operacao: Operacao = Operacao.Invalida, dados: any) {
    var erros: string[] = [];
    erros.push(mensagem);
    retorno.Erros.forEach(element => {
      erros.push(element.ErrorCode + " - " + element.Message);
    });
    this._msgService.addMessageArray('Cotação', erros, "error", func, operacao, dados);
  }

  exportarRelatorioCSV(cotacoes: CotacaoDownload[]) {
    if (!cotacoes || cotacoes.length === 0) {
      return;
    };

    const data: any[] = [];
    const titulos: any[] = ['Número da Proposta comercial',
                            'Razão Social Cliente',
                            'CNPJ Cliente',
                            'Operação Comercial',
                            'Modalidade',
                            'Município Origem',
                            'UF Município Origem',
                            'Porto Origem',
                            'Porto Destino',
                            'Município Destino',
                            'UF Município Destino',
                            'Contêiner (Categoria – Tamanho e tipo)',
                            'Produto',
                            'Flag carga com excesso',
                            'Flag Carga Perigosa',
                            'Flag Carga refrigerada',
                            'Responsável pela estufagem – Operação Origem',
                            'Responsável pela estufagem – Operação Destino',
                            'Serviços Adicionais (separados por vírgula)',
                            'Seguro',
                            'AD Valorem',
                            'Valor do Frete'];
    cotacoes.forEach(x => {
      data.push([
        x.numProposta,
        x.nomeCliente,
        x.cnpjCli,
        x.tipoOperacao,
        x.modal,
        x.municOri,
        x.ufMunicOri,
        x.portoOri,
        x.portoDes,
        x.municDes,
        x.ufMunicDes,
        x.categoriaContainer + ' - ' + x.tamanhoContainer + ' - ' + x.tipoContainer,
        x.familiaProduto,
        x.flagCargaOver,
        x.flagCargaPerigosa,
        x.flagCargaRefrigerada,
        x.responsavelOva,
        x.responsavelDesova,
        x.servicosAdicionais,
        x.seguro,
        x.percAdvalorem,
        x.ValFrete
      ]);
    });

    this.baseService.DownloadCSV(data, titulos, 'Cotacoes.csv');
  }
}
