import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { ConfigService } from './config.service';
import { CustomerInfoService } from './customer-info.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Booking } from '../_models/booking';

@Injectable({
    providedIn: 'root'
})
// Service to handle redirections to PCAB 3.0
export class RedirectService {

    constructor(
        private _configService: ConfigService,
        private _customerInfoService: CustomerInfoService
    ) { }

    // Open a modal and redirect to PCAB 3.0 "Booking Online" New page
    public redirectWithModalToBookingOnlineNew(): void {       
        this.showRedirectModal(() => {
            this.redirectToBookingOnlineNew();
        });
    }

    // Open a modal and redirect to PCAB 3.0 "Booking Online" Copy page
    public redirectWithModalToBookingOnlineCopy(bookingNumber: string): void {
        this.showRedirectModal(() => {
            this.redirectToBookingOnlineCopy(bookingNumber);
        });
    }

    // PO-PO Bookings with Credit Payment must be created on PCAB 3.0
    private showRedirectModal(onCloseCallback: () => void): void {
        // show a 5s modal that redirects to PCAB 3.0 on close
        let timerInterval: NodeJS.Timer;
        let countdown = 5;
        swal({
            title: 'Estamos te Redirecionando',
            html: `A criação de reservas porto-a-porto deve ser feita através do novo Booking Online.<br>Redirecionando em <b>${countdown}</b> segundos.`,
            type: 'warning',
            showConfirmButton: false,
            timer: countdown * 1000,
            onOpen: () => {
                swal.showLoading();
                timerInterval = setInterval(() => {
                    countdown--;
                    const content = swal.getContent();
                    if (content) {
                        const b = content.querySelector('b');
                        if (b) {
                            b.textContent = countdown.toString();
                        }
                    }
                }, 1000);
            },
            onClose: () => {
                clearInterval(timerInterval);
                onCloseCallback();
            },
        }).catch(swal.noop);
    }

    // Redirect to PCAB 3.0 "Booking Online" New page
    private redirectToBookingOnlineNew() {        
        // After my redirect, if user press the back button, I want to get back to home
        //window.history.pushState({}, '', '/home');

        //https://cdt.alianca.com.br/ecommerce/reservaonline/nova/
        const baseUrl = this._configService._config.redirectPcabV3Url.replace(/\/$/, '');
        const redirectUrl = `${baseUrl}/nova/`;
        console.log('redirecting to: ', redirectUrl);
        window.location.href = redirectUrl;
    }

    // Redirect to PCAB 3.0 "Booking Online" Copy page
    private redirectToBookingOnlineCopy(bookingNumber: string) {
        // After my redirect, if user press the back button, I want to get back to home
        //window.history.pushState({}, '', '/home');

        //https://cdt.alianca.com.br/ecommerce/reservaonline/copy?bookingNumber=<BOOKING_NUMBER> 
        const baseUrl = this._configService._config.redirectPcabV3Url.replace(/\/$/, '');
        const redirectUrl = `${baseUrl}/copy?bookingNumber=${bookingNumber}`;
        console.log('redirecting to: ', redirectUrl);
        window.location.href = redirectUrl;
    }

    // Return true if booking is Porto-Porto (PO-PO)
    public isPortoPorto(booking: Booking): boolean {
        console.log('modal: ', booking.modal);   
        if(!booking || !booking.modal) {
            this.showRedirectErrorModalidade();
            throwError('Booking is null or undefined');
        }
        return booking.modal.toUpperCase() === 'PO/PO';
    }

    // Return true if payment type is credit
    public isCreditPayment$(cnpjCli: string): Observable<boolean> {
        return this._customerInfoService.getCustomerInfo(cnpjCli).pipe(
            map(customerInfo => {
                console.log('Customer info fetched successfully to check if payment is credit:', customerInfo);
                const isCredit = !customerInfo || Object.keys(customerInfo).length === 0;
                console.log('isCredit: ', isCredit);
                return isCredit;
            }),
            catchError((error) => {
                console.error('Error fetching customer info to check if pasyment is credit:', error);
                this.showRedirectErrorCredit();
                // Rethrow the error to propagate it
                return throwError(error);
            })
        );
    }

    // Open a modal to show an error message when redirecting to PCAB 3.0
    private showRedirectErrorModalidade(): void {
        swal({
            title: 'Erro',
            text: 'Ocorreu um erro ao validar a modalidade do booking. Tente novamente ou entre em contato com o suporte.',
            type: 'error',
            confirmButtonText: 'OK'
        });
    }

    // Open a modal to show an error message when redirecting to PCAB 3.0
    private showRedirectErrorCredit(): void {
        swal({
            title: 'Erro',
            text: 'Ocorreu um erro ao buscar os detalhes do booking. Tente novamente ou entre em contato com o suporte.',
            type: 'error',
            confirmButtonText: 'OK'
        });
    }
    
    // Return true if redirect to PCAB 3.0 is enabled
    public isRedirectEnabled(): boolean {
        return this._configService._config.enableRedirectPcabV3 || false;
    }

}