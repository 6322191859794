export class SubTributaria {
  id: number;
  departureUf: string;
  destinationUf: string;
  modal: string;
  groupEconomy: string;
  diferencyICMS: number;
  createdBy: string;
  createdOn: Date;
  updatedOn: Date;
  updatedBy: string;
  isEnabled: boolean;
}
