import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from "@angular/forms";

import { BaseComponent } from './../../shared/base.component';
import { MessageComponent } from './../../shared/message/message.component';

import { Municipio } from './../../_models/municipio';
import { PerfilUsuario } from "app/_enums/perfil-usuario.enum";
import { UsuarioRegistrar } from './../../_models/usuarioregistrar';
import { ValidationResult } from './../../_models/validation-result';

import { BaseService } from './../../_services/base.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from './../../_services/message.service';

import swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
declare var ocultarControleMenu: any;
declare var ocultarSwalOverlay: any;
declare var removeSwal: any;

@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.scss']
})
export class RegistrarComponent extends BaseComponent implements OnInit {

  public usuario: UsuarioRegistrar = new UsuarioRegistrar();
  public loading = false;
  public loadingMunicipios = false;
  public aceitouTermo = false;
  municipio: Municipio;
  municipios: Municipio[] = [];

  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;

  constructor(private msgService: MessageService, private baseService: BaseService,
    private _router: Router,
    private _sharedService: SharedService,
    private _sanitizer: DomSanitizer,
    private _changeDetect: ChangeDetectorRef) {

    super();

    this.usuario.cliente = false;
    this.usuario.provedor = false;

    this.msgService.emitirMensagem.subscribe(
      msg => {
        if (msg.mensagem instanceof Array)
          this.mensagem.mostrarMensagemArray(msg.tipo, msg.mensagem);
        else
          this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  autocompleListFormatter = (data: any) => {
    let html = `<span>${data.Descricao}/${data.UF}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  ngOnInit() {
    ocultarControleMenu();
    this.ObterMunicipios();
  }

  public ObterMunicipios() {
    this.loadingMunicipios = true;
    this._sharedService.getMunicipios()
      .subscribe(m => {
        this.municipios = m;

        this.loadingMunicipios = false;
      });
  }

  public Enviar(form: FormGroup) {
    this.msgService.clearMessage();

    if (!this.validaFormulario())
      return;

    this.usuario.dataAceiteTermo = new Date();
    this.usuario.perfilUsuario = (this.usuario.cliente && this.usuario.provedor) ? PerfilUsuario.Ambos :
                                        (this.usuario.cliente ? PerfilUsuario.Cliente : PerfilUsuario.Provedor);

    this.loading = true;

    this.baseService.Post('usuario/registrar', this.usuario).subscribe(result => {
      this.loading = false;
      let retorno: ValidationResult = result as ValidationResult;
      if (retorno.IsValid) {
        removeSwal();
        swal({
          title: 'Sucesso',
          text: retorno.Message,
          type: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: 'OK'
        }).then(success => {
          this._router.navigate(['/home']);
          ocultarControleMenu();
          ocultarSwalOverlay();
        }, dismiss => {
          removeSwal();
        });
      }
      else {
        this.loading = false;
        retorno.Erros.forEach(element => this.msgService.addMessage('Registro Usuário', element.Message, "error"));
      }
    },
    err => {
      this.loading = false;
      this.msgService.addMessage('Registro Usuário', err, "error");
    });
  }

  validaFormulario(): boolean {
    this._changeDetect.detectChanges();

    let msgErro: string[] = [];

    //cliente e/ou provedor
    if (!this.usuario.cliente && !this.usuario.provedor)
      msgErro.push('Informe se é Cliente, Provedor ou ambos');

    //Nome
    if (!this.usuario.nome || this.usuario.nome == "" || this.usuario.nome.trim() == "")
      msgErro.push('Nome é obrigatório');

    //Email
    if (!this.usuario.email || this.usuario.email == "" || this.usuario.email.trim() == "")
      msgErro.push('Email é obrigatório');
    else
      this.usuario.email = this.usuario.email.toLowerCase();

    //Razão Social
    if (!this.usuario.razaoSocial || this.usuario.razaoSocial == "" || this.usuario.razaoSocial.trim() == "")
      msgErro.push('Razão Social é obrigatória');

    //Nome Fantasia
    if (!this.usuario.nomeFantasia || this.usuario.nomeFantasia == "" || this.usuario.nomeFantasia.trim() == "")
      msgErro.push('Nome Fantasia é obrigatório');

    //CNPJ
    if (!this.usuario.cnpj || this.usuario.cnpj == "" || this.usuario.cnpj.trim() == "")
      msgErro.push('CNPJ é obrigatório');

    //Inscrição Estadual
    if (!this.usuario.inscricaoEstadual || this.usuario.inscricaoEstadual == "" || this.usuario.inscricaoEstadual.trim() == "")
      msgErro.push('Inscrição Estadual é obrigatória');

    //CEP
    if (!this._sharedService.validaCEP(this.usuario.cep))
      msgErro.push('CEP inválido');

    //Logradouro
    if (!this.usuario.logradouro || this.usuario.logradouro == "" || this.usuario.logradouro.trim() == "")
      msgErro.push('Logradouro é obrigatório');

    //Número
    if (!this.usuario.numero || this.usuario.numero == "" || this.usuario.numero.trim() == "")
      msgErro.push('Número é obrigatório');

    //Bairro
    if (!this.usuario.bairro || this.usuario.bairro == "" || this.usuario.bairro.trim() == "")
      msgErro.push('Bairro é obrigatório');

    //Município
    if (this.municipio !== undefined && this.municipio.codigo !== undefined){
      this.usuario.municipio = this.municipio.codigo.toString();
      this.usuario.uf = this.municipio.uf;  
    }
    else {
      msgErro.push('Informe o Município');  
    }

    //Telefone
    if (!this._sharedService.validaTelefone(this.usuario.telefone))
      msgErro.push('Telefone inválido');

    //Política
    if (!this.aceitouTermo)
      msgErro.push('Você precisa aceitar a Política de Privacidade e Segurança para se cadastrar.');

    if (msgErro.length > 0) {
        this.msgService.addMessageArray('Registre-se', msgErro, 'error');
        return false;
    }
    else
      return true;
  }
}
