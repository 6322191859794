
import {catchError, map} from 'rxjs/operators';
import { ValidationResult } from './../_models/validation-result';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';


import { BaseService } from './base.service';
import { Funcionalidade } from './../_models/funcionalidade';


@Injectable({
    providedIn: 'root'
})
export class FuncionalidadeService {

    constructor(private baseService: BaseService) { }

    getFuncionalidades(): Observable<Funcionalidade[]> {
        // get users from api
        return this.baseService.Get('funcionalidade').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Funcionalidade[];
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getFuncionalidade(id: number): Observable<Funcionalidade> {
        // get users from api
        return this.baseService.Get('funcionalidade/' + id).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Funcionalidade;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

}
