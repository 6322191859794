import { Component, OnInit, Input, EventEmitter, ViewChild, Output, ElementRef, Renderer2 } from '@angular/core';

import { Porto } from './../../../../_models/porto';
import { Cliente } from './../../../../_models/cliente';
import { Booking } from 'app/_models/booking';
import { Schedule } from './../../../../_models/schedule';
import { Municipio } from './../../../../_models/municipio';
import { Mercadoria } from './../../../../_models/mercadoria';
import { TipoContainer } from './../../../../_models/tipo-container';
import { ServicoProposta } from 'app/_models/servico-proposta';
import { PropostaComercial } from './../../../../_models/proposta-comercial';
import { TipoOperacaoComercial } from './../../../../_models/tipoOperacaoComercial';
import { PropostaComercialFiltro } from './../../../../_models/proposta-comercial-filtro';
import { PropostaComercialRequest } from './../../../../_models/proposta-comercial-request';

import { AppService } from './../../../../_services/app.service';
import { DataService } from './../../../../_services/data.service';
import { SharedService } from './../../../../_services/shared.service';
import { MessageService } from './../../../../_services/message.service';
import { GroupbyService } from './../../../../_services/groupby.service';
import { GenericOrderbyService } from './../../../../_services/generic-orderby.service';
import { PropostaComercialService } from './../../../../_services/proposta-comercial.service';

import { BaseComponent } from './../../../../shared/base.component';
import { CnpjDropdownComponent } from './../../../../shared/cnpj-dropdown/cnpj-dropdown.component';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { RedirectService } from 'app/_services/redirect.service';

@Component({
  selector: 'step1-selecao-proposta',
  templateUrl: './step1-selecao-proposta.component.html',
  styleUrls: ['./step1-selecao-proposta.component.scss']
})
export class Step1SelecaoPropostaComponent extends BaseComponent implements OnInit {

  @ViewChild(CnpjDropdownComponent, { static: true }) cnpjdropdown: CnpjDropdownComponent;
  @ViewChild('cmbProp', { static: true }) cmbProposta: ElementRef;
  @ViewChild('cmbPortoDestino', { static: true }) cmbPortoDestino: ElementRef;
  @ViewChild('cmbPortoOrigem', { static: true }) cmbPortoOrigem: ElementRef;
  @ViewChild('cmbCidadeOrigem', { static: true }) cmbCidadeOrigem: ElementRef;
  @ViewChild('cmbCidadeDestino', { static: true }) cmbCidadeDestino: ElementRef;
  @ViewChild('cmbProduto', { static: true }) cmbProduto: ElementRef;
  @ViewChild('cmbOperacaoComercial', { static: true }) cmbOperacaoComercial: ElementRef;
  @ViewChild('cmbTipoContainer', { static: true }) cmbTipoContainer: ElementRef;
  @ViewChild('cmbOva', { static: true }) cmbOva: ElementRef;
  @ViewChild('cmbDesova', { static: true }) cmbDesova: ElementRef;
  @ViewChild('cmbEscolta', { static: true }) cmbEscolta: ElementRef;
  @ViewChild('cmbShipper', { static: true }) cmbShipper: ElementRef;
  @ViewChild('cmbCargaOver', { static: true }) cmbCargaOver: ElementRef;
  @ViewChild('cmbCargaPerigosa', { static: true }) cmbCargaPerigosa: ElementRef;
  @ViewChild('cmbConferente', { static: true }) cmbConferente: ElementRef;
  @ViewChild('cmbServicoOrigem', { static: true }) cmbServicoOrigem: ElementRef[];
  @ViewChild('cmbServicoDestino', { static: true }) cmbServicoDestino: ElementRef[];

  @Input() duplicar: boolean;
  @Input() novoBooking: boolean;
  @Input() booking: Booking;
  @Input() progNavios: Schedule;
  @Output() propostaSelecionadaEvent: EventEmitter<PropostaComercial> = new EventEmitter<PropostaComercial>();

  bookingAPartirDeCotacao: boolean = false;
  exibeModal: boolean = false;
  exibeSelecaoProposta: boolean = false;
  loadingTodasPropostas: boolean = false;
  temOrigemPA: boolean = false;
  temOrigemPO: boolean = false;
  temDestinoPA: boolean = false;
  temDestinoPO: boolean = false;
  todasPropostas: PropostaComercial[] = [];
  todosServicosOrigem: ServicoProposta[] = [];
  todosServicosDestino: ServicoProposta[] = [];
  propostasFiltradas: PropostaComercial[] = [];
  propostaFiltro: PropostaComercialFiltro = new PropostaComercialFiltro();
  listaNumPropostas: number[] = [];
  propostaSelecionada: PropostaComercial;

  produtos: Mercadoria[] = [];
  todosTiposOperacao: TipoOperacaoComercial[] = [];
  tiposOperacao: TipoOperacaoComercial[] = [];
  tipoContainers: TipoContainer[] = [];
  tipoDeContainer: TipoContainer[] = [];
  todosTipoContainers: TipoContainer[] = [];

  cidadesOrigem: Municipio[] = [];
  isLoadingCities = false;
  cidadesDestino: Municipio[] = [];
  todosPortos: Porto[] = [];
  portosOrigem: Porto[] = [];
  portosDestino: Porto[] = [];

  optionsServicos = [{ valor: '[INDEFINIDO]', descricao: '' }, { valor: 'S', descricao: 'Sim' }, { valor: 'N', descricao: 'Não' }];

  todasCargaPerigosa: boolean = false;
  todasCargaOver: boolean = false;
  todasShipperOwn: boolean = false;

  qtdeCargaPerigosa: number = 0;
  qtdeCargaOver: number = 0;
  qtdeShipperOwn: number = 0;
  qtdeEmbarcador: number = 0;
  qtdeRecebedor: number = 0;
  qtdeEquipePropriaOri: number = 0;
  qtdeEquipePropriaDes: number = 0;
  qtdeConferente: number = 0;
  qtdeEscolta: number = 0;

  qtdeCargaPerigosaNAO: number = 0;
  qtdeCargaOverNAO: number = 0;
  qtdeShipperOwnNAO: number = 0;
  qtdeEquipePropriaOriNAO: number = 0;
  qtdeEquipePropriaDesNAO: number = 0;
  qtdeConferenteNAO: number = 0;
  qtdeEscoltaNAO: number = 0;

  totalPropostasFiltradas: number = 0;
  disableModal: boolean = false;

  constructor(private _appService: AppService,
    private _msgService: MessageService,
    private _propostaService: PropostaComercialService,
    private _sharedService: SharedService,
    private _dataService: DataService,
    public _groupbyService: GroupbyService,
    private _genericOrderbyService: GenericOrderbyService,
    private _redirectService: RedirectService,
    private _renderer: Renderer2,
    private elRef: ElementRef) {
    super();
  }

  ngOnInit() {
    this.cnpjdropdown.SelecionaMultiplos = false;
    this.cnpjdropdown.modoEdicao = true;
    //this.cnpjdropdown.pesquisaEspecial = true;
    this.cnpjdropdown.filtroCliente.tipoConsulta = 1;
    this.cnpjdropdown.filtroCliente.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    if (this.novoBooking && !this.duplicar) {
      this.ObterTodosTiposOperacao();
      this.ObterPortos();
    }
  }

  clienteNaoEncontrado(msg: string) {
    this._msgService.addMessage('Booking', msg, 'error', LogFrontFuncionalidade.Booking, Operacao.Consulta);
  }

  clienteSelecionado(cliente: Cliente) {
    this.booking.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    if (cliente == null) {
      if (!this.cnpjdropdown.MaisDe1Cnpj && !this._appService.isAdmin()) {
        this.loadingTodasPropostas = false;
        this.exibeModal = false;
        this._msgService.addMessage('Booking', 'Não foram encontradas propostas para esse cliente. Solicite uma cotação.', 'error',
          LogFrontFuncionalidade.Booking, Operacao.Consulta, cliente);
        return;
      }
      else {
        //this.booking = new Booking();
        this.exibeSelecaoProposta = false;
        this.exibeModal = false;
        return;
      }
    }

    this.booking.cnpjCli = cliente.cnpj;
    this.booking.ieCli = cliente.inscricaoEstadual;
    this.exibeSelecaoProposta = false;
    if (this.novoBooking && !this.duplicar && !this.bookingAPartirDeCotacao) {
      this.booking.modal = '';
      this.booking.numProposta = '';
      this.booking.idTrecho = 0;
      this.booking.qtdeContainer = 0;
      this.booking.dddEmb = '';
      this.booking.foneEmb = '';
      this.propostaFiltro = new PropostaComercialFiltro();
      this.propostaSelecionada = undefined;
      this.propostaSelecionadaEvent.emit(this.propostaSelecionada);
    }

    // Busca todas as propostas do cliente
    this.BuscaTodasPropostasCliente();
  }

  configuraPropostaSelecionada(proposta: PropostaComercial) {
    this.cnpjdropdown.modoBloqueado = true;
    this.propostaSelecionada = new PropostaComercial();
    this._sharedService.copyProperties(proposta, this.propostaSelecionada);
    this.propostaSelecionada.servicosDaProposta = proposta.servicosDaProposta;
  }

  SelecionouModal() {
    // PO-PO Bookings with Credit Payment must be created on PCAB 3.0
    // others continue in 2.0
    // continueSelecionouModal() is called to ensure the assyncronous nature of isCreditPayment is handled correctly
    try {
      // feature switch, only redirect to PCAB 3.0 if enabled
      if(!this._redirectService.isRedirectEnabled()) {     
        this.continueSelecionouModal();
        return;
      }      

      if (this._redirectService.isPortoPorto(this.booking)) { 
        // if PO-PO disable the button until I check if it is a credit payment
        this.disableModalDestinoFor10Seconds();             
        this._redirectService.isCreditPayment$(this.booking.cnpjCli).subscribe(isCredit => {
          if(isCredit) {
            console.log('selecionou modal PO/PO');
            console.log('redirecionando para PCAB 3.0');
            console.log('cnpjCli', this.booking.cnpjCli);
            this.exibeSelecaoProposta = false;
            this._redirectService.redirectWithModalToBookingOnlineNew();
            return;            
          }
          else {            
            this.disableModal = false;
            this.continueSelecionouModal()
          }
        });  
      }
      else {
        this.continueSelecionouModal();
      }
    } catch (error) { 
      this.disableModal = false;
      console.error('Error on SelecionouModal', error);
      this._msgService.addMessage('Booking', 'Erro ao selecionar modalidade.', 'error', LogFrontFuncionalidade.Booking, Operacao.Consulta);     
    } 
  }
 
  // Disable the Destino modal button for 10 seconds
  private disableModalDestinoFor10Seconds(): void {
    this.disableModal = true; 
    setTimeout(() => {
      this.disableModal = false;
    }, 10 * 1000);
  }
  
  // continue in 2.0
  private continueSelecionouModal() {

    if (!this.novoBooking || this.duplicar || this.bookingAPartirDeCotacao) {
      //emitir evento para habilitar botões de controle step     
      this.propostaSelecionadaEvent.emit(this.propostaSelecionada);
      return;
    }

    this.limpaServicosAdicionais(this.todosServicosOrigem);
    this.limpaServicosAdicionais(this.todosServicosDestino);
    this.exibeSelecaoProposta = ((this.booking.oriPorto != undefined) && (this.booking.desPorto != undefined));

    if ((this.booking.oriPorto == undefined) || (this.booking.desPorto == undefined))
      return;

    this.propostaFiltro = new PropostaComercialFiltro();
    this.propostaFiltro.modal = this.booking.modal;
    this.propostaSelecionada = undefined;

    this.propostaSelecionadaEvent.emit(this.propostaSelecionada);

    this.propostasFiltradas = this.todasPropostas.filter(x => (x.modal == this.propostaFiltro.modal));
    this.todosServicosOrigem = [];
    this.todosServicosDestino = [];

    this.propostasFiltradas.forEach(p => {
      if (p.servicosDaProposta != null) {
        p.servicosDaProposta.forEach(x => {
          if (x.indicOriDes == 'O' && this.todosServicosOrigem.filter(y => y.idTipoServico == x.idTipoServico).length == 0)
            this.todosServicosOrigem.push(x);
          if (x.indicOriDes == 'D' && this.todosServicosDestino.filter(y => y.idTipoServico == x.idTipoServico).length == 0)
            this.todosServicosDestino.push(x);
        });
      }
    });

    this.FiltraPropostas();
  }

  limpaServicosAdicionais(servicos: ServicoProposta[]) {
    if (servicos != null)
      servicos.forEach(servico => {
        servico.valorSelecionado = '[INDEFINIDO]';
      });
  }

  BuscaTodasPropostasCliente() {
    this.loadingTodasPropostas = true;

    // Filtro da requisição
    const filtro: PropostaComercialRequest = new PropostaComercialRequest();
    filtro.idUsuario = this.booking.idUsuario;
    filtro.cnpjCli = this.booking.cnpjCli;
    filtro.ieCli = this.booking.ieCli;
    filtro.idTrecho = this.booking.idTrecho;
    filtro.flag_ativa = (!this.novoBooking && !this.duplicar) ? 'N' : 'S';
    filtro.numProposta = (this.booking.numProposta != '') ? Number(this.booking.numProposta) : 0;

    if (this.progNavios) {
      filtro.idPortolOrigem = this.progNavios.idPortoOrigem;
      filtro.idPortoDestino = this.progNavios.idPortoDestino;
    }

    // Obtem propostas comerciais
    this._propostaService.getPropostas(filtro)
      .subscribe(p => {

        //console.log(p);
        if (!p)
          return;
        this.todasPropostas = [];
        this.propostasFiltradas = [];

        if (p instanceof Array) { //retornou lista de propostas
          this.todasPropostas = p;
          this.propostasFiltradas = p;
        }
        else {
          this.todasPropostas.push(p);
          this.propostasFiltradas.push(p);
          this.propostaSelecionada = p;
        }

        if (this.todasPropostas == null || this.todasPropostas.length == 0) {
          this.loadingTodasPropostas = false;
          this.exibeModal = false;
          this._msgService.addMessage('Booking', 'Não foram encontradas propostas para esse cliente. Solicite uma cotação.', 'error',
            LogFrontFuncionalidade.Booking, Operacao.Consulta, filtro);
          return;
        }

        this.todosServicosOrigem = [];
        this.todosServicosDestino = [];

        this.temDestinoPA = (this.todasPropostas.filter(x => x.modal.endsWith('PA')).length > 0);
        this.temDestinoPO = (this.todasPropostas.filter(x => x.modal.endsWith('PO')).length > 0);
        this.temOrigemPA = (this.todasPropostas.filter(x => x.modal.startsWith('PA')).length > 0);
        this.temOrigemPO = (this.todasPropostas.filter(x => x.modal.startsWith('PO')).length > 0);

        this.todasPropostas.forEach(p => {
          // Popula todos os tipos de contêiners pois o id é composto (get/set)
          let tp: TipoContainer = new TipoContainer();
          
          this._sharedService.copyProperties(p.tipoDeContainer, tp);
          if (tp != null && tp.codigo != null)
            p.tipoDeContainer.idContainer = tp.idContainer;
          else {
            tp.codigo = null;
            tp.desc_resumida = '';
            tp.descricao = '(Vazio)';
            tp.tamanho = null;
          }

          var x = this.todosTipoContainers.find(x => (x.codigo == tp.codigo && x.tamanho == tp.tamanho) || (x.codigo == tp.codigo && x.codigo == null));
          if (x == null)
            this.todosTipoContainers.push(tp);
        });

        if (((this.temDestinoPA && !this.temDestinoPO) || (!this.temDestinoPA && this.temDestinoPO)) &&
          ((this.temOrigemPA && !this.temOrigemPO) || (!this.temOrigemPA && this.temOrigemPO))) {
          this.booking.modal = ((this.temOrigemPA && !this.temOrigemPO) ? 'PA' : 'PO') + '/' + ((this.temDestinoPA && !this.temDestinoPO) ? 'PA' : 'PO');
          this.SelecionouModal();
        }
        else {
          if ((this.temOrigemPA && !this.temOrigemPO) || (!this.temOrigemPA && this.temOrigemPO))
            this.booking.modal = ((this.temOrigemPA && !this.temOrigemPO) ? 'PA' : 'PO') + '/';

          if ((this.temDestinoPA && !this.temDestinoPO) || (!this.temDestinoPA && this.temDestinoPO))
            this.booking.modal = '/' + ((this.temDestinoPA && !this.temDestinoPO) ? 'PA' : 'PO');

          this.booking.modal = this.booking.modal.replace("//", "/");
        }

        this.exibeModal = true;
        this.loadingTodasPropostas = false;

        // se já está com a tela montada (ou seja, está trocando o cliente)
        if (this.exibeSelecaoProposta)
          this.FiltraPropostas();
      }, (err) => console.log(err)
        , () => {
          // finally
        });
  }

  FiltraPropostas() {
    this.limparBordas([this.cmbProposta, this.cmbPortoDestino, this.cmbPortoOrigem, this.cmbCidadeOrigem, this.cmbCidadeDestino, this.cmbOva, this.cmbDesova, this.cmbEscolta,
    this.cmbShipper, this.cmbCargaOver, this.cmbCargaPerigosa, this.cmbConferente, this.cmbProduto, this.cmbOperacaoComercial, this.cmbTipoContainer]);
    this.propostasFiltradas = this.todasPropostas.filter(x => (x.modal == this.propostaFiltro.modal) &&
      this.filtroNumero(x.numProposta, this.propostaFiltro.numProposta) &&
      this.filtroNumero(x.idMunicOrigem, this.propostaFiltro.idMunicOrigem) &&
      this.filtroNumero(x.idMunicDestino, this.propostaFiltro.idMunicDestino) &&
      this.filtroNumero(x.idPortolOrigem, this.propostaFiltro.idPortolOrigem) &&
      this.filtroNumero(x.idPortoDestino, this.propostaFiltro.idPortoDestino) &&
      this.filtroNumero(x.idFamiliaProduto, this.propostaFiltro.idFamiliaProduto) &&
      this.filtroNumero(x.idCategoriaContainer, this.propostaFiltro.idCategoriaContainer) &&
      this.filtroNumero(x.tamanhoContainer, this.propostaFiltro.tamanhoContainer) &&
      this.filtroFlag(x.flagShipperOwnContainer, this.propostaFiltro.flagShipperOwnContainer) &&
      this.filtroFlag(x.flagCargaOver, this.propostaFiltro.flagCargaOver) &&
      this.filtroFlag(x.flagCargaPerigosa, this.propostaFiltro.flagCargaPerigosa) &&
      this.filtroFlag(x.flagEquipePropriaOri, this.propostaFiltro.flagOva) &&
      this.filtroFlag(x.flagEquipePropriaDes, this.propostaFiltro.flagDesova) &&
      (this.filtroFlag(x.flagConferenteOri, this.propostaFiltro.flagConferente) || this.filtroFlag(x.flagConferenteDes, this.propostaFiltro.flagConferente)) &&
      (this.filtroFlag(x.flagEscoltaOri, this.propostaFiltro.flagEscolta) || this.filtroFlag(x.flagEscoltaDes, this.propostaFiltro.flagEscolta)) &&
      this.filtroString(x.tipoOperacaoComercial, this.propostaFiltro.tipoOperacaoComercial) &&
      this.filtroTipoContainer(x.tipoDeContainer.idContainer, this.propostaFiltro.tipoDeContainer.idContainer) &&
      this.filtrarServicoProposta(x.listaTipoServicoOri, x.listaTipoServicoDes)
    );

    if (this.propostasFiltradas.length == 0) {
      this.exibeSelecaoProposta = false;
      this._msgService.addMessage('Booking', 'Não foram encontradas propostas para esse cliente e modalidade. Troque a modalidade ou Solicite uma cotação.', 'warning',
        LogFrontFuncionalidade.Booking, Operacao.Consulta, this.propostaFiltro);
      return;
    }

    this._msgService.clearMessage();

    // Popula combos
    this.obterCidadesOrigem();
    this.obterCidadesDestino();
    this.obterPortosOrigem();
    this.obterPortosDestino();
    this.obterProdutos();
    this.obterTiposOperacao();
    this.obterTiposContainers();

    this.totalPropostasFiltradas = this.propostasFiltradas.length;

    this.qtdeCargaPerigosa = (this.propostasFiltradas.filter(p => p.flagCargaPerigosa == 'S').length);
    this.qtdeCargaOver = (this.propostasFiltradas.filter(p => p.flagCargaOver == 'S').length);
    this.qtdeShipperOwn = (this.propostasFiltradas.filter(p => p.flagShipperOwnContainer == 'S').length);
    this.qtdeEmbarcador = (this.propostasFiltradas.filter(p => p.flagOvaEmbarcador == 'S').length);
    this.qtdeRecebedor = (this.propostasFiltradas.filter(p => p.flagDesovaRecebedor == 'S').length);
    this.qtdeEquipePropriaOri = (this.propostasFiltradas.filter(p => p.flagEquipePropriaOri == 'S').length);
    this.qtdeEquipePropriaDes = (this.propostasFiltradas.filter(p => p.flagEquipePropriaDes == 'S').length);
    this.qtdeConferente = (this.propostasFiltradas.filter(p => p.flagConferenteOri == 'S' && p.flagConferenteDes == 'S').length);
    this.qtdeEscolta = (this.propostasFiltradas.filter(p => p.flagEscoltaOri == 'S' && p.flagEscoltaDes == 'S').length);

    this.qtdeCargaPerigosaNAO = (this.propostasFiltradas.filter(p => p.flagCargaPerigosa != 'S').length);
    this.qtdeCargaOverNAO = (this.propostasFiltradas.filter(p => p.flagCargaOver != 'S').length);
    this.qtdeShipperOwnNAO = (this.propostasFiltradas.filter(p => p.flagShipperOwnContainer != 'S').length);
    this.qtdeEquipePropriaOriNAO = (this.propostasFiltradas.filter(p => p.flagEquipePropriaOri != 'S').length);
    this.qtdeEquipePropriaDesNAO = (this.propostasFiltradas.filter(p => p.flagEquipePropriaDes != 'S').length);
    this.qtdeConferenteNAO = (this.propostasFiltradas.filter(p => p.flagConferenteOri != 'S' && p.flagConferenteDes != 'S').length);
    this.qtdeEscoltaNAO = (this.propostasFiltradas.filter(p => p.flagEscoltaOri != 'S' && p.flagEscoltaDes != 'S').length);

    if (this.qtdeEmbarcador == this.totalPropostasFiltradas)
      this.propostaFiltro.flagOva = 'N'; // '[INDEFINIDO]';
    else {
      if (this.qtdeEquipePropriaOri == this.totalPropostasFiltradas)
        this.propostaFiltro.flagOva = 'S';
      else if (this.qtdeEquipePropriaOriNAO == this.totalPropostasFiltradas)
        this.propostaFiltro.flagOva = 'N';
    }

    if (this.qtdeRecebedor == this.totalPropostasFiltradas)
      this.propostaFiltro.flagDesova = 'N'; // '[INDEFINIDO]';
    else {
      if (this.qtdeEquipePropriaDes == this.totalPropostasFiltradas)
        this.propostaFiltro.flagDesova = 'S';
      else if (this.qtdeEquipePropriaDesNAO == this.totalPropostasFiltradas)
        this.propostaFiltro.flagDesova = 'N';
    }

    if (this.qtdeConferente == this.totalPropostasFiltradas)
      this.propostaFiltro.flagConferente = 'S';
    else if (this.qtdeConferenteNAO == this.totalPropostasFiltradas)
      this.propostaFiltro.flagConferente = 'N';

    if (this.qtdeEscolta == this.totalPropostasFiltradas)
      this.propostaFiltro.flagEscolta = 'S';
    else if (this.qtdeEscoltaNAO == this.totalPropostasFiltradas)
      this.propostaFiltro.flagEscolta = 'N';

    if (this.propostaFiltro.flagCargaOver == '[INDEFINIDO]')
      this.propostaFiltro.flagCargaOver = (this.qtdeCargaOver == this.totalPropostasFiltradas) ? 'S' :
        (this.qtdeCargaOverNAO == this.totalPropostasFiltradas) ? 'N' : '[INDEFINIDO]';


    if (this.propostaFiltro.flagCargaPerigosa == '[INDEFINIDO]')
      this.propostaFiltro.flagCargaPerigosa = (this.qtdeCargaPerigosa == this.totalPropostasFiltradas) ? 'S' :
        (this.qtdeCargaPerigosaNAO == this.totalPropostasFiltradas) ? 'N' : '[INDEFINIDO]';

    if (this.propostaFiltro.flagShipperOwnContainer == '[INDEFINIDO]')
      this.propostaFiltro.flagShipperOwnContainer = (this.qtdeShipperOwn == this.totalPropostasFiltradas) ? 'S' :
        (this.qtdeShipperOwnNAO == this.totalPropostasFiltradas) ? 'N' : '[INDEFINIDO]';

    this.HabilitaBloqueiaServicos(this.todosServicosOrigem);
    this.HabilitaBloqueiaServicos(this.todosServicosDestino);

    this.listaNumPropostas = [];
    this.propostasFiltradas.forEach(x => {
      var existe = this.listaNumPropostas.find(i => i == x.numProposta);
      if (existe == null)
        this.listaNumPropostas.push(x.numProposta);
    });

    // Se chegou a uma proposta apenas, Seleciona!
    if (this.totalPropostasFiltradas == 1) {
      this.propostaFiltro.numProposta = this.propostasFiltradas[0].numProposta;
      this.propostaSelecionada = this.propostasFiltradas[0];
    }
    else {
      this.propostaSelecionada = undefined;
      if (this.propostasFiltradas.length > 0) {
        let numPropAux = this.propostasFiltradas[0].numProposta;
        if (this.propostasFiltradas.filter(f => f.numProposta == numPropAux).length == this.propostasFiltradas.length) {
          this.propostaFiltro.numProposta = this.propostasFiltradas[0].numProposta;
          this.propostaSelecionada = this.propostasFiltradas[0];
        }
      }
    }

    this.propostaSelecionadaEvent.emit(this.propostaSelecionada);

    if ((this.listaNumPropostas.length == 1) && (this.totalPropostasFiltradas > 1)) {
      //Encontrou um número de proposta porém com mais de um trecho
      this.DestacaFiltroIdentificadorTrecho();
    }
  }

  public DestacaFiltroIdentificadorTrecho() {
    // verifica qual filtro não foi informado, põe foco no campo e destaca
    var booking = this.booking;
    var filtro = this.propostaFiltro;

    if (this.AplicaDestaqueModal(booking.oriPorto, filtro.idPortolOrigem, this.cmbPortoOrigem))
      return;

    if (this.AplicaDestaqueModal(booking.desPorto, filtro.idPortoDestino, this.cmbPortoDestino))
      return;

    if (this.AplicaDestaqueModal(!booking.oriPorto, filtro.idMunicOrigem, this.cmbCidadeOrigem))
      return;

    if (this.AplicaDestaqueModal(!booking.desPorto, filtro.idMunicDestino, this.cmbCidadeDestino))
      return;

    if (this.AplicaDestaqueFiltroCondicao(filtro.idFamiliaProduto == 0, this.cmbProduto))
      return;

    if (this.AplicaDestaqueFiltroIndefinido(filtro.tipoOperacaoComercial, this.cmbOperacaoComercial))
      return;

    if (this.AplicaDestaqueFiltroCondicao(filtro.tipoDeContainer.idContainer == '0|0', this.cmbTipoContainer))
      return;

    if (this.AplicaDestaqueFiltroIndefinido(filtro.flagOva, this.cmbOva))
      return;

    if (this.AplicaDestaqueFiltroIndefinido(filtro.flagDesova, this.cmbDesova))
      return;

    if (this.AplicaDestaqueFiltroIndefinido(filtro.flagConferente, this.cmbConferente))
      return;

    if (this.AplicaDestaqueFiltroIndefinido(filtro.flagEscolta, this.cmbEscolta))
      return;

    if (this.AplicaDestaqueFiltroIndefinido(filtro.flagShipperOwnContainer, this.cmbShipper))
      return;

    if (this.AplicaDestaqueFiltroIndefinido(filtro.flagCargaPerigosa, this.cmbCargaPerigosa))
      return;

    if (this.AplicaDestaqueFiltroIndefinido(filtro.flagCargaOver, this.cmbCargaOver))
      return;

    if (this.AplicaDestaqueServicosEspeciais(filtro.servicosDaProposta, this.todosServicosOrigem, "Origem"))
      return;

    if (this.AplicaDestaqueServicosEspeciais(filtro.servicosDaProposta, this.todosServicosDestino, "Destino"))
      return;
  }

  private AplicaDestaqueModal(condicaoModal: boolean, idModal: number, combo: ElementRef): boolean {
    return this.AplicaDestaqueFiltroCondicao(condicaoModal && idModal == 0, combo);
  }

  private AplicaDestaqueFiltroIndefinido(filtro: string, combo: ElementRef): boolean {
    return this.AplicaDestaqueFiltroCondicao(filtro == '[INDEFINIDO]', combo);
  }

  private AplicaDestaqueFiltroCondicao(condicao: boolean, combo: ElementRef): boolean {
    if (!condicao)
      return false;

    this.SetFocus(combo);
    return true;
  }

  private AplicaDestaqueServicosEspeciais(servicosFiltro: ServicoProposta[], todosServicos: ServicoProposta[], indicadorOrigemDestino: string): boolean {
    for (var i = 0; i < todosServicos.length; i++) {
      var servico = todosServicos[i];
      if (servicosFiltro.filter(x => x.idTipoServico == servico.idTipoServico && x.indicOriDes == servico.indicOriDes).length != 0)
        continue;

      var hElement = this.elRef.nativeElement;
      var cmb = hElement.querySelector('#cmbServico' + indicadorOrigemDestino + i);
      setTimeout(function () { this.SetFocusNativeElement(cmb); }.bind(this));
      return true;
    }
    return false;
  }

  public HabilitaBloqueiaServicos(todosServicos: ServicoProposta[]) {
    todosServicos.forEach(x => {
      var qtdServicoSIM: number;
      var qtdServicoNAO: number;
      var valor: string = '[INDEFINIDO]';
      var servicoFiltro: ServicoProposta;

      x.disabled = false;
      x.valor = '[INDEFINIDO]';

      //qtdServicoSIM = this.propostasFiltradas.filter(p => (p.servicosDaProposta.filter(s => s.idTipoServico == x.idTipoServico && s.indicOriDes == x.indicOriDes).length > 0)).length;
      //qtdServicoNAO = this.propostasFiltradas.filter(p => (p.servicosDaProposta.filter(s => s.idTipoServico != x.idTipoServico && s.indicOriDes == x.indicOriDes).length > 0)).length;
      if (x.indicOriDes == 'O') {
        qtdServicoSIM = this.propostasFiltradas.filter(p => (',' + p.listaTipoServicoOri).indexOf(',' + x.idTipoServico + ',') >= 0).length;
        qtdServicoNAO = this.propostasFiltradas.filter(p => (',' + p.listaTipoServicoOri).indexOf(',' + x.idTipoServico + ',') == -1).length;
      }
      else {
        qtdServicoSIM = this.propostasFiltradas.filter(p => (',' + p.listaTipoServicoDes).indexOf(',' + x.idTipoServico + ',') >= 0).length;
        qtdServicoNAO = this.propostasFiltradas.filter(p => (',' + p.listaTipoServicoDes).indexOf(',' + x.idTipoServico + ',') == -1).length;
      }

      servicoFiltro = this.propostaFiltro.servicosDaProposta.find(f => f.idTipoServico == x.idTipoServico && f.indicOriDes == x.indicOriDes);

      if (servicoFiltro != null) {
        if (servicoFiltro.valorSelecionado != '[INDEFINIDO]')
          x.disabled = true;
        valor = servicoFiltro.valorSelecionado;
      }

      if (valor == '[INDEFINIDO]' || valor == null) {
        x.valorSelecionado = (qtdServicoSIM == 0 && qtdServicoNAO > 0) ? 'N' : (qtdServicoSIM > 0 && qtdServicoNAO == 0) ? 'S' : '[INDEFINIDO]';

        if (x.valorSelecionado != '[INDEFINIDO]')
          x.disabled = true;
      }

      if (!x.disabled)
        x.disabled = (qtdServicoSIM == 0 && qtdServicoNAO > 0) || (qtdServicoSIM > 0 && qtdServicoNAO == 0) || (qtdServicoSIM == 0 && qtdServicoNAO == 0);

      //x.disabled = (qtdServicoSIM == 0 || qtdServicoSIM == this.propostasFiltradas.length) || ((qtdServicoNAO == 0 || qtdServicoNAO == this.propostasFiltradas.length) ); //(this.propostasFiltradas.filter(p => (p.servicosDaProposta.filter(s => s.idTipoServico == x.idTipoServico && s.indicOriDes == x.indicOriDes).length > 0)).length == 0);
      /*
      if (this.propostasFiltradas.filter(p => p.servicosDaProposta.filter(s => s.idTipoServico == x.idTipoServico && s.indicOriDes == x.indicOriDes).length == 0).length == 0) {
        x.disabled = true;
      }
      */
    });
  }

  public filtroNumero(valorComparar: any, valor: number): boolean {
    return (valorComparar == valor || valor == 0);
  }

  public filtroFlag(valorComparar: any, valor: string): boolean {
    if (valor == '[INDEFINIDO]')
      return true;

    return ((valorComparar == null || valorComparar == '' || valorComparar == ' ' ? 'N' : valorComparar) == valor);
  }

  public filtroString(valorComparar: any, valor: string): boolean {
    valor = (valor == 'null') ? null : valor;
    return (valorComparar == valor || valor == '[INDEFINIDO]');
  }

  public filtroTipoContainer(valorComparar: string, valor: string) {
    valorComparar = (valorComparar == undefined || valorComparar == null) ? '' : valorComparar;
    valor = (valor == undefined || valor == "undefined" || valor == null) ? '' : valor;
    return (valorComparar == valor || valor == '0|0');
  }

  public filtrarServico(servico: ServicoProposta, valor: string) {
    var s = this.propostaFiltro.servicosDaProposta.find(x => x.idTipoServico == servico.idTipoServico && x.indicOriDes == servico.indicOriDes);
    if (s == null) {
      servico.valor = valor;
      servico.valorSelecionado = valor;
      this.propostaFiltro.servicosDaProposta.push(servico);
    }
    else {
      if (valor == '[INDEFINIDO]')
        this.propostaFiltro.servicosDaProposta.splice(this.propostaFiltro.servicosDaProposta.indexOf(s), 1);
      else
        s.valor = valor; // verificar pq disso
    }
    this.FiltraPropostas();
  }

  public filtrarServicoProposta(servicosOri: string, servicosDes: string) {
    if (this.propostaFiltro.servicosDaProposta.length == 0)
      return true;

    var ret: boolean = true;
    this.propostaFiltro.servicosDaProposta.forEach(x => {
      var str = (x.indicOriDes == 'O') ? ',' + servicosOri : ',' + servicosDes;
      if (!(((str.indexOf(',' + x.idTipoServico + ',') >= 0) && (x.valorSelecionado == 'S')) ||  // trocado x.valor por x.valorSelecionado
        ((str.indexOf(',' + x.idTipoServico + ',') == -1) && (x.valorSelecionado == 'N')))) { // trocado x.valor por x.valorSelecionado
        ret = false;
        return ret;
      }
    });

    return ret;
  }

  public ObterTodosTiposOperacao() {
    this._dataService.getTiposOperacaoComercial()
      .subscribe(lista => {
        lista.forEach(x => this.todosTiposOperacao.push({ codigo: x.codigo, descricao: x.descricao }));
        this.todosTiposOperacao.push({ codigo: null, descricao: '(Vazio)' });
      });
  }

  // Busca produtos das propostas filtradas
  private obterProdutos(): void {
    //TODO: Adicionar "(Vazio)" para quando vier proposta com produto null
    this.produtos = [];
    this._groupbyService.AgruparPorCampo(this.propostasFiltradas, 'idFamiliaProduto').forEach(x => {
      if (x.idFamiliaProduto) {
        this.produtos.push({ codigo: x.idFamiliaProduto, descricao: x.descFamiliaProduto });
      }
    });

    this.produtos = this._genericOrderbyService.ordenarPorCampo(this.produtos, 'Descricao');

    if (this.produtos.length == 1)
      this.propostaFiltro.idFamiliaProduto = this.produtos[0].codigo;
  }

  // Busca tipos de operação comercial das propostas filtradas
  private obterTiposOperacao(): void {
    this.tiposOperacao = [];

    this.todosTiposOperacao.forEach(tp => {
      var prop = this.propostasFiltradas.find(p => p.tipoOperacaoComercial == tp.codigo);
      if (prop != null)
        this.tiposOperacao.push(tp);
    });

    this.tiposOperacao = this._genericOrderbyService.ordenarPorCampo(this.tiposOperacao, 'descricao');

    if (this.tiposOperacao.length == 1)
      this.propostaFiltro.tipoOperacaoComercial = this.tiposOperacao[0].codigo;
  }

  // Busca produtos das propostas filtradas
  private obterTiposContainers(): void {
    //TODO: Adicionar "(Vazio)" para quando vier proposta com tipo de contêiner null
    this.tipoContainers = [];

    this.todosTipoContainers.forEach(tp => {
      var prop = this.propostasFiltradas.find(p => p.tipoDeContainer.idContainer == tp.idContainer);
      if (prop != null)
        this.tipoContainers.push(tp);
    });

    this.tipoContainers = this._genericOrderbyService.ordenarPorCampo(this.tipoContainers, 'Descricao');

    if (this.tipoContainers.length == 1)
      this.propostaFiltro.tipoDeContainer = this.tipoContainers[0];
  }

  // Busca cidades de origem das propostas filtradas
  public obterCidadesOrigem(): void {
    //TODO: Adicionar "(Vazio)" para quando vier proposta com cidade nula
    this.cidadesOrigem = [];
    this.isLoadingCities = true;
    if (this.propostaFiltro.modal.startsWith('PA')) {
      this._groupbyService.AgruparPorCampo(this.propostasFiltradas, 'idMunicOrigem').forEach(x => {
        if (x.idMunicOrigem) {
          this.cidadesOrigem.push({
            codigo: x.idMunicOrigem, descricao: x.municOrigem, uf: x.ufOrigem,
            descricaoCompleta: x.municOrigem + ' / ' + x.ufOrigem
          });
        }
      });

      this.cidadesOrigem = this._genericOrderbyService.ordenarPorCampo(this.cidadesOrigem, 'Descricao');

      // se sobrou apenas uma, seleciona
      if (this.cidadesOrigem.length == 1)
        this.propostaFiltro.idMunicOrigem = this.cidadesOrigem[0].codigo;
      this.isLoadingCities = false;
    }
  }

  // Busca cidades de destino das propostas filtradas
  public obterCidadesDestino(): void {
    //TODO: Adicionar "(Vazio)" para quando vier proposta com cidade nula
    this.cidadesDestino = [];
    if (this.propostaFiltro.modal.endsWith('PA')) {
      this._groupbyService.AgruparPorCampo(this.propostasFiltradas, 'idMunicDestino').forEach(x => {
        if (x.idMunicDestino) {
          this.cidadesDestino.push({
            codigo: x.idMunicDestino, descricao: x.municDestino,
            uf: x.ufDestino, descricaoCompleta: x.municDestino + ' / ' + x.ufDestino
          });
        }
      });

      this.cidadesDestino = this._genericOrderbyService.ordenarPorCampo(this.cidadesDestino, 'Descricao');

      // se sobrou apenas uma, seleciona
      if (this.cidadesDestino.length == 1)
        this.propostaFiltro.idMunicDestino = this.cidadesDestino[0].codigo;
    }
  }

  // Busca portos de origem das propostas filtradas
  public obterPortosOrigem(): void {
    this.portosOrigem = [];
    if (this.propostaFiltro.modal.startsWith('PO')) {
      this.todosPortos.forEach(porto => {
        var prop = this.propostasFiltradas.find(p => p.idPortolOrigem == porto.codigo);
        if (prop != null)
          this.portosOrigem.push(porto);
      });

      this.portosOrigem = this._genericOrderbyService.ordenarPorCampo(this.portosOrigem, 'Descricao');

      // se sobrou apenas um, seleciona
      if (this.portosOrigem.length == 1)
        this.propostaFiltro.idPortolOrigem = this.portosOrigem[0].codigo;
    }
  }

  // Busca portos de destino das propostas filtradas
  public obterPortosDestino(): void {
    this.portosDestino = [];
    if (this.propostaFiltro.modal.endsWith('PO')) {
      this.todosPortos.forEach(porto => {
        var prop = this.propostasFiltradas.find(p => p.idPortoDestino == porto.codigo);
        if (prop != null)
          this.portosDestino.push(porto);
      });

      this.portosDestino = this._genericOrderbyService.ordenarPorCampo(this.portosDestino, 'Descricao');

      // se sobrou apenas um, seleciona
      if (this.portosDestino.length == 1)
        this.propostaFiltro.idPortoDestino = this.portosDestino[0].codigo;
    }
  }

  // Busca todos os portos (pois array de propostas não tem cidade dos portos para popular a combo.)
  public ObterPortos() {
    this._sharedService.getPortos()
      .subscribe(p => {
        this.todosPortos = p;
        this.todosPortos.push({ codigo: null, descricao: '', municipio: '', uF: '' });
      });
  }

  public SetFocus(campo: ElementRef) {
    this.SetFocusNativeElement(campo.nativeElement);
  }

  public SetFocusNativeElement(nativeElement: any) {
    // this._renderer.selectRootElement(nativeElement).focus();
    setTimeout(() => {
      nativeElement.focus();
    }, 150);

    this.alteraBordaControle(nativeElement, 2, '#18b73d');
  }

  public limparFiltros() {
    this.limpaServicosAdicionais(this.todosServicosOrigem);
    this.limpaServicosAdicionais(this.todosServicosDestino);
    this.propostaFiltro = new PropostaComercialFiltro();
    this.propostaFiltro.modal = this.booking.modal;
    this.booking.qtdeContainer = 0;
    this.propostaSelecionada = undefined;
    this.FiltraPropostas();
  }

  limparBordas(controles: ElementRef[]) {
    controles.forEach(controle => {
      this.alteraBordaControle(controle.nativeElement, 1, '#CBD5E7');
    });
  }

  alteraBordaControle(nativeElement: any, tamanho: number, cor: string) {
    var dataHeight = nativeElement.attributes.getNamedItem("data-height");
    var height = dataHeight ? 'height:' + dataHeight.value + ";" : '';
    this._renderer.setAttribute(nativeElement, 'style', height + 'border:' + tamanho + ';border-color:' + cor);
  }
}
