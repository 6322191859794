
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { Operacao } from '../_enums/operacao';

import { BaseService } from './base.service';
import { PortoInstantPaymentRequest } from '../_models/PortoInstantPaymentRequest';
import { ValidationResult } from './../_models/validation-result';
import { MessageService } from './message.service';

@Injectable({
    providedIn: 'root'
})
export class PortoService {

    constructor(private baseService: BaseService,
                private _msgService: MessageService) { }

    salvar(portoInstantPayment: PortoInstantPaymentRequest): Observable<ValidationResult> {


      return this.baseService.Post('porto-instant-payment', portoInstantPayment).pipe(
          map((response: ValidationResult) => {
              let retorno: ValidationResult = response;
              return retorno;
          }),catchError(e => {
              return this.baseService.handleError(e);
          }),);
    }


    deletar(id: number): Observable<ValidationResult> {
        return this.baseService.Delete('porto/' + id).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getAll(): Observable<PortoInstantPaymentRequest[]> {
        return this.get('porto-instant-payment');
    }

    getPortosAtivos(): Observable<PortoInstantPaymentRequest[]> {
        return this.get('porto-instant-payment/get-ativos');
    }


    findByRegionId(regionId: number): Observable<PortoInstantPaymentRequest[]> {
      let urlPath: string =  'porto-instant-payment/find-by-region-id?region=' + regionId;

      return this.baseService.Get(urlPath).pipe(
        map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (!retorno.IsValid) {
            this._msgService.addMessageValidationError('Porto', retorno.Erros, 'error', LogFrontFuncionalidade.Porto, Operacao.Consulta, null);
          }

          return retorno.Data as PortoInstantPaymentRequest[];
        }),catchError(e => {
          return this.baseService.handleError(e);
        }),);
    }



    findByRegionIdPortId(regionId: number, portId: number): Observable<PortoInstantPaymentRequest[]> {
      let urlPath: string =  'porto-instant-payment/find-by-region-id-port-id?region=' + regionId + '&port=' + portId;

      return this.baseService.Get(urlPath).pipe(
        map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (!retorno.IsValid) {
            this._msgService.addMessageValidationError('Porto', retorno.Erros, 'error', LogFrontFuncionalidade.Porto, Operacao.Consulta, null);
          }

          return retorno.Data as PortoInstantPaymentRequest[];
        }),catchError(e => {
          return this.baseService.handleError(e);
        }),);
    }



    private get(path: string): Observable<PortoInstantPaymentRequest[]> {
        return this.baseService.Get(path).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as PortoInstantPaymentRequest[];
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getById(id: number): Observable<PortoInstantPaymentRequest> {
        return this.baseService.Get('porto-instant-payment/' + id).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as PortoInstantPaymentRequest;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

}
