import { Component, OnInit, ViewChild, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Cliente } from 'app/_models/cliente';
import { Feriado } from 'app/_models/feriado';
import { Endereco } from 'app/_models/endereco';
import { AgendaEntrega } from 'app/_models/agenda-entrega';
import { TrackingLista } from 'app/_models/tracking-lista';
import { ClienteRequest } from 'app/_models/cliente-request';
import { AgendaEntregaSalvar } from 'app/_models/agenda-entrega-salvar';
import { TrackingListaRequest } from './../../../_models/trackinglista-request';

import { LogService } from 'app/_services/log.service';
import { AppService } from 'app/_services/app.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from 'app/_services/message.service';
import { GroupbyService } from 'app/_services/groupby.service';
import { DatepickerService } from 'app/_services/datepicker.service';
import { AgendaEntregaService } from 'app/_services/agenda-entrega.service';

import { BaseComponent } from './../../../shared/base.component';
import { EnderecoComponent } from 'app/shared/endereco/endereco.component';
import { ItemEntregaAgendamentoComponent } from 'app/cliente/rastreamento/agendamento-rastreamento/item-entrega-agendamento/item-entrega-agendamento.component';
import { AnalyticsService } from 'app/_services/analytics.service';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import swal from 'sweetalert2';

@Component({
  selector: 'agendamento-rastreamento',
  templateUrl: './agendamento-rastreamento.component.html',
  styleUrls: ['./agendamento-rastreamento.component.scss']
})
export class AgendamentoRastreamentoComponent extends BaseComponent implements OnInit {

  @ViewChild('lgModalAgendamento', {static: true}) lgModalAgendamento;
  @ViewChild(ItemEntregaAgendamentoComponent, {static: true}) itemEntrega: ItemEntregaAgendamentoComponent;
  @ViewChild('topo', {static: true}) private topo: ElementRef;
  @ViewChild(EnderecoComponent, {static: true}) enderecoEntrega: EnderecoComponent;
  @Output() carregaFiltrosDeRastreamento: EventEmitter<TrackingListaRequest> = new EventEmitter();

  rastreamentos: TrackingLista[] = [];
  listaAgendamentos: AgendaEntrega[] = new Array();
  inscricaoContaneres: Subscription;
  inscricaoBooking: Subscription;
  inscricaoSalvar: Subscription;
  loadingSalvar: boolean = false;
  loadingItems: boolean = false;
  loadingEndereco: boolean = false;
  exibeCopiar: boolean = true;
  soLeitura: boolean = false;
  eAdmin: boolean = this._appService.isAdmin();
  Destinatario: Cliente = new Cliente();
  qtdeAgendamentosCadastrados: number = 0;
  feriados: Feriado[];

  clientes: Cliente[];
  loadingClientes: boolean = false;
  enderecoSelecionado: Endereco = new Endereco();

  idPorto: number;
  dataSaidaPorto: Date;
  cnpjRec: string = '';
  ieRec: string = '';
  nomeRec: string = '';
  logradouroRec: string = '';
  numeroRec: string = '';
  bairroRec: string = '';
  complementoRec: string = '';
  cidade: string = '';
  ufRec: string = '';
  contatoRec: string = '';
  dddRec: string = '';
  foneRec: string = '';
  cepRec: string = '';
  observacao: string = '';
  ddd: string = '';
  telefone: string = '';
  idMunicipio: number;
  mensagemErro: string = '';
  qtdHorasEntregaETA: number;
  qtdMinHorasEntrega: number;
  dataMinimaAgendamento: Date;
  enderecoNovo: Endereco;
  horasMinimas: number;
  msgDataMinima: string = '';
  agendouTodos: boolean = false;

  constructor(private _sharedService: SharedService,
    private _agendaEntregaService: AgendaEntregaService,
    private _datepickerService: DatepickerService,
    private _appService: AppService,
    private _msgService: MessageService,
    private _logService: LogService,
    private agendaSalvar: AgendaEntregaSalvar,
    private _groupBy: GroupbyService,
    private _analyticsService: AnalyticsService) {
    super();
  }

  ngOnInit() {
    this._agendaEntregaService.emitirModal.subscribe(
      modal  => this.showModal(modal)
    );
  }

  public showModal(modal):void {
    if(modal)
      this.lgModalAgendamento.show();
    else
      this.lgModalAgendamento.hide();
  }

  ngOnDestroy() {
    if (this.inscricaoContaneres)
      this.inscricaoContaneres.unsubscribe;
    if (this.inscricaoBooking)
      this.inscricaoBooking.unsubscribe;
    if (this.inscricaoSalvar)
      this.inscricaoSalvar.unsubscribe;
  }

  setRastreamento(rastreamentos, soLeitura: boolean = false): void {
    this.loadingEndereco = true;
    this.rastreamentos = rastreamentos;
    this.listaAgendamentos = new Array();
    if (this.rastreamentos.length > 0) {
      var cli: ClienteRequest = new ClienteRequest();
      cli.cnpj = this.rastreamentos[0].cnpjDes;
      cli.inscricaoEstadual = this.rastreamentos[0].ieDes;
      cli.idMunicipio = this.rastreamentos[0].idMunicipioDes;
      this.idPorto = this.rastreamentos[0].idPortoDestino;
      this.dataSaidaPorto = this.rastreamentos[0].dataSaidaPortoDestino;

      this._sharedService.getClientes(cli,false).subscribe(c => {

        if (c == null) {
          this.Destinatario = null;
          this.loadingEndereco = false;
          swal({
            title: 'Alerta',
            text: 'Nenhum destinatario foi encontrado!',
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: 'OK',
          })
        }
        else {
          this.clientes = c.filter(x => x.cnpj == cli.cnpj);
          this.Destinatario = ((this.clientes != undefined) && (this.clientes.length == 1)) ? this.clientes[0] : null;

          if (this.Destinatario) {
            this.carregaEnderecoEntrega();
          }
          this.cnpjRec = this._sharedService.formataCNPJ(this.rastreamentos[0].cnpjDes);
          this.nomeRec = this.rastreamentos[0].nomeDes;
          this.ieRec = this.rastreamentos[0].ieDes;
          this.logradouroRec = this.rastreamentos[0].logradouroDes;
          this.numeroRec = this.rastreamentos[0].numeroEndDes;
          this.bairroRec = this.rastreamentos[0].bairroDes;
          this.complementoRec = this.rastreamentos[0].complementoDes;
          this.ufRec = this.rastreamentos[0].ufDes;
          this.contatoRec = this._appService.UsuarioLogado.nome; //this.rastreamentos[0].contatoDes;
          this.dddRec =  this._appService.UsuarioLogado.telefone.substr(2,  this._appService.UsuarioLogado.telefone.length - 1); // this.rastreamentos[0].dddDes;
          this.foneRec = this._appService.UsuarioLogado.telefone; //this._sharedService.replaceAll([this.rastreamentos[0].dddDes, this.rastreamentos[0].telefoneDes].join(''), ' ', '');
          if (this.foneRec.length > 11) //trunca de acordo com a máscara
            this.foneRec = this.foneRec.substr(0, 11);
          this.formataTelefoneEnderecoAgendaEntrega(this.foneRec);
          this.cepRec = this.rastreamentos[0].cepDes;
          this.cidade = this.rastreamentos[0].municEntrega.trim(); //.booking.municDes.trim(); // + '/' + this.rastreamentos[0].booking.ufDes;
          this.idMunicipio = this.rastreamentos[0].idMunicipioEntrega;

          this.enderecoNovo = new Endereco();
          this.enderecoNovo.municipio = this.cidade;
          this.enderecoNovo.uf = this.ufRec;
          this.enderecoNovo.idMunicipio = this.idMunicipio;

          this.agendaSalvar.idBooking = this.rastreamentos[0].idBooking;
          this.agendaSalvar.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
          this.loadLista();
        }
        //this.loadingEndereco = false;

      });
    }
  }

  carregaEnderecoEntrega() {
    this.enderecoSelecionado = new Endereco();

    this.enderecoSelecionado.logradouro = this.rastreamentos[0].logradouroDes;
    this.enderecoSelecionado.numero = this.rastreamentos[0].numeroEndDes;
    this.enderecoSelecionado.bairro = this.rastreamentos[0].bairroDes;
    this.enderecoSelecionado.complemento = this.rastreamentos[0].complementoDes;
    this.enderecoSelecionado.uf = this.rastreamentos[0].ufDes;
    this.enderecoSelecionado.cep = this.rastreamentos[0].cepDes;
    this.enderecoSelecionado.municipio = this.rastreamentos[0].municEntrega; //this.rastreamentos[0].booking.municDes;
    this.enderecoSelecionado.idMunicipio = this.rastreamentos[0].idMunicipioEntrega;

    this.enderecoEntrega.carregaEnderecos(this.enderecoSelecionado, this.Destinatario.enderecos);
  }

  exibirAdicionarNovo() {
    this.enderecoEntrega.novo = true;
    this.enderecoEntrega.permiteEditarEndereco = true;
    //this.enderecoEntrega.endereco = this.enderecoNovo;
    this.enderecoEntrega.endereco = this.enderecoEntrega.copiaObjetoEndereco(this.enderecoNovo);

    this.contatoRec = '';
    this.dddRec = '';
    this.foneRec = '';
    this.formataTelefoneEnderecoAgendaEntrega(this.foneRec);
  }

  public cancelarAdicionarNovo() {
    this.cnpjRec = this._sharedService.formataCNPJ(this.rastreamentos[0].cnpjDes);
    this.nomeRec = this.rastreamentos[0].nomeDes;
    this.ieRec = this.rastreamentos[0].ieDes;
    this.contatoRec = this.rastreamentos[0].contatoDes;
    this.dddRec = this.rastreamentos[0].dddDes;
    this.foneRec = this._sharedService.replaceAll(this.rastreamentos[0].dddDes + this.rastreamentos[0].telefoneDes, ' ', '');
    this.formataTelefoneEnderecoAgendaEntrega(this.foneRec);

    this.enderecoEntrega.novo = false;
    this.enderecoEntrega.indexSelecionado = -1;
    this.carregaEnderecoEntrega();
  }

  loadLista(): void {
    this.loadingItems = true;
    let consulta = new AgendaEntrega();
    consulta.idBooking = this.rastreamentos[0].idBooking;
    consulta.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;

    var idPortoDestino = this.rastreamentos[0].idPortoDestino;

    this._sharedService.getFeriados(idPortoDestino)
      .subscribe(feriados => {
        //this.feriados = feriados;
        if (feriados)
          this.feriados = feriados.filter(f => f.Data != null);
        else
          this.feriados = null;

        var dataSaida = this._sharedService.corrigeData(this.rastreamentos[0].dataSaidaPortoDestino);
        var qtdETA: Date = this._sharedService.CalculaDataMinimaAgendamento(feriados, dataSaida, this.qtdHorasEntregaETA); //this._sharedService.CalculaProximoDiaUtil(feriados, dataSaida, this.qtdHorasEntregaETA);
        var qtdMin: Date = this._sharedService.CalculaDataMinimaAgendamento(feriados, new Date(), this.qtdMinHorasEntrega);

        if (qtdETA.getTime() > qtdMin.getTime()) {
          this.dataMinimaAgendamento = qtdETA;
          this.horasMinimas = this.qtdHorasEntregaETA;
          this.msgDataMinima = ', A entrega deve ser agendada ' + this.horasMinimas.toString() + ' horas após a data de saída do navio (respeitando finais de semana e feriados). Data/Hora no agendamento deve ser superior a ' + this.dataMinimaAgendamento.toLocaleString() + '!';
        }
        else {
          this.dataMinimaAgendamento = qtdMin;
          this.horasMinimas = this.qtdMinHorasEntrega;
          this.msgDataMinima = ', A entrega deve ser agendada ' + this.horasMinimas.toString() + ' horas após a data atual (respeitando finais de semana e feriados). Data/Hora no agendamento deve ser superior a ' + this.dataMinimaAgendamento.toLocaleString() + '!';
        }

        this.inscricaoContaneres = this._agendaEntregaService.Consulta(consulta)
          .subscribe(agendados => {
            // Navega nos rastreamentos para verificar se existe agendamento. Se não existir, adiciona na lista de não agendados

            if (agendados)
              agendados.forEach(agendamento => this.listaAgendamentos.push(agendamento));

            this.rastreamentos.forEach(track => {
              if (agendados && agendados.find(f => f.numContainer == track.numContainer) == null)
                this.listaAgendamentos.push(this.criaAgendaFromTracking(track));
            });

            this.qtdeAgendamentosCadastrados = this.listaAgendamentos.filter(a => a.idAgendamento != 0).length;
            this.agendouTodos = (agendados && agendados.length > 0) && (this.qtdeAgendamentosCadastrados == this.listaAgendamentos.length);

            this.obtemObservacoes();

            this.loadingItems = false;
            this.loadingEndereco = false;
          },
          (err) => console.log('erro 5')
          //console.log(err)
        );
    });
  }

  naoAgendado(agendaEntrega: AgendaEntrega): boolean {
    return !agendaEntrega.idAgendamento;
  }

  bloqueiaEdicao(): boolean {
    if (!this.listaAgendamentos)
      return false;
    return this.listaAgendamentos.find(a => a.idAgendamento == 0) == null;
  }

  copiar() {
    if (this.listaAgendamentos.length > 1) {
      for (let i = (this.qtdeAgendamentosCadastrados+1); i < this.listaAgendamentos.length; i++) {
        var agendamento = this.listaAgendamentos[i];
        var primeiroAgendamento = this.listaAgendamentos[this.qtdeAgendamentosCadastrados];
        agendamento.numRefCliente = primeiroAgendamento.numRefCliente;
        agendamento.horaEntrega = primeiroAgendamento.horaEntrega;
        agendamento.dataCompleta = this._sharedService.clonaDateModel(primeiroAgendamento.dataCompleta);
      }
    }
  }

  fechar(myForm: NgForm) {
    this.enderecoEntrega.novo = false;
    this.observacao = '';
    myForm.form.markAsPristine();
    myForm.form.markAsUntouched();
    this._agendaEntregaService.hideModal();
  }

  ocultarMensagem() {
    //this.mensagemErro = '';
    this.exibeMensagemErro('');
  }

  criaAgendaFromTracking(track: TrackingLista): AgendaEntrega {
    let agenda = new AgendaEntrega();
    agenda.idBooking = track.idBooking;
    agenda.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    agenda.idAgendamento = 0;
    agenda.numContainer = track.numContainer;
    agenda.numRefCliente = track.numRefCli;
    agenda.dataEntrega = this.dataMinimaAgendamento; //new Date();
    agenda.horaEntrega = '';
    agenda.observacao = '';
    agenda.cnpjEmb = track.cnpjDes;
    agenda.ieEmb = track.ieDes; // '';
    agenda.nomeEmb = track.nomeDes; // '';
    agenda.logradouroEnt = '';
    agenda.numeroEndEnt = '';
    agenda.bairroEnt = '';
    agenda.complementoEnt = '';
    agenda.idMunicipioEnt = track.idMunicipioEntrega; //track.booking.idMunicDes; //0;
    agenda.municipioEnt = track.municEntrega; //.booking.municDes; //'';
    agenda.ufEnt = track.ufDes; //.booking.ufDes; //'';
    agenda.contatoEnt = '';
    agenda.dddEnt = '';
    agenda.foneEnt = '';
    agenda.cepEnt = '';
    agenda.idPorto = track.idPortoDestino;
    agenda.dataSaidaPortoDestino = track.dataSaidaPortoDestino;

    return agenda;
  }

  public ConfigurarDataEntrega(agendamento: AgendaEntrega){
    // Se a data selecionada for nula ou inválida, retorna nulo
    if ((agendamento == undefined) ||
        (agendamento.dataCompleta == undefined) ||
        !this._datepickerService.IsValid(agendamento.dataCompleta) ||
        (!agendamento.horaEntrega))
      return;

    // Configura o campo 'dataColeta' baseado na data seleiconada
    // no DatePicker e na hora informada no input
    agendamento.dataEntrega = this._datepickerService.ObtemDataHora(agendamento.dataCompleta, agendamento.horaEntrega);
  }

  formataTelefoneEnderecoAgendaEntrega(telefone: string): void {
    if (telefone) {
      telefone = telefone.replace(/\D/g, '');
      if (telefone && telefone.length > 9) {
        this.ddd = this._sharedService.obtemDDDdoTelefone(telefone);
        this.telefone = this._sharedService.obtemTelefoneSemDDD(telefone);
        this.foneRec = this.ddd + this.telefone;
      }
    }
  }

  salvar(myForm: NgForm) {
    this.agendaSalvar.Containeres = [];
    this.exibeMensagemErro('');

    var mensagem = '';
    var endAgend = this.enderecoEntrega.obtemEndereco();

    if (!this.validaEndereco(endAgend))
      return;

    this.loadingSalvar = true;
    var item: number = 1;

    var agendamentos = this.listaAgendamentos.filter(a => a.idAgendamento == 0);
    this._analyticsService.RegistrarAgendamentosTracking(agendamentos.length);
    agendamentos.forEach(agenda => {
      if (this.temAgendamento(agenda)) {

        // Configura o campo 'dataEntrega'
        this.ConfigurarDataEntrega(agenda);

        //valida se data de agendamento é permitida
        if (this.dataMinimaAgendamento >= agenda.dataEntrega) {
          //mensagem += 'Agendamento #' + item + ', Data/hora informada no agendamento deve ser superior a ' + this.dataMinimaAgendamento.toLocaleString() + '!';
          //mensagem += 'Agendamento #' + item + ', A entrega deve ser agendada com ' + this.horasMinimas.toString() + ' horas de antecedência (respeitando finais de semana e feriados). Data/Hora no agendamento deve ser superior a ' + this.dataMinimaAgendamento.toLocaleString() + '!';
          mensagem += 'Agendamento #' + item + this.msgDataMinima;
        }
        //else if (this._sharedService.isFeriadoFds(this.feriados, agenda.dataEntrega)) {
        //  //Verifica se data do agendamento é feriado ou final de semana
        //  mensagem += "Agendamento #" + item + ", A entrega deve ser realizada em dias úteis. O dia " + agenda.dataEntrega.toLocaleString() + " é um final de semana ou feriado!";
        //}
        else {
          var cnpjSemFormatacao = "";
          if (this.cnpjRec != undefined) {
            cnpjSemFormatacao = this._sharedService.replaceAll(this.cnpjRec, '.', '');
            cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '/', '');
            cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '-', '');
          }

          if (endAgend) {
            agenda.cnpjEmb = cnpjSemFormatacao;
            agenda.ieEmb = this.ieRec;
            agenda.nomeEmb = this.nomeRec;
            agenda.logradouroEnt = endAgend.logradouro; //this.logradouroRec;
            agenda.numeroEndEnt = endAgend.numero; // this.numeroRec;
            agenda.bairroEnt = endAgend.bairro; // this.bairroRec;
            agenda.complementoEnt = endAgend.complemento; // this.complementoRec;
            agenda.contatoEnt = this.contatoRec;
            agenda.dddEnt = this.ddd; // this.dddRec; //-----
            agenda.foneEnt = this.telefone; // this.foneRec;
            agenda.cepEnt = endAgend.cep ? endAgend.cep.replace('-', '') : ''; // this.cepRec;
            agenda.observacao = this.observacao;
          }
          //agenda.dataEntrega = agenda.dataCompleta.formatted;

          this.agendaSalvar.Containeres.push(agenda);
        }
      }

      item++;
    });

    if (mensagem.length > 0){
      this.loadingSalvar = false;
      this.exibeMensagemErro(mensagem);
      return;
    }

    if (this.agendaSalvar.Containeres.length > 0) {
      this._msgService.clearMessage();
      this.inscricaoSalvar = this._agendaEntregaService.Salvar(this.agendaSalvar)
        .subscribe(result => {
          this.loadingSalvar = false;
          if (result){
            this.fechar(myForm);
            this.carregaFiltrosDeRastreamento.emit(null);
          }
        });
    }
    else {
      this._msgService.addMessage('Modal', 'Informe a data e hora de agendamento de ao menos 1 conteiner!', 'error', LogFrontFuncionalidade.Rastreamento, Operacao.Inclusao,
          this.listaAgendamentos);
      this.loadingSalvar = false;
      //this.fechar();
    }
  }

  validaEndereco(end: Endereco): boolean {
    var campos: string[] = [];

    if (!end)
      this.exibeMensagemErro("Informe o endereço de entrega!");
    else {
      //Verifica campos obrigatórios da aba
      if (!this.cnpjRec) campos.push("CNPJ");
      if (!this.ieRec) campos.push("Inscrição Estadual");
      if (!this.nomeRec) campos.push("Razão Social");
      if (!end.cep) campos.push("CEP");
      if (!end.logradouro) campos.push("Logradouro");
      if (!end.numero) campos.push("Número");
      if (!end.bairro) campos.push("Bairro");
      if (!this.contatoRec) campos.push("Contato");
      if (!this.foneRec) campos.push("Telefone");

      //Informa os campos obrigatórios não preenchidos, se houver
      if (campos.length > 0) {
        //this.mensagemErro = "Campos obrigatórios: " + campos.join(", ");
        this.exibeMensagemErro("Campos obrigatórios: " + campos.join(", "));
        return false;
      }
    }
    if (!this._sharedService.validaTelefone(this.foneRec)) {
      this.exibeMensagemErro("Telefone inválido");
      return false;
    }

    return true;
  }

  temAgendamento(agenda): boolean {
    return (agenda.dataCompleta && agenda.horaEntrega);
  }

  exibeMensagemErro(msg: string) {
    this.mensagemErro = msg;

    this._logService.logFront(this.agendaSalvar, LogFrontFuncionalidade.Rastreamento, msg, Operacao.Inclusao, 'Agendamento de Entrega');

    if (this.mensagemErro && this.mensagemErro.length > 0)
      setTimeout(() => { this.topo.nativeElement.scrollIntoView(); });
  }

  mudouDestinatario() {
    this.ieRec = '';
    this.nomeRec = '';
    //this.enderecoSelecionado = this.enderecoNovo;
    //this.enderecoEntrega.enderecoSelecionado = this.enderecoNovo;
    this.enderecoSelecionado = this.enderecoEntrega.copiaObjetoEndereco(this.enderecoNovo);
    this.enderecoEntrega.enderecoSelecionado = this.enderecoEntrega.copiaObjetoEndereco(this.enderecoNovo);
    this.enderecoEntrega.carregaEnderecos(null, null);
    this.ObterClientes();
  }

  public ObterClientes() {

    this.loadingClientes = true;
    this.clientes = null;
    var filtroCliente = new ClienteRequest();
    filtroCliente.cnpj = this.cnpjRec;
    filtroCliente.inscricaoEstadual = this.ieRec;
    filtroCliente.idMunicipio = this.idMunicipio;
    this._sharedService.getClientes(filtroCliente)
      .subscribe(c => {
        this.loadingClientes = false;
        if (c == null) {
          this.Destinatario = null;
          this.clientes = null;
          this.enderecoSelecionado = null;
          this.nomeRec = '';
          this.enderecoEntrega.carregaEnderecos(this.enderecoSelecionado, null);
          return;
        }

        this.clientes = c.filter(x => x.cnpj == filtroCliente.cnpj);
        if (this.clientes && this.clientes.length == 0)
          this.clientes = null;
        this.Destinatario = ((this.clientes != undefined) && (this.clientes.length == 1)) ? this.clientes[0] : null;

        this.enderecoEntrega.carregaEnderecos(this.enderecoSelecionado, ((this.Destinatario) ? this.Destinatario.enderecos : null));
      });
  }

  ieEmbarcadorSelecionada(ie: string){
    this.Destinatario = this.clientes ? this.clientes.find(c => c.inscricaoEstadual == ie) : null;
    this.nomeRec = (this.Destinatario) ? this.Destinatario.nomeFantasia : '';
    var end = this.enderecoEntrega.copiaObjetoEndereco(this.enderecoNovo); //this.enderecoNovo;
    this.enderecoEntrega.carregaEnderecos(end, this.Destinatario.enderecos);
  }

  obtemObservacoes() {
    var retorno = "";
    if (this.agendouTodos) {
      var lista: AgendaEntrega[] = this._groupBy.AgruparPorCampo(this.listaAgendamentos, "observacao");
      lista.forEach(item => {
        if (item && item.observacao)
          retorno += item.observacao + "\n" ;
      });
    }
    this.observacao = retorno;
  }
}
