export class UsuarioPesquisaRequest
{
    cadastradosDe: Date;
    cadastradosAte: Date;
    email: string = '';
    nome: string = '';
    razaoSocial: string = '';
    perfil: string = ''; //string com IDs dos perfils selecionados separados por ";"
    status: string = ''; //string com IDs dos status selecionados separados por ";"
    cnpj: string = '';   //string com CNPJ ou parte do CNPJ a ser pesquisado no CNPJ principal ou nos associados

    constructor() {}
}
