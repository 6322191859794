
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogService } from './../../_services/log.service';
import { LogComponent } from './log.component';
import { SharedModule } from './../../shared/shared.module';
import { LogRoutingModule } from './log.routing.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        SharedModule,
        LogRoutingModule
    ],
    exports: [],
    declarations: [
        LogComponent],
    providers: [ LogService],
})
export class LogModule { }
