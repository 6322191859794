import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MascaraModule } from "app/shared/mascara/mascara.module";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CNPJDropDownModule } from './cnpj-dropdown/cnpj-dropdown.module';
import { EnderecoModule } from './endereco/endereco.module';
import { FiltrosLateraisModule } from '../cliente/filtros-laterais/filtros-laterais.module';
import { DetalhesContainerModule } from './detalhes-container/detalhes-container.module';
import { MultiselectDropdownModule } from './multiselect-dropdown/multiselect-dropdown.module';

import { MessageComponent } from './message/message.component';
import { PaginacaoComponent } from './paginacao/paginacao.component';
import { MensagemModalComponent } from './mensagem-modal/mensagem-modal.component';

import { MascaraDirective } from './../_directives/mascara.directive';
import { KzMaskCurrencyDirective } from "app/_directives/kz-mask-currency.directive";

import { OrderbyPipe } from './../_pipes/orderby.pipe';
import { CountdownPipe } from "app/_pipes/countdown.pipe";

import { PaginacaoService } from './paginacao/paginacao.service';
import { GenericOrderbyService } from "app/_services/generic-orderby.service";

import { SlimScrollModule } from './slimscroll/slimscroll.module';
import { NguiAutoCompleteModule } from './ngui-auto-complete/auto-complete.module';
import { UploadArquivosModule } from './uploadarquivos/uploadarquivos.module';

@NgModule({
    imports: [
        CommonModule,
        MultiselectDropdownModule,
        CNPJDropDownModule,
        UploadArquivosModule,
        NguiAutoCompleteModule,
        MascaraModule,
        EnderecoModule,
        SlimScrollModule
    ],
    exports: [
        TooltipModule,
        CollapseModule,
        PaginacaoComponent,
        MessageComponent,
        MensagemModalComponent,
        MultiselectDropdownModule,
        CNPJDropDownModule,
        EnderecoModule,
        UploadArquivosModule,
        OrderbyPipe,
        FiltrosLateraisModule,
        DetalhesContainerModule,
        CountdownPipe,
        KzMaskCurrencyDirective,
        NguiAutoCompleteModule,
        MascaraDirective,
        SlimScrollModule
    ],
    declarations: [
        PaginacaoComponent,
        MessageComponent,
        OrderbyPipe,
        KzMaskCurrencyDirective,
        CountdownPipe,
        MensagemModalComponent
    ],
    providers: [
        PaginacaoService,
        GenericOrderbyService
    ],
})
export class SharedModule { }
