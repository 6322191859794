import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TaxaEsporadicaComponent } from './taxa-esporadica.component';
import { TaxaEsporadicaDetalheComponent } from './taxa-esporadica-detalhe/taxa-esporadica-detalhe.component';
import { AuthGuard } from 'app/_guards/auth.guard';

const usuariosRoutes: Routes = [
    { path: '', component: TaxaEsporadicaComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe', component: UsuarioDetalheComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe/:id', component: UsuarioDetalheComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(usuariosRoutes)],
    exports: [RouterModule]
})
export class TaxaEsporadicaModuleRoutingModule {}
