
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from './../../shared/shared.module';

import { CaixaEntradaRoutingModule } from './caixa-entrada.routing.module';
import { CaixaEntradaComponent } from './caixa-entrada.component';
import { CaixaEntradaDetalheComponent } from './caixa-entrada-detalhe/caixa-entrada-detalhe.component';
import { CaixaEntradaService } from './../../_services/caixa-entrada.service';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        CaixaEntradaRoutingModule,
        ModalModule.forRoot(),
        SharedModule
    ],
    exports: [],
    declarations: [
        CaixaEntradaComponent,
        CaixaEntradaDetalheComponent
    ],
    providers: [
        CaixaEntradaService,
    ],
})
export class CaixaEntradaModule { }
