import { SentidoRota } from '../_enums/sentido-rota.enum';

export class AnelRota {
    anelRotaID: number; 
    ordem: number;
    sentido: SentidoRota;
    codigoPortoOrigem: number;
    codigoPortoDestino: number;
    descricaoPortoOrigem: string;
    descricaoPortoDestino: string;
    anelID: number;
    novo: boolean;
    ultimo: boolean;
}