import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

import { SharedModule } from 'app/shared/shared.module';
import { VincularNfModule } from './vincular-nf/vincular-nf.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DatePickerModule } from "app/shared/ng2-datepicker/ng2-datepicker.module";
import { MultiselectDropdownModule } from './../shared/multiselect-dropdown/multiselect-dropdown.module';

import { DatepickerService } from 'app/_services/datepicker.service';
import { MessageService } from 'app/_services/message.service';
import { OrdemServicoService } from 'app/_services/ordem-servico.service';

import { FiltroTopComponent } from 'app/provedor/filtro-top/filtro-top.component';
import { FiltroListaComponent } from 'app/provedor/filtro-lista/filtro-lista.component';
import { ListaProvedorComponent } from 'app/provedor/lista-provedor/lista-provedor.component';
import { ItemProvedorComponent } from 'app/provedor/lista-provedor/item-provedor/item-provedor.component';
import { ItemDetalhesProvedorComponent } from 'app/provedor/lista-provedor/item-provedor/item-detalhes-provedor/item-detalhes-provedor.component';
import { FecharOrdemComponent } from 'app/provedor/lista-provedor/fechar-ordem/fechar-ordem.component';
import { OrdenacaoProvedorComponent } from 'app/provedor/ordenacao-provedor/ordenacao-provedor.component';
import { UsuarioService } from 'app/_services/usuario.service';
import { NavioViagemService } from 'app/_services/navio-viagem.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		TextMaskModule,
		DatePickerModule,
		MultiselectDropdownModule,
		ModalModule.forRoot(),
		SharedModule
	],
	declarations: [
		FiltroTopComponent,
		FiltroListaComponent,
		ListaProvedorComponent,
		ItemProvedorComponent,
		ItemDetalhesProvedorComponent,
		OrdenacaoProvedorComponent,
		FecharOrdemComponent
	],
	exports: [
		SharedModule,
		FiltroTopComponent,
		FiltroListaComponent,
		ListaProvedorComponent,
		ItemProvedorComponent,
		ItemDetalhesProvedorComponent,
		OrdenacaoProvedorComponent,
		FecharOrdemComponent
	],
	providers: [
		DatepickerService,
		MessageService,
		OrdemServicoService,
		UsuarioService,
		NavioViagemService
	]
})

export class ProvedorModule { }
