import { Component, Input, QueryList, ViewChild, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment/moment';
import 'moment/locale/pt-br';

import { AppService } from 'app/_services/app.service';

import { DoctoFatura } from '../../../../_models/doctoFatura';
import { DoctoFaturaArquivo } from "app/_models/doctoFaturaArquivo";
import { DownloadDocumentacaoComponent } from "app/cliente/documentacao/lista-documentacao/item-documentacao/download-documentacao/download-documentacao.component";
import { DocumentacaoService } from 'app/_services/documentacao.service';
import { BaseService } from 'app/_services/base.service';
import { MessageService } from 'app/_services/message.service';

@Component({
  selector: 'item-documentacao',
  templateUrl: './item-documentacao.component.html',
  styleUrls: ['./item-documentacao.component.scss']
})
export class ItemDocumentacaoComponent {

  linksCertificados: string[];
  loadingCertificados: boolean = false;
  @Input() index: number;
  @Input() doctoFatura: DoctoFatura;

  @Output() enviarArquivoEmail: EventEmitter<DoctoFaturaArquivo> = new EventEmitter<DoctoFaturaArquivo>();
  @Output() enviarTodosEmail: EventEmitter<DoctoFatura> = new EventEmitter<DoctoFatura>();
  @Output() downloadArquivo: EventEmitter<DoctoFaturaArquivo> = new EventEmitter<DoctoFaturaArquivo>();
  @Output() downloadTodosArquivos: EventEmitter<DoctoFatura> = new EventEmitter<DoctoFatura>();
  @Output() clickBuscarArquivos: EventEmitter<DoctoFatura> = new EventEmitter<DoctoFatura>();

  @ViewChild(DownloadDocumentacaoComponent, {static: true}) menusDownloads: QueryList<DownloadDocumentacaoComponent>;

  constructor(protected _appService: AppService,
     private _documentacaoService: DocumentacaoService,
     private _baseService: BaseService,
     private _msgService: MessageService) {  }

  getFormato(): string {
    return (this._appService.configInfo.formatoDataSQLServer != undefined && this._appService.configInfo.formatoDataSQLServer != '') ?
              this._appService.configInfo.formatoDataSQLServer :
              "MM/DD/YYYY hh:mm:ss a";  //produção
              //"DD/MM/YYYY hh:mm:ss a"; //homologação
  }

  clickEnviarArquivoEmail(arquivo: DoctoFaturaArquivo) {
    this.enviarArquivoEmail.emit(arquivo);
  }

  clickEnviarTodosEmail(doctoFatura: DoctoFatura){
    this.enviarTodosEmail.emit(doctoFatura);
  }

  clickDownloadArquivo(arquivo: DoctoFaturaArquivo) {
    this.downloadArquivo.emit(arquivo);
  }

  clickDownloadTodosArquivos(doctoFatura: DoctoFatura){
    this.downloadTodosArquivos.emit(doctoFatura);
  }

  buscarArquivos(doctoFatura: DoctoFatura){
    this.clickBuscarArquivos.emit(doctoFatura);
  }

  buscarLinksCertificados(){
   this.loadingCertificados = true;
   this.linksCertificados = this.doctoFatura.idViagreen
   .split(";")
   .filter(x => x != "")
   .map(x => {
     return this._appService.configViaGreen.apiUrlDownloadCertificado + "/" + x + "?accessToken=" + this._appService.configViaGreen.token;
   });
   this.loadingCertificados = false;
  }

  get classeDataVencimento(){
    return this.IsDocumentoVencido() ? "vencido" : "a-vencer";
  }

  downloadCertificados(){
    if(this.linksCertificados.length > 1)
      this._baseService.downloadAllAndZip(this.linksCertificados,
        this.linksCertificados.map((x, index) => "certificado-co2-" + index + ".pdf"), "certificados.zip")
    else{
      this.BaixarCertificado();
    }
  }

  private BaixarCertificado() {
      this._baseService.downloadFileByUrl(this.linksCertificados[0], "certificado-co2.pdf").subscribe(() => {return}, () =>
      this._msgService.addMessage("Documentação", "Não foi possível baixar o certficado! Tente novamente mais tarde.", "error"));
  }

  get dataEmissao(): string {
    return (this.doctoFatura == undefined) ? undefined :
      this.formatarData(this.doctoFatura.dtEmissFat);
  }

  get dataVencimento(): string {
    return (this.doctoFatura == undefined || !this.doctoFatura.dtVenctoFat) ? 'Pendente' : this.formatarData(this.doctoFatura.dtVenctoFat);
  }

  private formatarData(dt: string): string {
    var formato = this.getFormato();

    var data = moment(dt, formato).toDate();
    //var data = moment(dt, "MM/DD/YYYY hh:mm:ss a").toDate(); //produção
    //var data = moment(dt, "DD/MM/YYYY hh:mm:ss a").toDate(); //homologação
    //console.log(data);
    return (data == undefined) ? undefined :
      moment(data).format("DD MMM, YYYY");
  }

  private IsDocumentoVencido(){
    if ((this.doctoFatura == undefined) || (this.doctoFatura.dtVenctoFat == undefined))
      return false;

    //Compara duas datas desprezando o horário
    var hoje = new Date();
    var formato = this.getFormato();
    var dtVencimento = moment(this.doctoFatura.dtVenctoFat, formato).toDate();
    //var dtVencimento = moment(this.doctoFatura.dtVenctoFat, "MM/DD/YYYY hh:mm:ss a").toDate();
    hoje.setHours(0,0,0,0);
    dtVencimento.setHours(23,59,0,0);
    return hoje.getTime() >= dtVencimento.getTime();
  }

}
