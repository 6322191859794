import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";

export class UsuarioPesquisa
{
    cadastradosDe: DateModel;
    cadastradosAte: DateModel;
    email: string = '';
    nome: string = '';
    razaoSocial: string = '';
    perfil: string = ''; //string com IDs dos perfils selecionados separados por ";"
    status: string = ''; //string com IDs dos status selecionados separados por ";"
    cnpj: string = '';   //string com CNPJ ou parte do CNPJ a ser pesquisado no CNPJ principal ou nos associados
}
