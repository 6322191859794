import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AnelRotaComponent } from './anel-rota.component';
import { AnelRotaDetalheComponent } from './anel-rota-detalhe/anel-rota-detalhe.component';
import { AuthGuard } from 'app/_guards/auth.guard';

const anelrotaRoutes: Routes = [
    { path: '', component: AnelRotaComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe', component: AnelRotaDetalheComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe/:id', component: AnelRotaDetalheComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(anelrotaRoutes)],
    exports: [RouterModule]
})
export class AnelRotaRoutingModule {}