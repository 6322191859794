import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { CustomerInfoImportComponent } from './customer-info-import.component';
import { AuthGuard } from 'app/_guards/auth.guard';

const usuariosRoutes: Routes = [
    { path: '', component: CustomerInfoImportComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(usuariosRoutes)],
    exports: [RouterModule]
})
export class CustomerInfoImportModuleRoutingModule {}
