import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GenericOrderbyService {

  constructor() { }  
    // Parâmetro 'campo' deve ser uma única string com o mesmo nome da propriedade da classe listada.
    // Exemplo utilizado (Meus Bookings)
    ordenarPorCampo(array: any[], campo: string, descending: boolean = false): any[] {
        var x = (descending) ? -1 : 1;

        if (!array || !campo) return array;

        array.sort((a, b) => {
        
            var campoA = (a[campo] == null) ? "" : a[campo];
            var campoB = (b[campo] == null) ? "" : b[campo];
            if (campoA < campoB) {
                return -1 * x;
            } else if (campoA > campoB) {
                return 1 * x;
            } else {
                return 0;
            }
    });

    return array; 
    }
}
