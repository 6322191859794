import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PortoComponent } from './porto.component';
import { PortoDetalheComponent } from './porto-detalhe/porto-detalhe.component';
import { AuthGuard } from 'app/_guards/auth.guard';

const portoRoutes: Routes = [
    { path: '', component: PortoComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe', component: UsuarioDetalheComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe/:id', component: UsuarioDetalheComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(portoRoutes)],
    exports: [RouterModule]
})
export class PortoRoutingModule {}
