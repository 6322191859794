import { Endereco } from 'app/_models/endereco';
import { DateModel } from 'app/shared/ng2-datepicker/ng2-datepicker.component';
import { Injectable } from "@angular/core";
@Injectable({
    providedIn: 'root'
})
export class AgendaEntrega {
    idBooking: number;
    idUsuario: number;
    idAgendamento: number;
    idAgendContainer: number;
    idMunicipioEnt: number;
    tamanhoContainer: number;
    numAgendamento: number;
    numNotaFiscal: string;
    numLacre1: string;
    numContainer: string;
    numLacre2: string;
    numLacre3: string;
    numRefCliente: string;
    dataEntrega: Date;
    horaEntrega: string;
    observacao: string;
    cnpjEmb: string;
    ieEmb: string;
    nomeEmb: string;
    logradouroEnt: string;
    numeroEndEnt: string;
    bairroEnt: string;
    complementoEnt: string;
    municipioEnt: string;
    ufEnt: string;
    contatoEnt: string;
    dddEnt: string;
    foneEnt: string;
    cepEnt: string;
    categoriaContainer: string;
    statusAgendamento: string;
    valMercadoria: string;
    peso: string;
    dataCompleta: DateModel;
    idPorto: number;
    dataSaidaPortoDestino: Date;
}
