import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from "ngx-bootstrap/modal";

import { Booking } from 'app/_models/booking';
import { BookingRequest } from './../../../../_models/booking-request';
import { SharedModule } from './../../../../shared/shared.module';

import { DatePickerModule } from 'app/shared/ng2-datepicker/ng2-datepicker.module';
import { Booking2Component } from './booking2.component';
import { MultiselectDropdownModule } from './../../../../shared/multiselect-dropdown/multiselect-dropdown.module';
import { BookingService } from './../../../../_services/booking.service';
import { PropostaComercialService } from './../../../../_services/proposta-comercial.service';
import { PropostaComercial } from './../../../../_models/proposta-comercial';
import { Step1SelecaoPropostaComponent } from './../../solicitar-booking/step1-selecao-proposta/step1-selecao-proposta.component';
import { Step2DetalhesOperacaoComponent } from './../../solicitar-booking/step2-detalhes-operacao/step2-detalhes-operacao.component';
import { ControleStepsComponent } from './../../solicitar-booking/controle-steps/controle-steps.component';
import { Step3AgendamentoColetaComponent } from './../../solicitar-booking/step3-agendamento-coleta/step3-agendamento-coleta.component';
import { Step4DadosReservaComponent } from './../../solicitar-booking/step4-dados-reserva/step4-dados-reserva.component';
import { ListaAgendamentoComponent } from './../../solicitar-booking/step3-agendamento-coleta/lista-agendamento/lista-agendamento.component';
import { GroupbyService } from "app/_services/groupby.service";
import { DataService } from "app/_services/data.service";
import { ItemAgendamentoModule } from "app/cliente/booking/solicitar-booking/step3-agendamento-coleta/lista-agendamento/item-agendamento/item-agendamento.module";
import { DatepickerService } from "app/_services/datepicker.service";
import { TextMaskModule } from "angular2-text-mask";
import { FiltrosPropostasComerciaisService } from "app/_services/filtros-propostas-comerciais.service";
import { AnalyticsService } from '../../../../_services/analytics.service';
import { Booking2RoutingModule } from './booking2.routing.module';
import { UsuarioService } from 'app/_services/usuario.service';
import { CotacaoService } from 'app/_services/cotacao.service';
import { ParametroService } from 'app/_services/parametro.service';
import { UploadArquivoService } from 'app/_services/upload-arquivo.service';
import { AppService } from 'app/_services/app.service';
import { ProgramacaoNaviosService } from 'app/_services/programacao-navios.service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MascaraModule } from "app/shared/mascara/mascara.module";

import{ Step5InformacaoPagtoComponent } from './../../../booking/solicitar-booking/step5-informacao-pgto/step5-informacao-pgto.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TextMaskModule,
        MultiselectDropdownModule,
        DatePickerModule,
        CurrencyMaskModule,
        SharedModule,
        ItemAgendamentoModule,
        ModalModule.forRoot(),
        Booking2RoutingModule,
        MascaraModule
    ],
    exports: [MultiselectDropdownModule],
    declarations: [
        Booking2Component
    ],
    providers: [
        AppService,
        BookingService,
        PropostaComercialService,
        BookingRequest,
        Booking,
        GroupbyService,
        DataService,
        DatepickerService,
        PropostaComercial,
        FiltrosPropostasComerciaisService,
        AnalyticsService,
        UsuarioService,
        CotacaoService,
        ParametroService,
        UploadArquivoService,
        ProgramacaoNaviosService
    ],
})
export class Booking2Module {

}
