import { AgendaEntrega } from 'app/_models/agenda-entrega';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AgendaEntregaSalvar {
    idBooking: number = 0;
    idUsuario: number = 0;

    Containeres: AgendaEntrega[] = new Array();
}