import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import  swal  from 'sweetalert2';

import { Usuario } from 'app/_models/usuario';
import { AlterarSenha } from './../../_models/alterar-senha';

import { MessageService } from './../../_services/message.service';
import { AuthenticationService } from './../../_services/authentication.service';

import { MessageComponent } from './../../shared/message/message.component';
import { BaseComponent } from './../../shared/base.component';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { SenhaNaoEConfirmacao } from './validator/senha-igual-confirmacao.validator';

declare var removeSwal: any;

@Component({
  selector: 'app-cadastrar-senha',
  templateUrl: './cadastrar-senha.component.html',
  styleUrls: ['./cadastrar-senha.component.scss']
})
export class CadastrarSenhaComponent extends BaseComponent implements OnInit {
  inscricaoParam: Subscription;

  mensagem: string = "";
  loading: boolean = false;

  form: FormGroup
  @ViewChild(MessageComponent, {static: true}) alerta: MessageComponent;

  constructor(private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _msgService: MessageService,
    private _router: Router,
    private _formBuilder: FormBuilder) {

    super();

    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.alerta.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );

  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      ID : [],
      Email : ["",[Validators.required,Validators.email]],
      Senha : ["",[Validators.required,Validators.minLength(8),Validators.pattern("[^\w\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))")]],
      ConfirmaSenha : ["",[Validators.required]],
    }, {validator:SenhaNaoEConfirmacao});

    this.loading = true;
    this.inscricaoParam = this._route.params.subscribe(
      (params: any) => {
        var id = params['id']
        this.form.controls.ID.setValue(id);
        var cadastro = new AlterarSenha();
        cadastro.ID = id;
        cadastro.Email = "";
        this._authenticationService.ObterEmail(cadastro)
        .subscribe(result => {
          this.loading = false;
          if (result.IsValid) {
            var user = result.Data as Usuario;
            this.form.controls.Email.setValue(user.email);
          }
          else
          this.form.controls.Email.setValue("");
        });
      }


    );
  }

  Enviar() {
    this.loading = true;
    this._msgService.clearMessage();
    var alterarSenha  = this.form.getRawValue() as AlterarSenha;
    this._authenticationService.CadastrarSenha(alterarSenha)
      .subscribe(result => {
        this.loading = false;
        if (result.IsValid) {
          removeSwal();
          this._msgService.clearMessage();
          swal({
                title: 'Bem Vindo ao Portal Cabotagem',
                html: 'Senha cadastrada com sucesso!<br/><br/>Clique <a href="https://www.youtube.com/playlist?list=PL0gWHLiurxFh7Te77PnF5EuqhI-BM6pjJ" target="_blank">aqui</a> para assistir os tutoriais e conhecer melhor o Portal Cabotagem',
                type: 'success',
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: 'OK'
              }).then(success => {
                  this._router.navigate(['/home']);
              }, dismiss => {
                // console.log(dismiss);
              });

          // this._msgService.addMessageRedirect('Usuario', result.Message, "success");
          // this._router.navigate(['/home']);
        }
        else {
          var err: string[] = [];
          result.Erros.forEach(e => err.push(e.Message));
          this._msgService.addMessageArray('CadastroSenha', err, 'danger', LogFrontFuncionalidade.ResetSenha, Operacao.Consulta, alterarSenha);
          //result.Erros.forEach(element => this._msgService.addMessage('Usuario', element.Message, "error"));
        }
      });
  }

}
