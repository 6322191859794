
import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';


import { BaseService } from './base.service';
import { Parametro } from './../_models/parametro';
import { Porto } from './../_models/porto';
import { Anel } from './../_models/anel';
import { TipoServico } from './../_models/tipo-servico';
import { Navio } from './../_models/navio';
import { ValidationResult } from './../_models/validation-result';
import { ParametroPorto } from 'app/_models/parametro-porto';
import { PaginaParametroPorto } from 'app/_models/pagina-parametro-porto';

@Injectable({
    providedIn: 'root'
})
export class ParametroService {

    constructor(private baseService: BaseService) { }

    // ---- Parametros de quantidade de horas ------
    salvarParametro(parametros: Parametro[]): Observable<ValidationResult> {
        return this.baseService.Put('parametro/', parametros).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getParametros(): Observable<Parametro[]> {
        return this.baseService.Get('parametro').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Parametro[];
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getParametro(id: number): Observable<Parametro> {
        return this.baseService.Get('parametro/' + id).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Parametro;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    obtemParametroPorCodigo(codigo: string): Observable<Parametro> {
        return this.baseService.Get('parametro/codigo?codigo=' + codigo).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Parametro;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    // ---- Parametro Porto -------------------------------------------------
    carregarPagina(): Observable<PaginaParametroPorto> {
        return this.baseService.Get('parametro/porto/pagina').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as PaginaParametroPorto;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    obterParametrosPortoPadrao(): Observable<ParametroPorto> {
        return this.baseService.Get('parametro/porto/padrao').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as ParametroPorto;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    obterParametrosPorto(portoId: number): Observable<ParametroPorto> {
        return this.baseService.Get('parametro/porto/' + portoId).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as ParametroPorto;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    obterParametros(pagina: PaginaParametroPorto, idPorto: number): ParametroPorto {
        if (!pagina)
            return null;
        var parametroPadrao = pagina.parametroPortoPadrao;
        var parametroExcecao = pagina.parametroPortoExcecoes.find(p => p.portoID == idPorto);
        if (!parametroExcecao)
            return parametroPadrao;

        var parametro = new ParametroPorto();
        parametro.portoID = idPorto;
        parametro.nomePorto = parametroExcecao.nomePorto;
        parametro.qtdHrEntrega = this.diferenteDeZero(parametroExcecao.qtdHrEntrega, parametroPadrao.qtdHrEntrega);
        parametro.qtdHrAgendEntrega = this.diferenteDeZero(parametroExcecao.qtdHrAgendEntrega, parametroPadrao.qtdHrAgendEntrega);
        parametro.qtdHrAgendColetaPA = this.diferenteDeZero(parametroExcecao.qtdHrAgendColetaPA, parametroPadrao.qtdHrAgendColetaPA);
        parametro.qtdHrAgendColetaPO = this.diferenteDeZero(parametroExcecao.qtdHrAgendColetaPO, parametroPadrao.qtdHrAgendColetaPO);
        parametro.qtdHrMinimasAgendColeta = this.diferenteDeZero(parametroExcecao.qtdHrMinimasAgendColeta, parametroPadrao.qtdHrMinimasAgendColeta);
        parametro.qtdHrMinimasAgendEntrega = this.diferenteDeZero(parametroExcecao.qtdHrMinimasAgendEntrega, parametroPadrao.qtdHrMinimasAgendEntrega);

        return parametro;
    }

    private diferenteDeZero(v1: number, v2: number): number {
        return (v1 != 0) ? v1 : v2;
    }

    salvarParametrosPorto(parametroPorto: ParametroPorto): Observable<ValidationResult> {
        return this.baseService.Post('parametro/porto', parametroPorto).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    atualizarParametrosPorto(parametroPorto: ParametroPorto): Observable<ValidationResult> {
        return this.baseService.Put('parametro/porto', parametroPorto).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    apagarParametrosPorto(portoId: number): Observable<ValidationResult> {
        return this.baseService.Delete('parametro/porto/' + portoId).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    // -------------------------------------------------------------

    // ---- Navios -------------------------------------------------
    salvarNavio(navios: Navio[]): Observable<ValidationResult> {
        return this.baseService.Put('navio', navios).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getNaviosAdmin(): Observable<Navio[]> {
        return this.baseService.Get('navio/adm').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Navio[];
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }
    // -------------------------------------------------------------

    // ---- Anéis / Rotas ------------------------------------------
    getAneis(): Observable<Anel[]> {
        return this.baseService.Get('anel').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Anel[];
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    salvarAnel(anel: Anel): Observable<ValidationResult> {
        if (!anel.anelID || anel.anelID == 0)
            return this.baseService.Post('anel', anel).pipe(
                map((response: ValidationResult) => {
                    let retorno: ValidationResult = response;
                    return retorno;
                }),catchError(e => {
                    return this.baseService.handleError(e);
                }),);
        else
            return this.baseService.Put('anel/' + anel.anelID, anel).pipe(
                map((response: ValidationResult) => {
                    let retorno: ValidationResult = response;
                    return retorno;
                }),catchError(e => {
                    return this.baseService.handleError(e);
                }),);
    }

    deletarAnel(id: number): Observable<ValidationResult> {
        return this.baseService.Delete('anel/' + id).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }
    // -------------------------------------------------------------

    // ---- Tipos de Serviços --------------------------------------
    salvarTipoServico(tipoServicos: TipoServico[]): Observable<ValidationResult> {
        return this.baseService.Put('tiposervico', tipoServicos).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getTipoServicosAdmin(filtro: string): Observable<TipoServico[]> {
        return this.baseService.Get('tiposervico/adm/' + filtro).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as TipoServico[];
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }
    // -------------------------------------------------------------

}
