import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './../_services/authentication.service';
import { AppService } from './../_services/app.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private appService: AppService) { }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        let path: string = state.url.replace('/', '');
        path = path.split('/')[0];
        if (this.appService.isAuthenticated() && this.appService.hasPermission(path)) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page
        this.router.navigate(['']);
        this.authenticationService.showModal();

        return false;
    }
}