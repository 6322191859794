import { NgIf } from '@angular/common';
import { TipoContainer } from './../../../../_models/tipo-container';
import { Anel } from 'app/_models/anel';
import { Subscription } from 'rxjs';
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, NgModule } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MessageService } from '../../../../_services/message.service';
import { MessageComponent } from '../../../../shared/message/message.component';
import { DomSanitizer } from '@angular/platform-browser';

import { Booking } from 'app/_models/booking';
import { Cliente } from "app/_models/cliente";

import { AppService } from 'app/_services/app.service';
import { SharedService } from '../../../../_services/shared.service';

import swal from 'sweetalert2';
import { BaseComponent } from '../../../../shared/base.component';
import { EnderecoComponent } from 'app/shared/endereco/endereco.component';
import { Feriado } from 'app/_models/feriado';



//Remmen Cardoso
import { ListItem } from '../../../../_models/listItem';//Remmen Cardoso
import { DataService } from './../../../../_services/data.service';
import { ClienteClassificacao } from 'app/_models/cliente-classificacao';
import { TipoPagamento } from 'app/_models/tipo-pagamento';
import { TipoServico } from 'app/_models/tipo-servico';
import { InfoPgtoAdicional } from '../../../../_models/InfoPgtoAdicional';
import { AnelRotaService } from '../../../../_services/anel-rota.service';
import { InfoPgtoAdicionalService } from '../../../../_services/info-pgto-adicional.service';
import { GenericOrderbyService } from './../../../../_services/generic-orderby.service';
import { PropostaComercialFiltro } from './../../../../_models/proposta-comercial-filtro';
import { PropostaComercial } from './../../../../_models/proposta-comercial';


import { Alert } from 'selenium-webdriver';
import { Console } from 'console';
import { Parser } from '@angular/compiler';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import "jspdf/dist/polyfills.es.js";
import { FALSE } from 'sass';
import { BookingShippingService } from 'app/_services/booking-shipping.service';
import { BookingShippingRequest } from 'app/_models/booking-shipping-request';
import * as moment from 'moment/moment';
import 'moment/locale/pt-br';
import { DateModel } from 'app/shared/ng2-datepicker/ng2-datepicker.component';
import { DatepickerService } from 'app/_services/datepicker.service';
import { DateExtensions } from 'app/shared/extensions/extensions.module';
import { SporadicTaxItem } from 'app/_models/SporadicTaxItem';
import { BookingShippingResponse } from 'app/_models/booking-shipping-response';

import { forEach } from 'jszip';
import { ImpresaoPixPdf } from '../../relatorios/ImpresaoPixPdf';
import { IDateModel } from '../../../../shared/ng2-datepicker/ng2-datepicker.component';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { Operacao } from 'app/_enums/operacao';
import { BaseService } from 'app/_services/base.service';


@Component({
  selector: 'step5-informacao-pgto',
  templateUrl: './step5-informacao-pgto.component.html',
  styleUrls: ['./step5-informacao-pgto.component.scss'],
})

export class Step5InformacaoPagtoComponent extends BaseComponent implements OnInit {

  @Input() novoBooking: boolean;
  @Input() edicaoBooking: boolean;
  @Input() booking: Booking;
  @Input() duplicar: boolean;
  @Input() loadingProposta: boolean = false;
  @Input() feriados: Feriado[];
  @Input() propostaComercial: PropostaComercial;

  @Output() newItemEvent: EventEmitter<boolean> = new EventEmitter<boolean>();


  @ViewChild(EnderecoComponent, { static: true }) endereco: EnderecoComponent;

  cliente: Cliente;
  loadingClientes: boolean = false;


  //Remmen Cardoso
  public loading: boolean = false;
  public isCalculando: boolean = false;
  isInstantPaymentPendente = false;
  isDuplicating: boolean;

  clienteClassificacoes: ListItem[] = new Array();
  tiposPagamentos: ListItem[] = new Array();
  tiposServicos: TipoServico[] = new Array();
  tipoContainers: TipoContainer[] = [];
  tamanhoContainer: string;
  fontSize: number = 12;
  objHeight: number = 0;
  objX: number = 0;
  objY: number = 0;
  objYTemp: number = 0;


  clienteClassificacao: ClienteClassificacao = new ClienteClassificacao();
  tipoPagamento: TipoPagamento = new TipoPagamento();
  tipoServico: TipoServico = new TipoServico();
  txtQrCode: string = '';
  imgAliancaHeader: string = './../../../../../assets/img/Alianca-Logo.jpeg';
  imgFundoCinza: string = './../../../../../assets/img/alianca-fundo-cinza.jpeg';
  imgPix: string = './../../../../../assets/img/pix.png';
  imagePixBytes: any;

  infoPgtoAdicional: InfoPgtoAdicional = new InfoPgtoAdicional();
  propostaFiltro: PropostaComercialFiltro = new PropostaComercialFiltro();
  propostasFiltradas: PropostaComercial[] = [];
  todosTipoContainers: TipoContainer[] = [];



  descTipoServico: string = '';
  descClassificacaoCliente: string = '';
  descTipoPagamento: string = '';
  vlrTotalDeposito: number = 0;
  public isPgtoGravadoNaBase: boolean = false;
  vlrTotalNfTmp: number = 0;
  taxasAdicionaisText: string = '';


  //Dados Para Deposito
  cnpj: string = '02.427.026/0001-46';
  bancoNome: string = 'JP MORGAN';
  bancoNumero: string = '376';
  agencia: string = '0001';
  contaDeposito: string = '104447-6';

  localOrigem: string = '';
  localDestino: string = '';
  rotuloOrigem: string = '';
  rotuloDestino: string = '';
  rotuloData: string = '';
  ufPagador: string = '';
  isHabilitaBotaoCalcular: boolean = false;
  qtdeContainer: number = 0;
  dataBaseCalculo: string;



  constructor(
    private _baseService: BaseService,
    private _sharedService: SharedService,
    private _appService: AppService,
    private _sanitizer: DomSanitizer,
    private _dataService: DataService,
    private _msgService: MessageService,
    private _datepickerService: DatepickerService,
    private _infoPgtoAdicionalService: InfoPgtoAdicionalService,
    private _genericOrderbyService: GenericOrderbyService,
    private impressaoPixPdf: ImpresaoPixPdf,
    private _bookingShippingService: BookingShippingService) {
    super();
  }

  ngOnInit(): void {
    this.ObterDataFields();
    //this.AjustarValores();

    //this.onLoadInfoPgtoAdicional(this.booking.numBooking);

  }

  autocompleListFormatter = (data: any) => {
    let html = `<span>${data.Descricao}/${data.UF}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
    //return html;
  }



  //Remmen Cardoso
  AjustarValores() {
    this.infoPgtoAdicional.descIndicador = this.txtQrCode;

    //Frete Negociado
    this.infoPgtoAdicional.vlrFreteNegociado = this.booking.proposta.valFreteOTM;
    this.tamanhoContainer = this.booking.proposta.tipoDeContainer.descricao;

    if (this.booking.proposta.adValorem) {
      this.infoPgtoAdicional.percAdvalorem = 0;
    }
    else {
      this.infoPgtoAdicional.percAdvalorem = this.booking.proposta.adValorem;
    }
  }

  ObterDataFields() {
    this._dataService.getClienteClassificacao()
      .subscribe(lista => {
        this.clienteClassificacoes = lista;
      });

    this._dataService.getTipoPagamento()
      .subscribe(lista => {
        this.tiposPagamentos = lista;
      });

    this._dataService.getTipoServico()
      .subscribe(lista => {
        this.tiposServicos = lista;
      });

  }

  propostaAlterada(proposta: PropostaComercial) {
    if (!proposta)
      return;

    this.propostaComercial = proposta;

  }

  setTipoPagamento() {
    this.tipoPagamento = this.tiposPagamentos[this.infoPgtoAdicional.idTipoPagto];
  }

  setClassificacaoCliente() {
    this.clienteClassificacao = this.clienteClassificacoes[this.infoPgtoAdicional.idClassificacaoCli];
  }

  setTipoServico() {
    this.tipoServico = this.tiposServicos[this.infoPgtoAdicional.idTipoServico];
  }


  atualizaStatus(infoService: InfoPgtoAdicionalService, numBooking: string) {
    const onSaveButtonBTN = document.getElementById('onSaveButtonBTN') as HTMLButtonElement;
    onSaveButtonBTN.style.width = '350px';

    var timeleft = 120;
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);

        if (onSaveButtonBTN.textContent != 'Transação PIX confirmada.')
          onSaveButtonBTN.textContent = 'Tempo expirado.';

      } else {

        timeleft--;

        onSaveButtonBTN.textContent = 'TEMPO REST CONF. PIX (' + timeleft + ')';

        if ((timeleft % 5) === 0) {
          infoService.getInfoPgtoAdicionalByNumBooking(numBooking)
            .subscribe(result => {

              if (result.paymentStatus === 'COMPLETED') {
                onSaveButtonBTN.textContent = 'Transação PIX confirmada.';
                timeleft = 0;
              }
              else {
                console.log('Consultando status.');
              }
            });
        }

      }

    }, 1000);

  }


  public onLoadInfoPgtoAdicional(numBooking: string) {
    if (this.booking.modal.startsWith('PO')) {
      this.localOrigem = this.booking.proposta?.nomePortoOrigem + '/' + this.booking.proposta?.ufPortoOrigem;
      this.rotuloOrigem = 'PORTO DE ORIGEM';
      this.rotuloData = 'DIA DDL DOCUMENTAÇÃO';
    }
    else {
      this.localOrigem = this.booking.proposta?.municOrigem + '/' + this.booking.proposta?.ufOrigem;
      this.rotuloOrigem = 'CIDADE DE ORIGEM';
      this.rotuloData = 'DATA DA COLETA';
    }


    if (this.booking.modal.endsWith('PO')) {
      this.localDestino = this.booking.proposta.nomePortoDestino + '/' + this.booking.proposta.ufPortoDestino;
      this.rotuloDestino = 'PORTO DE DESTINO';
    }
    else {
      this.localDestino = this.booking.proposta.municDestino + '/' + this.booking.ufDes;
      this.rotuloDestino = 'CIDADE DE DESTINO';
    }

    const modal = this.booking.modal.split("/");
    this.infoPgtoAdicional.origemModalId = modal[0];
    this.infoPgtoAdicional.destinoModalId = modal[1];
    this.qtdeContainer = this.booking.qtdeContainer;

    this.ObterDataFields();    


    if (numBooking == undefined || numBooking.trim().length == 0)
      return;



    this._infoPgtoAdicionalService.getInfoPgtoAdicionalByNumBooking(numBooking)
      .subscribe(infoPgtoAdicional => {

        console.log(infoPgtoAdicional);

        if (infoPgtoAdicional == undefined || infoPgtoAdicional == null ) {
          this.isPgtoGravadoNaBase = false;
          
          infoPgtoAdicional.vlrTotalNota = 0;
          infoPgtoAdicional.percAdvalorem = 0;
          infoPgtoAdicional.vlrAdValorem = 0;
          infoPgtoAdicional.percAliquotaIcms = 0;
          infoPgtoAdicional.vlrFreteNegociado = 0;
          infoPgtoAdicional.vlrBaf = 0;
          infoPgtoAdicional.vlrTaxaAdicional = 0;
          infoPgtoAdicional.vlrIcms = 0;
          infoPgtoAdicional.dataBaseCalculo
        }
        else {
          this.isPgtoGravadoNaBase = true;
          const txtVlrTotalNota = document.getElementById('txtVlrTotalNota') as HTMLButtonElement;
          txtVlrTotalNota.disabled = true;

          const tiposPagamentoCbo = document.getElementById('tiposPagamentoCbo') as HTMLButtonElement;
          tiposPagamentoCbo.disabled = true;

          const classificacaoClienteCbo = document.getElementById('classificacaoClienteCbo') as HTMLButtonElement;
          classificacaoClienteCbo.disabled = true;

          const tiposServicoCbo = document.getElementById('tiposServicoCbo') as HTMLButtonElement;
          tiposServicoCbo.disabled = true;

          const ufPagadorFrete = document.getElementById('ufPagadorFrete') as HTMLButtonElement;
          ufPagadorFrete.disabled = true;


          if (!this.duplicar) {
            infoPgtoAdicional.vlrTotalNota = parseFloat(infoPgtoAdicional.vlrTotalNota.toString().replace(/\./g, "").replace(',', '.'));
            infoPgtoAdicional.percAdvalorem = parseFloat(infoPgtoAdicional.percAdvalorem.toString().replace(/\./g, "").replace(',', '.'));
            infoPgtoAdicional.vlrAdValorem = parseFloat(infoPgtoAdicional.vlrAdValorem.toString().replace(/\./g, "").replace(',', '.'));
            infoPgtoAdicional.percAliquotaIcms = parseFloat(infoPgtoAdicional.percAliquotaIcms.toString().replace(/\./g, "").replace(',', '.'));
            infoPgtoAdicional.vlrFreteNegociado = parseFloat(infoPgtoAdicional.vlrFreteNegociado.toString().replace(/\./g, "").replace(',', '.'));
            infoPgtoAdicional.vlrBaf = parseFloat(infoPgtoAdicional.vlrBaf.toString().replace(/\./g, "").replace(',', '.'));
            infoPgtoAdicional.vlrTaxaAdicional = parseFloat(infoPgtoAdicional.vlrTaxaAdicional.toString().replace(/\./g, "").replace(',', '.'));
            infoPgtoAdicional.vlrIcms = parseFloat(infoPgtoAdicional.vlrIcms.toString().replace(/\./g, "").replace(',', '.'));
            infoPgtoAdicional.vlrFreteTotal = parseFloat(infoPgtoAdicional.vlrFreteTotal.toString().replace(/\./g, "").replace(',', '.'));
            this.dataBaseCalculo = infoPgtoAdicional.dataBaseCalculo;
            this.infoPgtoAdicional.qrCode = infoPgtoAdicional.qrCode;
            this.txtQrCode = infoPgtoAdicional.descIndicador;

          }
          else {
            infoPgtoAdicional.vlrTotalNota = 0;
            infoPgtoAdicional.percAdvalorem = 0;
            infoPgtoAdicional.vlrAdValorem = 0;
            infoPgtoAdicional.percAliquotaIcms = 0;
            infoPgtoAdicional.vlrFreteNegociado = 0;
            infoPgtoAdicional.vlrBaf = 0;
            infoPgtoAdicional.vlrTaxaAdicional = 0;
            infoPgtoAdicional.vlrIcms = 0;
          }


          this.infoPgtoAdicional = infoPgtoAdicional;

          console.log("Infopgto novo", this.infoPgtoAdicional);

          this.setTipoPagamento();
          this.setClassificacaoCliente();
          this.setTipoServico();
          this.isInstantPaymentPendente = false;
          this.newItemEvent.emit(true);
        }
      });
  }


  OnChangeClassificacaoCliente(value: any) {

    this.descClassificacaoCliente = this.getDescricao(value.target.value, this.clienteClassificacoes);

    //this.habilitaBotaoCalcula();

  }


  OnChangeTipoPagamento(value: any) {

    this.descTipoPagamento = this.getDescricao(value.target.value, this.tiposPagamentos);

    //this.habilitaBotaoCalcula();
  }


  OnChangeTipoServico(value: any) {

    this.descTipoServico = this.getDescricao(value.target.value, this.tiposPagamentos);

    //this.habilitaBotaoCalcula();
  }


  getDescricao(valor: any, lista: any): string {
    let descricao: string = '';

    lista.forEach(element => {

      if (element.codigo.toString() === valor) {
        descricao = element.descricao;
      }

    });


    return descricao;
  }


  private ConvertDecimal(valor: any): number {
    if (!valor)
      return valor;

    var num = valor.toString();
    num = this._sharedService.replaceAll(valor, '.', '');
    num = num.replace(',', '.');
    return Number(num);
  }


  private ObterData(dateModel: DateModel, dateOffset: number) {
    return dateModel != undefined
      ? this._datepickerService.ObtemData(dateModel)
      : this._sharedService.addDays(new Date(), dateOffset);
  }

  private GetDataUltimoAgendamento(): Date {
    let dataUltimoAgendamento: Date;

    let arr = this.booking.agendamentosDeColeta;
    let maxDate: Date = new Date();

    for (let i = 1, len = arr.length; i < len; i++) {

      let v = arr[i].dataColeta;

      maxDate = (v > maxDate) ? v : maxDate;
    }

    return maxDate;
  }


  public onSaveInfoPgtoAdicional() {

    const txtVlrTotalNota = document.getElementById('txtVlrTotalNota') as HTMLButtonElement;
    txtVlrTotalNota.disabled = true;

    const tiposPagamentoCbo = document.getElementById('tiposPagamentoCbo') as HTMLButtonElement;
    tiposPagamentoCbo.disabled = true;

    const classificacaoClienteCbo = document.getElementById('classificacaoClienteCbo') as HTMLButtonElement;
    classificacaoClienteCbo.disabled = true;

    const tiposServicoCbo = document.getElementById('tiposServicoCbo') as HTMLButtonElement;
    tiposServicoCbo.disabled = true;

    const ufPagadorFrete = document.getElementById('ufPagadorFrete') as HTMLButtonElement;
    ufPagadorFrete.disabled = true;

    if (this.isPgtoGravadoNaBase == true) {
      this.impressaoPixPdf.printPDF(this.booking, this.infoPgtoAdicional);
    }
    else
    {
      this.limpaVariaveis();
      this.habilitaBotaoCalcula();

      if(!this.isHabilitaBotaoCalcular)
      {
        txtVlrTotalNota.disabled = false;
        tiposPagamentoCbo.disabled = false;
        classificacaoClienteCbo.disabled = false;
        tiposServicoCbo.disabled = false;
        ufPagadorFrete.disabled = false;        
        return;
      }
  
  
      if (!this.validaQuantidade()) {
        txtVlrTotalNota.disabled = false;
        tiposPagamentoCbo.disabled = false;
        classificacaoClienteCbo.disabled = false;
        tiposServicoCbo.disabled = false;
        ufPagadorFrete.disabled = false;           
        return;
      }

      console.log("Caiu na calculadora");

      this.Calculadora();

    }
  }


  private SalvarNaBase(){

      this.infoPgtoAdicional.idProposta = this.booking.proposta.idProposta;
      this.infoPgtoAdicional.idBooking = this.booking.numBooking;
      this.infoPgtoAdicional.customerName = this.booking.proposta.nomeCli;
      this.infoPgtoAdicional.cpfCnpj = this.booking.cnpjCli;
      this.infoPgtoAdicional.qdtConteiner = this.booking.qtdeContainer;
      // this.infoPgtoAdicional.containerType = this.booking.tipoDeContainer.Tamanho;
      this.infoPgtoAdicional.createdBy = this._baseService.UsuarioLogado.nome;
      this.infoPgtoAdicional.cnpjDeposito = this.cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
      this.infoPgtoAdicional.bancoDeposito = this.bancoNumero;
      this.infoPgtoAdicional.agenciaDeposito = this.agencia;
      this.infoPgtoAdicional.contaDeposito = this.contaDeposito.replace('-', '');
      this.infoPgtoAdicional.dataBaseCalculo = this.dataBaseCalculo;

      this.infoPgtoAdicional.updatedBy = '';

      

    this.loading = true;
    this._infoPgtoAdicionalService.SalvarInfoPgtoAdicional(this.infoPgtoAdicional)
      .subscribe(result => {

        this.impressaoPixPdf.printPDF(this.booking, this.infoPgtoAdicional);        
        this.isPgtoGravadoNaBase = true;
        this.atualizaStatus(this._infoPgtoAdicionalService, this.infoPgtoAdicional.idBooking);        
        this.renderizaImagePIX();
        

        
        
        if (result.IsValid) {
          this._msgService.addMessageRedirect('Info Pgto Adicional', result.Message, "success");
        }
        else {
          var msgErr: string[] = [];
          result.Erros.forEach(element => msgErr.push(element.Message));
          //this.isPgtoGravadoNaBase = false;
        }
      });
  }

  validaQuantidade(): boolean {

    if (this.qtdeContainer != this.booking.qtdeContainer) {
      var mensagens: string[] = [];


      mensagens.push('A quantidade de containers não pode ser alterada. Por favor, corrija-a para: ' + this.qtdeContainer);


      this._msgService.addMessageArray('Info - PIX ', mensagens, 'error', LogFrontFuncionalidade.Booking, Operacao.Consulta);

      return false;

    }


    return true;
  }



  Calculadora() {
    
    if (!this.validaQuantidade()) {
      return;
    }

    this.isCalculando = true;

    const formato = "YYYY-MM-dd hh:mm:ss";
    let bookingShippingRequest: BookingShippingRequest = new BookingShippingRequest;
    let dataTmp: Date;

    // Limpar variáveis
    this.limpaVariaveis();

    bookingShippingRequest.paymentType = this.infoPgtoAdicional.idTipoPagto.toString();
    bookingShippingRequest.classification = this.infoPgtoAdicional.idClassificacaoCli.toString();
    bookingShippingRequest.serviceType = this.infoPgtoAdicional.idTipoServico.toString();
    bookingShippingRequest.departureUf = this.booking.proposta.ufPortoOrigem;
    bookingShippingRequest.destinationUf = this.booking.proposta.ufPortoDestino;
    bookingShippingRequest.numBooking = this.booking.numBooking;

    const modal = this.booking.modal.split("/");
    bookingShippingRequest.originModalId = modal[0];
    bookingShippingRequest.destinationModalId = modal[1];

    if (this.propostaComercial.adValorem == null)
      bookingShippingRequest.adValoremRate = 0;
    else
      bookingShippingRequest.adValoremRate = parseFloat(this.propostaComercial.adValorem.toString().replace(/\./g, "").replace(',', '.'));

    bookingShippingRequest.containersQuantity = this.booking.qtdeContainer;
    bookingShippingRequest.equipmentSize = this.booking.tamanhoContainer;
    bookingShippingRequest.negotiatedShipping = this.propostaComercial.valFreteOTM;
    bookingShippingRequest.nfTotalAmount = this.infoPgtoAdicional.vlrTotalNota;
    bookingShippingRequest.originPortId = this.booking.idPortoOri;
    bookingShippingRequest.destinationPortId = this.booking.idPortoDes;

    console.log(this.dataBaseCalculo);


    let _dataBaseCalculo = this.dataBaseCalculo.split("/");
    let ano = _dataBaseCalculo[2];
    let mes = _dataBaseCalculo[1];
    let dia = _dataBaseCalculo[0];

    bookingShippingRequest.cteIssueDate = ano + '-' + mes + '-' + dia;
    bookingShippingRequest.shipArrivalDate = ano + '-' + mes + '-' + dia;



    this._bookingShippingService.calculate(bookingShippingRequest)
      .subscribe(response => {
        this.isCalculando = false;

        let resposta: BookingShippingRequest = response.Data;

        this.infoPgtoAdicional.vlrFreteNegociado = parseFloat(resposta.totalShipping.toString().replace(/\./g, "").replace(',', '.'));
        this.infoPgtoAdicional.vlrIcms = parseFloat(resposta.icms.toString().replace(/\./g, "").replace(',', '.'));
        this.infoPgtoAdicional.percAliquotaIcms = parseFloat(resposta.icmsRate.toString().replace(/\./g, "").replace(',', '.'));
        this.infoPgtoAdicional.vlrAdValorem = parseFloat(resposta.adValorem.toString().replace(/\./g, "").replace(',', '.'));
        this.infoPgtoAdicional.vlrFreteTotal = parseFloat(resposta.totalPaymentAmount.toString().replace(/\./g, "").replace(',', '.'));

        if (resposta.additionalTaxes == null)
          resposta.additionalTaxes = 0;

        this.infoPgtoAdicional.vlrTaxaAdicional = parseFloat(resposta.additionalTaxes.toString().replace(/\./g, "").replace(',', '.'));
        this.infoPgtoAdicional.descIndicador = `${resposta.textoPix}`;
        this.infoPgtoAdicional.vlrBaf = parseFloat(resposta.bafTotalValue.toString().replace(/\./g, "").replace(',', '.'));
        this.txtQrCode = resposta.textoPix;
        this.infoPgtoAdicional.qrCode = resposta.qrCode;
        this.infoPgtoAdicional.customerName = this.booking.proposta.nomeCli;
        this.infoPgtoAdicional.cpfCnpj =  this.booking.proposta.cnpjCli;


        let vlrTotalTaxasAdicionais: number = 0;
        let vlrTotalBunker: number = 0;

        resposta.sporadicTaxList.forEach(stl => {

          if (this.taxasAdicionaisText.length > 0) {
            this.taxasAdicionaisText = this.taxasAdicionaisText + ','
          }


          this.taxasAdicionaisText = this.taxasAdicionaisText + stl.descricao + ', \n';

          if (stl.isBunker) {
            vlrTotalBunker = stl.valor;
          }
          else {
            vlrTotalTaxasAdicionais += stl.valor;
          }
        });

        this.SalvarNaBase();
        //calculadoraBTN.disabled = false;
        //onSaveButtonBTN.disabled = false;
      });
  }


  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  copyText() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.txtQrCode;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  displayBox(): void {
    let element: any = document.getElementById('divInfoPpgtoAdicional')


    let DATA: any = document.getElementById('divInfoPpgtoAdicional');


    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let top = 10;
      let position = 30;
      PDF.addImage(FILEURI, 'PNG', top, position, fileWidth, fileHeight);
      PDF.save('angular-demo.pdf');
    });

  }

  closeBox(): void {
    var element = document.getElementById('impressao')
    element = document.querySelector('.container');
    element.style.visibility = 'hidden';
  }


  // Busca produtos das propostas filtradas
  private obterTiposContainers(): void {
    //TODO: Adicionar "(Vazio)" para quando vier proposta com tipo de contêiner null
    this.tipoContainers = [];

    this.todosTipoContainers.forEach(tp => {
      var prop = this.propostasFiltradas.find(p => p.tipoDeContainer.idContainer == tp.idContainer);
      if (prop != null)
        this.tipoContainers.push(tp);
    });

    this.tipoContainers = this._genericOrderbyService.ordenarPorCampo(this.tipoContainers, 'Descricao');

    if (this.tipoContainers.length == 1)
      this.propostaFiltro.tipoDeContainer = this.tipoContainers[0];
  }


  renderizaImagePIX() {
    // Convert the byte string to a Uint8Array
    const byteCharacters = atob(this.infoPgtoAdicional.descIndicador);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the Uint8Array
    const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Adjust the type as needed

    // Create a URL for the Blob
    const imageUrl = URL.createObjectURL(blob);

    // Render the image in your HTML
    const imgElement = document.createElement('img');
    imgElement.src = imageUrl;
    this.imgPix = imageUrl;

    // Append the image element to the DOM (e.g., inside a div)
    //document.getElementById('imagemPix').appendChild(imgElement);
  }



  habilitaBotaoCalcula() {

    var mensagens: string[] = [];

    let isTipoPagamentoSelecionado = this.infoPgtoAdicional.idTipoPagto > 0;
    let isClassificacaoSelecionada = this.infoPgtoAdicional.idClassificacaoCli > 0;
    let isTipoServicoSelecionado = this.infoPgtoAdicional.idTipoServico > 0;
    let isUfPagadorInformada = this.infoPgtoAdicional.ufPagadorFrete.trim().length > 0;
    let isUfPgadorValido = !((this.infoPgtoAdicional.origemModalId == 'PA' && this.infoPgtoAdicional.idTipoPagto == 2) && this.booking.proposta.ufOrigem == this.infoPgtoAdicional.ufPagadorFrete)
    let vlrNotaFiscal = this.infoPgtoAdicional.vlrTotalNota > 0;


    this.isHabilitaBotaoCalcular = isTipoPagamentoSelecionado &&
      isClassificacaoSelecionada &&
      isTipoServicoSelecionado &&
      isUfPagadorInformada &&
      isUfPgadorValido &&
      vlrNotaFiscal;

      let usuario: string = this._appService.UsuarioLogado.nome;

      if (!isTipoPagamentoSelecionado){
        mensagens.push("Selecione um tipo de pagamento!");
      }

  
      if (!isClassificacaoSelecionada){
        mensagens.push('Selecione uma classificação para o cliente!');
      }
        
  
      if (!isTipoServicoSelecionado){
        mensagens.push("Selecione um tipo de serviço!");
     }
  

      if (!isUfPagadorInformada){
        mensagens.push("A UF do pagador do frete!");
      }
  

      if (!isUfPgadorValido){
        mensagens.push("A UF do pagadorXXXXXXX!");
      }

      if (!vlrNotaFiscal){
        mensagens.push("Informe o valor da NF.");
      }      

      this._msgService.addMessageArray('Info - PIX', mensagens, 'error', LogFrontFuncionalidade.Booking, Operacao.Inclusao);

      mensagens
  }

  limpaVariaveis() {
    //this.infoPgtoAdicional.vlrTotalNota = 0;
    this.infoPgtoAdicional.vlrIcms = 0;
    this.infoPgtoAdicional.vlrTaxaAdicional = 0;
    this.taxasAdicionaisText = '';
    this.txtQrCode = '';
    this.infoPgtoAdicional.vlrBaf = 0;
    this.infoPgtoAdicional.vlrAdValorem = 0;
    this.infoPgtoAdicional.vlrTaxaAdicional = 0;
  }


  ValidarStep(): string[]{
    var mensagens: string[] = [];
    

    this.limpaVariaveis();

    const calculadoraBTN = document.getElementById('calculadoraBTN') as HTMLButtonElement;

    let isTipoPagamentoSelecionado = this.infoPgtoAdicional.idTipoPagto != null;
    let isClassificacaoSelecionada = this.infoPgtoAdicional.idClassificacaoCli != null;
    let isTipoServicoSelecionado = this.infoPgtoAdicional.idTipoPagto != null;
    let isUfPagadorInformada = this.infoPgtoAdicional.ufPagadorFrete.trim().length > 0;
    let isUfPgadorValido = !((this.infoPgtoAdicional.origemModalId == 'PA' && this.infoPgtoAdicional.idTipoPagto == 2) && this.booking.proposta.ufOrigem == this.infoPgtoAdicional.ufPagadorFrete)
    let vlrNotaFiscal = this.infoPgtoAdicional.vlrTotalNota > 0;


    this.isHabilitaBotaoCalcular = isTipoPagamentoSelecionado &&
                                   isClassificacaoSelecionada &&
                                   isTipoServicoSelecionado &&
                                   isUfPagadorInformada &&
                                   isUfPgadorValido &&
                                   vlrNotaFiscal;

    // if (!isTipoPagamentoSelecionado)
    //   this.msgErr.push('Selecione um tipo de pagamento!');

    // if (!isClassificacaoSelecionada)
    //   msgErr.push('Selecione uma classificação para o cliente!');

    // if (!isTipoServicoSelecionado)
    //   msgErr.push('Selecione um tipo de serviço!');    

    // if (!isUfPagadorInformada)
    //   msgErr.push('Informe a UF do pagador!');

    // if (!isUfPgadorValido)
    //   msgErr.push('A UF do pagador do frete deve ser a mesma da origem quando o tipo de pagamento for FOB e Modal PA! XXXXXXXX');    


    return mensagens;
  }  

}
