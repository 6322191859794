import { Component, Input } from '@angular/core';

import { Anel } from '../../../../_models/anel';
import { Porto } from '../../../../_models/porto';
import { AnelRota } from '../../../../_models/anel-rota';

import { MessageService } from '../../../../_services/message.service';

import { Operacao } from 'app/_enums/operacao';
import { SentidoRota } from '../../../../_enums/sentido-rota.enum';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'rotas-anel',
  templateUrl: './rotas-anel.component.html',
  styleUrls: ['./rotas-anel.component.scss']
})
export class RotasAnelComponent {
  @Input() anel: Anel;
  @Input() titulo: string;
  @Input() portos: Porto[];
  @Input() sentido: SentidoRota;
  public rotas: AnelRota[];

  constructor(private _msgService: MessageService) { }

  public CarregarRotas(rotas: AnelRota[]){
    this.rotas = rotas;
  }

  public AdicionarRota(index: number) {
    if (!this.ValidaRota(index))
      return;
      
    if (index > 0){
      this.rotas[index - 1].novo = false;
      this.rotas[index - 1].ultimo = false;
    }
    
    this.rotas[index].ultimo = true;
    this.rotas[index].novo = false;
    this.rotas[index].sentido = this.sentido;
    this.rotas[index].descricaoPortoDestino = this.portos.find(p => p.codigo == this.rotas[index].codigoPortoDestino).descricao;
    if (this.rotas[index].descricaoPortoOrigem == "")
      this.rotas[index].descricaoPortoOrigem = this.portos.find(p => p.codigo == this.rotas[index].codigoPortoOrigem).descricao;

    this.SetUltimoNovo(this.anel.anelID);
  }

  public ExcluirRota(index: number) {
    this.rotas.splice(this.rotas.length - 1, 1);
    this.rotas.splice(index, 1);
    this.SetUltimoNovo(this.anel.anelID);
  }

  public ValidaRota(index: number): boolean {
    if (index == 0 && this.rotas[index].codigoPortoOrigem == 0) {
      this._msgService.addMessage('Adicionar Rota', 'Selecione um Porto Origem!', "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.rotas);
      return false;
    }

    if (index == 0 && this.rotas[index].codigoPortoOrigem == this.rotas[index].codigoPortoDestino) {
      this._msgService.addMessage('Adicionar Rota', 'O Porto Destino deve ser diferente do Porto Origem!', "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.rotas);
      return false;
    }

    if (this.rotas[index].codigoPortoDestino == 0) {
      this._msgService.addMessage('Adicionar Rota', 'Selecione um Porto Destino!', "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.rotas);
      return false;
    }

    let jaUsado = false;
    for (let i = 0; i < this.rotas.length - 1; i++) {
      if (this.rotas[i].codigoPortoOrigem == this.rotas[index].codigoPortoDestino ||
        this.rotas[i].codigoPortoDestino == this.rotas[index].codigoPortoDestino) {
        jaUsado = true;
      }
    }
    if (jaUsado) {
      this._msgService.addMessage('Adicionar Rota', 'Porto já utilizado!', "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.rotas);
      return false;
    }
    return true;
  }

  public SetUltimoNovo(anelID: number) {
    let totalRotas: number;
    let novaRota = new AnelRota();

    if (this.rotas == undefined) {
      this.rotas = [];
      totalRotas = 0;
    }
    else
      totalRotas = this.rotas.length;

    if (totalRotas == 0) {
      novaRota.ultimo = true;
      novaRota.ordem = 1;
      novaRota.codigoPortoOrigem = 0;
      novaRota.descricaoPortoOrigem = "";
    }
    else {
      this.rotas[totalRotas - 1].ultimo = true;
      novaRota.ordem = this.rotas[totalRotas - 1].ordem + 1;
      novaRota.codigoPortoOrigem = this.rotas[totalRotas - 1].codigoPortoDestino;
      novaRota.descricaoPortoOrigem = this.rotas[totalRotas - 1].descricaoPortoDestino;
    }

    novaRota.anelRotaID = 0;
    novaRota.codigoPortoDestino = 0;
    novaRota.descricaoPortoDestino = "";
    novaRota.anelID = anelID;
    novaRota.novo = true;

    this.rotas.push(novaRota);
  }

  public ContemRotas(): boolean{
    return !(this.rotas == undefined || this.rotas.length == 0 || this.rotas[0].novo == true);
  }

  public AdicionaRotaPreenchida(){
    let ultimo = this.rotas.length-1;
    if (this.rotas[ultimo].codigoPortoDestino != 0)
      this.AdicionarRota(ultimo);
  }
}
