import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DatePickerModule } from "app/shared/ng2-datepicker/ng2-datepicker.module";
import { SharedModule } from "app/shared/shared.module";
import { DatepickerService } from "app/_services/datepicker.service";
import { DocumentacaoService } from "app/_services/documentacao.service";
import { NavioViagemService } from "app/_services/navio-viagem.service";
import { OkEntregaService } from "app/_services/ok-entrega.service";
import { ParametroService } from "app/_services/parametro.service";
import { UsuarioService } from "app/_services/usuario.service";
import { DocumentacaoComponent } from "./documentacao.component";
import { DocumentacaoRoutingModule } from "./documentacao.routing.module";
import { FiltroDocumentacaoComponent } from "./filtro-documentacao/filtro-documentacao.component";
import { DownloadDocumentacaoComponent } from "./lista-documentacao/item-documentacao/download-documentacao/download-documentacao.component";
import { ItemDocumentacaoComponent } from "./lista-documentacao/item-documentacao/item-documentacao.component";
import { ListaDocumentacaoComponent } from "./lista-documentacao/lista-documentacao.component";
import { OrdenacaoDocumentacaoComponent } from "./ordenacao-documentacao/ordenacao-documentacao.component";


@NgModule({
  declarations: [
    DocumentacaoComponent,
    FiltroDocumentacaoComponent,
    OrdenacaoDocumentacaoComponent,
    ListaDocumentacaoComponent,
    ItemDocumentacaoComponent,
    DownloadDocumentacaoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    DatePickerModule,
    DocumentacaoRoutingModule
  ],
  exports: [
    DocumentacaoComponent
  ],
  providers:[
    DocumentacaoService,
    NavioViagemService,
    ParametroService,
    UsuarioService,
    DatepickerService,
    OkEntregaService
  ]
})
export class DocumentacaoModule { }
