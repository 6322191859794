import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { SharedService } from './../../../../_services/shared.service';
import { MessageService } from './../../../../_services/message.service';
import { DatepickerService } from 'app/_services/datepicker.service';
import { UploadArquivoService } from 'app/_services/upload-arquivo.service';

import { Feriado } from 'app/_models/feriado';
import { Booking } from 'app/_models/booking';
import { Schedule } from './../../../../_models/schedule';
import { AlteracoesArquivoUpload } from 'app/_models/alteracoes-arquivo-upload';

import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { BaseComponent } from './../../../../shared/base.component';
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { UploadArquivosComponent } from 'app/shared/uploadarquivos/uploadarquivos.component';
import { BuscaViagensBookingViewModel } from 'app/_models/busca-viagens-booking';
import { BookingService } from 'app/_services/booking.service';

@Component({
  selector: 'step4-dados-reserva',
  templateUrl: './step4-dados-reserva.component.html',
  styleUrls: ['./step4-dados-reserva.component.scss']
})
export class Step4DadosReservaComponent extends BaseComponent implements OnInit {

  @Input() novoBooking: boolean;
  @Input() booking: Booking;
  @Input() progNavios: Schedule;
  @Input() feriados: Feriado[];
  @Input() qtdeHorasAgendamentoPA: number;
  @Input() qtdeHorasAgendamentoPO: number;

  @ViewChild(UploadArquivosComponent, {static: true}) uploadarquivos: UploadArquivosComponent;

  tipoPeriodo: string;
  selectedDate: DateModel;
  options: DatePickerOptions;
  programacao: Schedule[] = [];
  idViagemSelecionada: number;
  programacaoSelecionada: Schedule;
  loadingNavios: boolean = false;
  abaCarregada: boolean = false;
  bloqueia:  boolean = false;
  uploading: boolean = false;
  isBookingSaved: boolean = false;


  constructor(private _bookingService: BookingService,
              private _sharedService: SharedService,
              private _msgService: MessageService,
              private _datePickerService: DatepickerService,
              private _uploadService: UploadArquivoService) {
    super();
    this.tipoPeriodo = 'embarque';
    this.options = _datePickerService.GenerateOptionsConfig(new Date());
  }

  ngOnInit() {
    this.bloqueia = (this.progNavios != undefined);
  }

  public BuscaViagens() {

    if (this.isBookingSaved)
      return;

    this.idViagemSelecionada = null;
    this.programacaoSelecionada = null;
    this.programacao = [];

    var request = new BuscaViagensBookingViewModel();
    request.Modal = this.booking.modal;
    request.IdPortoOrigem = this.booking.idPortoOri;
    request.IdPortoDestino = this.booking.idPortoDes;
    request.DataReferencia = (this.booking.oriPorto) ? this._sharedService.CalculaProximoDiaUtil(this.feriados, new Date(), this.qtdeHorasAgendamentoPO) : this.getMaiorData();
    request.TipoPeriodo = this.tipoPeriodo;
    if (this.selectedDate)
      request.DataSelecionada = this.selectedDate.momentObj.toDate();

    this.loadingNavios = true;
    this._bookingService.buscaViagens(request)
      .subscribe(p => {
        if (p != null) {
          this.programacao = p;
          if ((this.programacao == undefined) || (this.programacao.length == 0)) {
              var msg = (this.booking.oriPorto) ? "Não existem navios de embarque disponíveis com o período e data informados" :
                  'Não foram encontradas viagens com as datas de agendamento informadas.';
              this._msgService.addMessage('Booking', msg, 'error', LogFrontFuncionalidade.Booking, Operacao.Consulta, this.booking);
          }
        }

        if ((this.programacao != undefined) && (this.programacao.length > 0))
          this.idViagemSelecionada = this.programacao[0].idViagemNavio;

        this.loadingNavios = false;
        this.abaCarregada = true;
        this.ExibeDataViagem();
      });
  }

  ExibeDataViagem() {
    if (this.programacao != undefined && this.programacao.length > 0) {
      this.programacaoSelecionada = this.programacao.find(p => p.idViagemNavio == this.idViagemSelecionada && p.idPortoOrigem == this.booking.idPortoOri && p.idPortoDestino == this.booking.idPortoDes);
      this.booking.idViagemNavio = this.idViagemSelecionada;
      this.booking.idNavioViagem = this.idViagemSelecionada;
      this.booking.idPortoTransbordo = this.programacaoSelecionada.idPortoTransbordo;
      this.booking.idNavioTransbordo = this.programacaoSelecionada.idNavioTransbordo;

      this.booking.dhDeadlineEntregaEmbarque = this.programacaoSelecionada.dhDeadlineEntregaEmbarque;

      this.booking.strDataDeadlineEntregaEmb = this.programacaoSelecionada.strDeadlineEntregaEmbarque;

      this.booking.transbordos = this.programacaoSelecionada.Transbordos;
    }
  }

  CarregaViagemBooking(booking: Booking){
    this.programacaoSelecionada = new Schedule();
    this.programacaoSelecionada.dhPrevChegadaPortoOrigem = booking.dataPrevChegadaOri;
    this.programacaoSelecionada.dhPrevChegadaPortoDestino = booking.dataPrevChegadaDes;
    this.programacaoSelecionada.dhDeadlineEntregaEmbarque = booking.dataDeadlineEntregaEmb;
    this.programacaoSelecionada.strPrevChegadaPortoOrigem = booking.strDataPrevChegadaOri;
    this.programacaoSelecionada.strPrevChegadaPortoDestino = booking.strDataPrevChegadaDes;
    this.programacaoSelecionada.strDeadlineEntregaEmbarque = booking.strDataDeadlineEntregaEmb;
    this.programacaoSelecionada.Transbordos = booking.transbordos;
  }

  getMaiorData(): Date {
    let value: string = 'dataColeta';
    if ((this.booking.agendamentosDeColeta != null) && (this.booking.agendamentosDeColeta != undefined)) {
      var array = this.booking.agendamentosDeColeta.sort(function (a, b) {
        if (!value) {
          return a > b ? 1 : -1;
        }
        return a[value] > b[value] ? 1 : -1;
      });

      const dataColeta = array[array.length - 1].dataColeta;

      if (dataColeta) {
        return this._sharedService.CalculaDataMinimaAgendamento(this.feriados, dataColeta, this.qtdeHorasAgendamentoPA);
      }
    }
    return new Date();
  }

  get permiteUploadOOG(): boolean{
    return this.booking.cargaOver && (this.novoBooking || (!this.novoBooking && (this.booking.statusBooking.toLowerCase().indexOf('pendente') > 0)));
  }

  get permiteUploadIMO(): boolean{
    return this.booking.cargaPerigosa && (this.novoBooking || (!this.novoBooking && (this.booking.statusBooking.toLowerCase().indexOf('pendente') > 0)));
  }

  get permiteUploadCliente(): boolean{
    return this.booking.pagtoAVista && (this.novoBooking || (!this.novoBooking && (this.booking.statusBooking.toLowerCase().indexOf('pendente') > 0)));
  }

  salvarAlteracoesUpload(alteracoes: AlteracoesArquivoUpload, tipo: string){
    this.uploading = true;
    this._uploadService.salvarAlteracoesUpload(alteracoes, 'booking', tipo, Number(this.booking.numBooking))
      .subscribe(result => {
        this.uploading = false;
        if (!result.IsValid){
          this._msgService.addMessageRedirect('Booking', 'Upload do Booking ' + Number(this.booking.numBooking) + ' concluído com sucesso!', 'success');
          this.uploadarquivos.marcarAlteracoesComoSalvas();
        }
        else {
          this._msgService.addMessageRedirect('Booking', 'Booking ' + Number(this.booking.numBooking) + ' gerado com sucesso, mas os anexos não foram incluídos. Por favor, tente anexar os documentos novamente!',
              'error', LogFrontFuncionalidade.Booking, Operacao.Upload, alteracoes);
        }
      });
  }

  get exibeComboViagens(): boolean {
    return (!this.programacao) || this.programacao.length > 1;
  }

  get descricaoNavioViagemProgramacao(): string {
    return (this.programacao) && this.programacao.length >= 1 ? this.programacao[0].descNavioViagem : '';
  }
}
