import { Component, OnInit, ViewChild } from '@angular/core';

import { Log } from './../../_models/log';
import { LogService } from './../../_services/log.service';
import { PaginacaoComponent } from './../../shared/paginacao/paginacao.component';
import { NivelLog } from 'app/_enums/nivel-log';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

  @ViewChild(PaginacaoComponent, { static: true }) paginacao: PaginacaoComponent;

  constructor(private _logService: LogService) { }

  lista: Log[];
  loading: boolean = false;
  filtro: string = "";

  ngOnInit() {
    this.ObterLista();
  }

  ObterLista() {
    this.loading = true;
    this._logService.getLogs(this.filtro)
      .subscribe(lista => {
        this.lista = lista;
        if (this.lista.length == undefined) {
          let newList: Log[] = [];
          let item  = <Log><object>this.lista;
          newList.push(item);
          this.lista = newList;
        }
        this.paginacao.setAllData(this.lista);
        this.paginacao.setPage(1);
        this.loading = false;
      });
  }

  getDescricaoNivel(nivel: NivelLog): string {
    return this._logService.getDescricaoNivel(nivel);
  }
}
