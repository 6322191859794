import { Component, Input, OnInit, ViewChild, NgModule } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MessageService } from "./../../../_services/message.service";
import { MessageComponent } from "./../../../shared/message/message.component";
import { DataService } from "./../../../_services/data.service";
import { SharedService } from "./../../../_services/shared.service";
import { BaseComponent } from "./../../../shared/base.component";

import { Observable } from "rxjs";
import { Subscription } from "rxjs";

//DATEPICKER
import { DatepickerService } from "app/_services/datepicker.service";
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DateExtensions } from 'app/shared/extensions/extensions.module';


// MAERSK
import { ListItem } from "./../../../_models/listItem";
import { LogFrontFuncionalidade } from "app/_enums/log-front-funcionalidade";
import { Operacao } from "app/_enums/operacao";
import { SporadicTaxService } from "./../../../_services/sporadic-tax.service";
import { SporadicTax } from "../../../_models/sporadic-tax";
import { IDateModel } from '../../../shared/ng2-datepicker/ng2-datepicker.component';
import { TipoTaxaEsporadicaService } from "app/_services/tipo-taxa-esporadica.service";
import { TipoTaxaEsporadica } from "app/_models/tipo-taxa-esporadica";
import { PortoService } from "app/_services/porto.service";
import { Porto } from "app/_models/porto";
import { PortoInstantPaymentRequest } from "app/_models/PortoInstantPaymentRequest";
import { forEach } from "jszip";
import { AppService } from "app/_services/app.service";
import { PortoInstantPaymentResponse } from 'app/_models/PortoInstantPaymentResponse';
import { get } from 'selenium-webdriver/http';
import * as moment from "moment";


@Component({
  selector: "taxa-esporadica-detalhe",
  templateUrl: "./taxa-esporadica-detalhe.component.html",
  styleUrls: ["./taxa-esporadica-detalhe.component.scss"],
})
export class TaxaEsporadicaDetalheComponent extends BaseComponent
  implements OnInit {
  inscricaoGet: Subscription;
  @ViewChild(MessageComponent, { static: true }) mensagem: MessageComponent;

  titulo: string = "Nova Taxa Esporádica";
  // //listItem: ListItem;
  isNovaTaxa: boolean = true;

  regioesGeograficas: ListItem[] = new Array();
  tiposModais: ListItem[] = new Array();
  tipoTaxaEsporadicasList: TipoTaxaEsporadica[] = new Array();

  public portosOrigem: PortoInstantPaymentRequest[] = new Array();
  public portosOrigemSendoTrabalhado: PortoInstantPaymentRequest[] = new Array();

  portosDestino: PortoInstantPaymentRequest[] = new Array();
  portosDestinoSendoTrabalhado: PortoInstantPaymentRequest[] = new Array();
  portoOrigemSelecionado: PortoInstantPaymentRequest;
  portoDestinoSelecionado: PortoInstantPaymentRequest;

  sporadicTax: SporadicTax = new SporadicTax();
  taxasParaSalvar: SporadicTax[] = new Array();

  loading: boolean = false;

  inicioDateModel: DateModel;
  dataInicioOptions: DatePickerOptions;
  fimDateModel: DateModel;
  dataFimOptions: DatePickerOptions;


  startDate: Date = new Date();
  expirationDate: Date = new Date();
  isPeriodoFinalInvalido: boolean = false;
  isPeriodoInicialInvalido: boolean = false;
  dataInicioSelecionada: Date;
  dataFimSelecionada: Date;
  startDateModel: DateModel;
  expirationDateModel: DateModel;

  // MAERSK
  id: number = 0;
  qtdDiasFim: number = 365;
  sporadicTaxesList: SporadicTax[] = new Array();
  portoInstantPayment: PortoInstantPaymentRequest;


  constructor(
    private _appService: AppService,
    private _dataService: DataService,
    private _route: ActivatedRoute,
    private _msgService: MessageService,
    private _sharedService: SharedService,
    private _router: Router,
    private _datepickerService: DatepickerService,
    private _sporadicTaxService: SporadicTaxService,
    private route: ActivatedRoute,
    private _tipoTaxaEsporadicaService: TipoTaxaEsporadicaService,
    private _portoService: PortoService
  ) {
    super();

    this._msgService.emitirMensagem.subscribe((msg) => {
      this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
    });


    this.ObterDataFields();

  }


  ngOnInit(): void {

    this.dataInicioSelecionada = new Date();
    this.setDataInicioPeriodo(this.dataInicioSelecionada);

    this.dataFimSelecionada = this._sharedService.addDays(this.dataInicioSelecionada, this.qtdDiasFim);

    this.setDataFimPeriodo(this.dataFimSelecionada);


    this.inscricaoGet = this._route.params.subscribe((params: any) => {
      this.id = params["id"];
      if (this.id) {
        this.titulo = "Editar Taxa Esporádica";
        this.loading = true;

        this.isNovaTaxa = false;
        this.getTaxaEsporadica(this.id);

      }
      else {
        this.titulo = "Nova Taxa Esporádica";
        this.sporadicTax = new SporadicTax();
        this.isNovaTaxa = true;
      }
    });
  }

  // Eventos dos Objetos
  OnChangeSporadicTaxTypeCmb(value: any) {
    this.sporadicTax.sporadicTaxTypeId = parseInt(value.target.value);
    //this.setProximoInicioValido();
  }


  OnChangeOriginRegionCmb(value: any) {
    
    //this.setProximoInicioValido();

    this.portosOrigem = new Array();
    this.portosOrigemSendoTrabalhado = new Array();

    this._portoService.findByRegionId(this.sporadicTax.originRegionId).subscribe((lista) => {
      this.portosOrigem = lista;
      this.portosOrigemSendoTrabalhado = lista;

      console.log(this.portosOrigemSendoTrabalhado);
    });
  }


  OnChangeOriginPortoCmb(value: any) {

    this.portosOrigemSendoTrabalhado = this.getPortoById(this.sporadicTax.originPortId);

  }


  OnChangeOriginModalIdCmb(value: any) {
    this.sporadicTax.originModalId = value.target.value;
    //this.setProximoInicioValido();
  }


  OnChangeDestinationRegionCmb(value: any) {

    //this.setProximoInicioValido();

    this.portosDestino = new Array();
    this.portosDestinoSendoTrabalhado = new Array();

    this._portoService.findByRegionId(this.sporadicTax.destinationRegionId).subscribe((lista) => {
      this.portosDestino = lista;
      this.portosDestinoSendoTrabalhado = lista;
      console.log(this.portosDestinoSendoTrabalhado);
    });

  }


  OnChangeDestinationPortoCmb(value: any) {

    this.portosDestinoSendoTrabalhado = this.getPortoById(this.sporadicTax.destinationPortId);

  }


  getPortoById(portoId: number): PortoInstantPaymentRequest[] {
    let listaPortos: PortoInstantPaymentRequest[] = new Array();

    this._portoService.getById(portoId).subscribe((lista) => {
      listaPortos.push(lista);
    });

    return listaPortos;
  }

  OnChangeDestinationModalCmb(value: any) {
    this.sporadicTax.destinationModalId = value.target.value;
    //this.setProximoInicioValido();
  }


  // Metodos auxiliares
  ObterDataFields() {
    this._dataService.getRegiaoGeografica().subscribe((lista) => {
      this.regioesGeograficas = lista;
    });

    this._dataService.getTipoModal().subscribe((lista) => {
      this.tiposModais = lista;
    });


    this._tipoTaxaEsporadicaService.getAll().subscribe((lista) => {
      this.tipoTaxaEsporadicasList = lista;
    });

  }


  private ObterData(dateModel: DateModel, dateOffset: number) {
    return dateModel != undefined
      ? this._datepickerService.ObtemData(dateModel)
      : this._sharedService.addDays(new Date(), dateOffset);
  }



  async onSaveTaxaEsporadica() {
    this.loading = true;
    this._msgService.clearMessage();
    var msgErr: string[] = [];


    if( this.validacoes() == false)
      {
        this.loading = false;
        return;
      }

    this.sporadicTax.createdBy =  this._appService.UsuarioLogado.nome;
    
    let startDate: Date = moment( this._datepickerService.ObtemData(this.startDateModel, 'DD/MM/YYYY'), 'DD/MM/YYYY').toDate();
    let expirationDate: Date =  moment( this._datepickerService.ObtemData(this.expirationDateModel, 'DD/MM/YYYY'), 'DD/MM/YYYY').toDate(); //this._datepickerService.ObtemData(this.expirationDateModel, 'DD/MM/YYYY');
    


    this.sporadicTax.startDate =  startDate;
    this.sporadicTax.expirationDate =  expirationDate;

    //Verifica se checkbox venceTaxaVigente está selecionado
    let venceTaxaVigenteCheckBox: any = document.getElementById('venceTaxaVigente')

    // if( venceTaxaVigenteCheckBox.checked){
    //   this.sporadicTax.acao = 'VENCE';
    // }
    // else{
    //   this.sporadicTax.acao = '';
    // }

    
    

    this.sporadicTax.acao = 'VENCE';



    this._sporadicTaxService.salvar(this.sporadicTax).subscribe((result) => {

      if (result.IsValid) {
        this._msgService.addMessageRedirect("Taxa Esporádica", "Registros salvos com sucesso.", "success");
        this._router.navigate(["taxa-esporadica"]);
      }
      else {
        this.loading = false;
        result.Erros.forEach((element) => msgErr.push(element.Message));
      }

    });
  }


  getTaxaEsporadica(id: number) {
    this._sporadicTaxService
      .getSporadicTax(this.id)
      .subscribe((sporadicTax) => {


        this.sporadicTax = sporadicTax;

        var data = sporadicTax.startDate.toString();

        let _year = parseInt(data.substr(0, 4));
        let _month = parseInt(data.toString().substr(5, 2)) - 1;
        let _day = parseInt(data.toString().substr(8, 2));

        this.startDate = new Date(_year, _month, _day);
        this.startDate.setDate(this.startDate.getDate());

        this.setDataInicioPeriodo(this.startDate);


        //Data de Expiração
        data = sporadicTax.expirationDate.toString();


        _year = parseInt(data.substr(0, 4));
        _month = parseInt(data.toString().substr(5, 2)) - 1;
        _day = parseInt(data.toString().substr(8, 2));

        this.expirationDate = new Date(_year, _month, _day);
        this.expirationDate.setDate(this.expirationDate.getDate());

        this.setDataFimPeriodo(this.expirationDate);

        sporadicTax.containerType20Value = parseFloat(sporadicTax.containerType20Value.toString().replace(/\./g, "").replace(',', '.'));
        sporadicTax.containerType40Value = parseFloat(sporadicTax.containerType40Value.toString().replace(/\./g, "").replace(',', '.'));


        this._portoService.findByRegionId(sporadicTax.originRegionId).subscribe((lista) => {
          this.portosOrigem = lista;
        });


        this._portoService.findByRegionId(sporadicTax.destinationRegionId).subscribe((lista) => {
          this.portosDestino = lista;
        });


        this.portosOrigemSendoTrabalhado = this.getPortoById(this.sporadicTax.originPortId);
        this.portosDestinoSendoTrabalhado = this.getPortoById(this.sporadicTax.destinationPortId);

        this.loading = false;

      });
  }


  validaDataInicial() {

    this.isPeriodoFinalInvalido = false;

    let _dataInicial = this.getDate(this.inicioDateModel);

    // if (_dataInicial < this.startDate) {
    //   this.dataInicioOptions = this._datepickerService.GenerateOptionsConfig(this.startDate);
    //   this.inicioDateModel = DateExtensions.toDateModel(this.startDate, "DD/MM/YYYY");
    //   //this.dataInicioOptions.minDate = this.startDate;
    //   _dataInicial = this.startDate;
    // }

    this.setDataFimPeriodo(_dataInicial);

  }


  validaDataFinal() {

    this.isPeriodoInicialInvalido = false;

    let _dataInicial = this.getDate(this.inicioDateModel);
    let _dataFinal: Date = this.getDate(this.fimDateModel);

    this.isPeriodoFinalInvalido = _dataFinal <= _dataInicial;

  }


  setProximoInicioValido() {
    let isSporadicTaxTypeSelected: boolean = this.sporadicTax.sporadicTaxTypeId > 0;
    let isOriginRegionSelected: boolean = this.sporadicTax.originPortId > 0;
    let isOriginModalSelected: boolean = this.sporadicTax.originModalId != '';
    let isDestinationRegionSelected: boolean = this.sporadicTax.destinationPortId > 0;
    let isDestinationModaSelected: boolean = this.sporadicTax.destinationModalId != '';

    if (isSporadicTaxTypeSelected && isOriginRegionSelected && isOriginModalSelected && isDestinationRegionSelected && isDestinationModaSelected) {
      this._sporadicTaxService
        .findLastExpirationDate(this.sporadicTax.sporadicTaxTypeId, this.sporadicTax.originPortId, this.sporadicTax.originModalId, this.sporadicTax.destinationPortId, this.sporadicTax.destinationModalId)
        .subscribe((sporadicTax) => {

          let _year: number = 0;
          let _month: number = 0;
          let _day: number = 0;

          if (sporadicTax != null) {

            if (sporadicTax.expirationDate < new Date())
              this.startDate = new Date();
            else {
              var data = sporadicTax.expirationDate.toString();

              _year = parseInt(data.substr(0, 4));
              _month = parseInt(data.toString().substr(5, 2));
              _day = parseInt(data.toString().substr(8, 2));

              this.startDate = new Date(_year, _month - 1, _day);
              this.startDate.setDate(this.startDate.getDate() + 1);
            }


            this.setDataInicioPeriodo(this.startDate);


            this.loading = false;
          }
          else {
            this.startDate = new Date();
            _year = this.startDate.getFullYear();
            _month = this.startDate.getMonth();
            _day = this.startDate.getDay();

            this.dataInicioOptions = this._datepickerService.GenerateOptionsConfig(this.startDate);
            this.inicioDateModel = DateExtensions.toDateModel(this.startDate, "DD/MM/YYYY");
            //this.dataInicioOptions.minDate = this.startDate;
          }

          let _dataFim: Date = this._sharedService.addDays(this.startDate, this.qtdDiasFim);

          this.setDataFimPeriodo(_dataFim);

          // this.dataFimOptions = this._datepickerService.GenerateOptionsConfig( _dataFim);
          // this.fimDateModel = DateExtensions.toDateModel(this._sharedService.addDays(this.startDate, this.qtdDiasFim), "DD/MM/YYYY");

        });
    }
    else {
      console.log('Selecione todos os ids.');
    }
  }


  setDataInicioPeriodo(novaData: Date) {

    this.inicioDateModel = new DateModel()
    this.dataInicioOptions = this._datepickerService.GenerateOptionsConfig(novaData);
    //this.dataInicioOptions.minDate = novaData;
    this.dataInicioOptions.initialDate = novaData;

    this.inicioDateModel = DateExtensions.toDateModel(novaData, "DD/MM/YYYY");
    this.inicioDateModel.day = novaData.getDate().toString();
    this.inicioDateModel.month = (novaData.getMonth() + 1).toString();
    this.inicioDateModel.year = novaData.getFullYear().toString();

    this.startDateModel = this.inicioDateModel;
  }


  setDataFimPeriodo(novaData: Date) {
    this.fimDateModel = new DateModel();
    this.dataFimOptions = this._datepickerService.GenerateOptionsConfig(novaData);
    // this.dataFimOptions.minDate = this.startDate;
    this.fimDateModel = DateExtensions.toDateModel(novaData, "DD/MM/YYYY");
    this.fimDateModel.day = novaData.getDate().toString();
    this.fimDateModel.month = novaData.getMonth().toString();
    this.fimDateModel.year = novaData.getFullYear().toString();

    this.expirationDateModel = this.fimDateModel;
  }


  getDate(dateModel: DateModel): Date {
    let _year: number = parseInt(dateModel.year);
    let _month: number = parseInt(dateModel.month);
    let _day: number = parseInt(dateModel.day);

    let dataTmp: Date = new Date(_year, _month - 1, _day);

    return dataTmp;

  }


  getToday(): Date {
    let dia = new Date().getDate();
    let mes = new Date().getMonth();
    let ano = new Date().getFullYear();

    return new Date(ano, mes, dia);;
  }

  validacoes(): boolean  {
    var mensagens: string[] = [];

    let isNomeTaxaPreenchido =  this.sporadicTax.sporadicTaxName.length > 0;
    let isTipoTaxaSelecionada = this.sporadicTax.sporadicTaxTypeId > 0;

      if (!isNomeTaxaPreenchido){
        mensagens.push("Selecione um tipo de pagamento!");
      }

  
      if (!isTipoTaxaSelecionada){
        mensagens.push('Selecione uma classificação para o cliente!');
      }
        
  
    //   if (!isTipoServicoSelecionado){
    //     mensagens.push("Selecione um tipo de serviço!");
    //  }
  

    //   if (!isUfPagadorInformada){
    //     mensagens.push("A UF do pagador do frete!");
    //   }
  

    //   if (!isUfPgadorValido){
    //     mensagens.push("A UF do pagadorXXXXXXX!");
    //   }

    //   if (!vlrNotaFiscal){
    //     mensagens.push("Informe o valor da NF.");
    //   }      

    //   this._msgService.addMessageArray('Info - PIX', mensagens, 'error', LogFrontFuncionalidade.Booking, Operacao.Inclusao);


    return mensagens.length == 0;
    
  }
}
