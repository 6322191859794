import { RouterModule, Routes } from '@angular/router';

import { CotacaoComponent } from './cotacao.component';
import { NgModule } from '@angular/core';
import { SolicitarCotacaoComponent } from './solicitar-cotacao/solicitar-cotacao.component';
import { AuthGuard } from 'app/_guards/auth.guard';

const cotacaoRoutes: Routes = [
    { path: '', component: CotacaoComponent, canActivate: [AuthGuard] },    
    // { path: 'solicitar', component: SolicitarCotacaoComponent, canActivate: [AuthGuard] },
    // { path: 'solicitar/:id/:idTrecho', component: SolicitarCotacaoComponent, canActivate: [AuthGuard] },
    // { path: 'solicitar/:id/:idTrecho/:duplicar', component: SolicitarCotacaoComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(cotacaoRoutes)],
    exports: [RouterModule]
})
export class CotacaoRoutingModule {}