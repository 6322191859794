
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';

import { BaseService } from "app/_services/base.service";
import { NavioViagem } from '../_models/navioViagem';
import { NavioViagemRequest } from '../_models/navioViagem-request';
import { ValidationResult } from "app/_models/validation-result";
import { Console } from 'console';

@Injectable({
    providedIn: 'root'
})
export class NavioViagemService {

  constructor(private baseService: BaseService) { }

  getNavioViagem(consulta: NavioViagemRequest): Observable<NavioViagem[]> {
      return this.baseService.Post('navioviagem', consulta).pipe(
          map((response: ValidationResult) => {
              let retorno: ValidationResult = response;
              if (retorno.IsValid)
                  return retorno.Data as NavioViagem[];
              else
                  return null;
          }),catchError(e => {
              return this.baseService.handleError(e);
          }),);
  }

}
