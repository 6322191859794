import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { DadosAba3 } from './dados-aba-3';
import { Booking } from '../../../../_models/booking';
import { Schedule } from './../../../../_models/schedule';
import { Endereco } from './../../../../_models/endereco';
import { AgendamentoColeta } from "app/_models/agendamento-coleta";
import { PropostaComercial } from '../../../../_models/proposta-comercial';

import { AppService } from './../../../../_services/app.service';
import { SharedService } from './../../../../_services/shared.service';
import { BaseComponent } from '../../../../shared/base.component';
import { DatepickerService } from "app/_services/datepicker.service";
import { DateExtensions } from 'app/shared/extensions/extensions.module';

import { EnderecoComponent } from 'app/shared/endereco/endereco.component';
import { ListaAgendamentoComponent } from './lista-agendamento/lista-agendamento.component';
import { Feriado } from 'app/_models/feriado';
import { DateModel } from '../../../../shared/ng2-datepicker/ng2-datepicker.module';

@Component({
  selector: 'step3-agendamento-coleta',
  templateUrl: './step3-agendamento-coleta.component.html',
  styleUrls: ['./step3-agendamento-coleta.component.scss']
})
export class Step3AgendamentoColetaComponent extends BaseComponent implements OnInit {

  constructor(private _datepickerService: DatepickerService,
    private _appService: AppService,
    private _shared: SharedService) {
      super();
      this.dadosAba3 = new DadosAba3();
      this.permiteEditarEndereco = true;
  }

  ngOnInit() { }

  @ViewChild(ListaAgendamentoComponent, {static: true}) listaAgendamento: ListaAgendamentoComponent;
  @ViewChild(EnderecoComponent, {static: true}) enderecoColeta: EnderecoComponent;
  @Input() booking: Booking;
  @Input() novoBooking: boolean;
  @Input() edicaoBooking: boolean;
  @Input() erroBooking: boolean = false;
  @Input() qtdeContainersInicial: number;
  @Input() progNavios: Schedule;
  @Input() qtdeHorasAgendamentoPA: number;
  @Input() qtdeMinHorasAgend: number;
  @Input() feriados: Feriado[];

  telefoneAgendaColeta: string = '';
  //telefoneAgendaColetaBKP: string = '';
  //contatoBKP: string = '';
  primeiraVezNaAba: boolean = true;
  dataMinima: Date;

  mascaraCep = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/];
  public dadosAba3: DadosAba3;
  public permiteEditarEndereco: boolean;
  public listaDeEnderecos: Endereco[] = [];
  public enderecoSelecionado: Endereco;

  public carregarProposta(proposta: PropostaComercial, mantemAgendamentos: boolean){
    this.primeiraVezNaAba = true;
    this.dadosAba3 = new DadosAba3();
    this.listaAgendamento.ConfiguraProposta(this.booking, proposta, mantemAgendamentos);
  }

  public CarregaEndereco() {
    if (!this.primeiraVezNaAba)
      return;
    this.primeiraVezNaAba = false;
    this.dataMinima = this._shared.CalculaDataMinimaAgendamento(this.feriados, new Date(), this.qtdeMinHorasAgend);

    //console.log(this.booking.Embarcador);
    var agendamentos = this.booking.agendamentosDeColeta;
    if (!this.listaDeEnderecos || this.listaDeEnderecos.length == 0)
      this.listaDeEnderecos = (!this.booking.embarcador || !this.booking.embarcador.enderecos) ? null : this.booking.embarcador.enderecos;

    //Caso existam agendamentos prévios, carrega na aba os dados do
    //primeiro agendamento, senão carrega os dados da proposta
    var existe = ((agendamentos != undefined) && (agendamentos.filter(a => a.numAgendamento != 0).length > 0));
    this.permiteEditarEndereco = (!existe && (this.edicaoBooking || this.novoBooking));
    if (existe){ //exibe dados vindos do agendamento salvo no ecargo
      this.dadosAba3.cep = agendamentos[0].cepCol;
      this.dadosAba3.logradouro = agendamentos[0].logradouroCol;
      this.dadosAba3.numero = agendamentos[0].numeroEndCol;
      this.dadosAba3.complemento = agendamentos[0].complementoCol;
      this.dadosAba3.bairro = agendamentos[0].bairroCol;
      this.dadosAba3.idMunicipio = agendamentos[0].idMunicipioCol;
      this.dadosAba3.municipio = agendamentos[0].municipioCol.trim();
      this.dadosAba3.uf = agendamentos[0].ufCol;
      this.dadosAba3.contato = agendamentos[0].contatoCol;
      this.dadosAba3.ddd = agendamentos[0].dddCol;
      this.dadosAba3.telefone = agendamentos[0].foneCol;
      this.dadosAba3.observacoes = agendamentos[0].observacao;
      this.telefoneAgendaColeta = agendamentos[0].dddCol+agendamentos[0].foneCol;
    }
    else {
      this.dadosAba3.idMunicipio = this.booking.idMunicOri;
      this.dadosAba3.municipio = this.booking.municOri.trim();
      this.dadosAba3.uf = this.booking.ufMunicOri;

      //se for da mesma cidade do embarcador, copia os dados
      var mesmaCidade = (this.booking.idMunicOri == this.booking.idMunicEmb);

      this.dadosAba3.cep = (this.dadosAba3.cep) ? this.dadosAba3.cep : mesmaCidade ? this.booking.cepEmb : '';
      this.dadosAba3.logradouro = (this.dadosAba3.logradouro) ? this.dadosAba3.logradouro : mesmaCidade ? this.booking.logradouroEmb : '';
      this.dadosAba3.numero = (this.dadosAba3.numero) ? this.dadosAba3.numero : mesmaCidade ? this.booking.numeroEndEmb : '';
      this.dadosAba3.complemento = (this.dadosAba3.complemento) ? this.dadosAba3.complemento : mesmaCidade ? this.booking.complementoEmb : '';
      this.dadosAba3.bairro = (this.dadosAba3.bairro) ? this.dadosAba3.bairro : mesmaCidade ? this.booking.bairroEmb : '';
      this.telefoneAgendaColeta = (this.telefoneAgendaColeta) ? this.telefoneAgendaColeta : mesmaCidade ? this.booking.telefoneEmbarcadorComDDD : '';
      this.dadosAba3.observacoes = (this.dadosAba3.observacoes) ? this.dadosAba3.observacoes : '';
      this.dadosAba3.contato = this.booking.contatoEmb;
      this.dadosAba3.ddd = this.booking.dddEmb;
      this.dadosAba3.telefone = this.booking.foneEmb;
      this.telefoneAgendaColeta = this.booking.dddEmb + this.booking.foneEmb;
    }
    this.enderecoSelecionado = new Endereco();
    this.enderecoSelecionado.cep = this.dadosAba3.cep;
    this.enderecoSelecionado.bairro = this.dadosAba3.bairro;
    this.enderecoSelecionado.complemento = this.dadosAba3.complemento;
    this.enderecoSelecionado.idMunicipio = this.dadosAba3.idMunicipio;
    this.enderecoSelecionado.logradouro = this.dadosAba3.logradouro;
    this.enderecoSelecionado.municipio = this.dadosAba3.municipio;
    this.enderecoSelecionado.numero = this.dadosAba3.numero;
    this.enderecoSelecionado.uf = this.dadosAba3.uf;

    this.enderecoColeta.carregaEnderecos(this.enderecoSelecionado, this.listaDeEnderecos);
  }

  public CarregarDadosAgendamentos(){
    if (this.booking.agendamentosDeColeta == undefined)
      return;

    this.booking.agendamentosDeColeta.filter(a => a.numAgendamento == 0).forEach(agendamento => {
      // Carrega os dados da aba em todos os agendamentos
      var endAgend = this.enderecoColeta.obtemEndereco();

      endAgend.cep = endAgend.cep + '';

      agendamento.cepCol = (endAgend && endAgend.cep) ? endAgend.cep.replace('-', '') : this.dadosAba3.cep.replace('-', '');
      agendamento.logradouroCol = (endAgend) ? endAgend.logradouro : this.dadosAba3.logradouro;
      agendamento.numeroEndCol = (endAgend) ? endAgend.numero : this.dadosAba3.numero;
      agendamento.complementoCol = (endAgend) ? endAgend.complemento : this.dadosAba3.complemento;
      agendamento.bairroCol = (endAgend) ? endAgend.bairro : this.dadosAba3.bairro;
      agendamento.municipioCol = (endAgend) ? endAgend.municipio : this.dadosAba3.municipio;
      agendamento.idMunicipioCol = (endAgend) ? endAgend.idMunicipio : this.dadosAba3.idMunicipio;
      agendamento.contatoCol = this.dadosAba3.contato;
      agendamento.dddCol = this.dadosAba3.ddd;
      agendamento.foneCol = this.dadosAba3.telefone;
      agendamento.observacao = this.dadosAba3.observacoes;
      agendamento.ufCol = (endAgend) ? endAgend.uf : this.dadosAba3.uf;
      agendamento.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
      agendamento.categoriaContainer = this.booking.categoriaContainer;
      agendamento.cnpjEmb = this.booking.cnpjEmb;
      agendamento.ieEmb = this.booking.ieEmb;
      agendamento.nomeEmb = this.booking.nomeEmb ? this.booking.nomeEmb : this.booking.nomeEmbarcador;
      agendamento.qtdeContainer = this.booking.qtdeContainer;
      agendamento.tamanhoContainer = this.booking.tamanhoContainer;

      // Configura o campo 'dataColeta'
      this.ConfigurarDataColeta(agendamento);
    });
  }

  public cancelarAdicionarNovo() {
    this.primeiraVezNaAba = true;
    this.enderecoColeta.novo = false;
    this.enderecoColeta.indexSelecionado = -1;
    this.CarregaEndereco();
    //this.dadosAba3.contato = this.contatoBKP;
    //this.telefoneAgendaColeta = this.telefoneAgendaColetaBKP;
  }

  public ConfigurarDataColeta(agendamento: AgendamentoColeta){
    // Se a data selecionada for nula ou inválida, retorna nulo
    if ((agendamento == undefined) ||
        (agendamento.dataSelecionada == undefined) ||
        !this._datepickerService.IsValid(agendamento.dataSelecionada, "DD/MM/YYYY") ||
        (!agendamento.horaColeta)) {

          agendamento.dataSelecionada = new DateModel();
          agendamento.dataSelecionada = DateExtensions.toDateModel(this.dataMinima, "DD/MM/YYYY");
          return;
        }

    // Configura o campo 'dataColeta' baseado na data seleiconada
    // no DatePicker e na hora informada no input
    agendamento.dataColeta = this._datepickerService.ObtemDataHora(agendamento.dataSelecionada, agendamento.horaColeta);
  }

  public ValidarStep(): string[]{
    var mensagens: string[] = [];
    var campos: string[] = [];
    var dataDeadline: Date;

    if (this.progNavios) {
      dataDeadline = this._shared.corrigeData(this.progNavios.dhDeadlineEntregaEmbarque);
    } else {
      if (this.booking.dataDeadlineEntregaEmb && (this.edicaoBooking || this.novoBooking))
        dataDeadline = this.booking.dataDeadlineEntregaEmb; //this._shared.corrigeData(this.booking.DataDeadlineEntregaEmb);
    }

    var endAgend = this.enderecoColeta.obtemEndereco();
    //console.log(endAgend);

    //Verifica campos obrigatórios da aba
    if (!endAgend.cep) campos.push("Cep");
    if (!endAgend.logradouro) campos.push("Logradouro");
    if (!endAgend.numero) campos.push("Número");
    if (!endAgend.bairro) campos.push("Bairro");
    if (!endAgend.municipio) campos.push("Município");
    if (!this.telefoneAgendaColeta) campos.push("Telefone");
    if (!this.dadosAba3.contato) campos.push("Contato");

    //Informa os campos obrigatórios não preenchidos, se houver
    if (campos.length > 0)
      mensagens.push("Campos obrigatórios: " + campos.join(", "));

    if (!this._shared.validaTelefone(this.telefoneAgendaColeta))
        mensagens.push("Telefone inválido");
    else
      this.formataTelefoneEnderecoAgendaColeta(this.telefoneAgendaColeta);

    var i = 0;
    var dataPrimeiraColeta: Date;
    var maiorDataCadastrada: Date;
    var maiorDataNova: Date;
    //console.log('--- booking - agendamento ---');
    //console.log(this.booking);
    //Valida todos os agendamentos
    //console.log(this.progNavios);
    this.booking.agendamentosDeColeta.forEach(agendamento => {

      //Carrega a data do agendamento e flag de novo agendamento
      var agendamentoNovo = (agendamento.numAgendamento == 0);
      var data: Date = agendamentoNovo ? this._datepickerService.ObtemData(agendamento.dataSelecionada) : agendamento.dataColeta;
      var dataAgendamento = this._datepickerService.ObtemDataHora2(data, agendamento.horaColeta);

      //Armazena a data do primeiro agendamento
      if (i == 0)
        dataPrimeiraColeta = dataAgendamento;

      //Valida campos obrigatórios do agendamento
      campos = [];
      if (agendamentoNovo && !agendamento.peso) campos.push("Peso da Carga");
      if (agendamentoNovo && !agendamento.valMercadoria) campos.push("Valor da Carga");
      if (!agendamento.horaColeta) campos.push("Hora da coleta");
      //if (!agendamento.peso) campos.push("Horário");

      //Informa os campos obrigatórios não preenchidos, se houver
      i++;
      if (campos.length > 0)
        mensagens.push("Agendamento #" + i + ", campos obrigatórios: " + campos.join(", "));

      //Informa se a data digitada for nula ou inválida
      if (agendamentoNovo && ((agendamento.dataSelecionada == undefined) || (!this._datepickerService.IsValid(agendamento.dataSelecionada, "DD/MM/YYYY"))))
        mensagens.push("Agendamento #" + i + ", Data da coleta inválida");

      //if (!this.novoBooking && (this.booking.DataPrevChegadaOri != undefined) && (dataAgendamento.getTime() > this.booking.DataPrevChegadaOri.getTime()))
      //  mensagens.push("Agendamento #" + i + ", Data superior a data de Deadline");

      //var dataMinima = this._shared.CalculaDataMinimaAgendamento(this.feriados, new Date(), this.qtdeMinHorasAgend);
      var msgQtdMin = '';
      if (agendamentoNovo && (this.novoBooking || this.edicaoBooking) && dataAgendamento.getTime() < this.dataMinima.getTime())
        msgQtdMin = "Agendamento #" + i + ", A coleta deve ser agendada com " + this.qtdeMinHorasAgend + " horas de antecedência (respeitando finais de semana e feriados). Data/hora no agendamento deve ser superior a " + this.dataMinima.toLocaleString() + ".";
        //msgQtdMin = "Agendamento #" + i + ", A coleta deve ser agendada respeitando " + this.qtdeMinHorasAgend + " horas da data atual. Data/hora no agendamento deve ser superior a " + dataMinima.toLocaleString() + ".";

      //Verifica se data do agendamento é feriado ou final de semana
      //if (agendamentoNovo && this._shared.isFeriadoFds(this.feriados, dataAgendamento))
      //  mensagens.push("Agendamento #" + i + ", A coleta deve ser realizada em dias úteis. O dia " + dataAgendamento.toLocaleString() + " é um final de semana ou feriado!");

      if (agendamentoNovo && dataDeadline) {
        var dataRef = this._shared.addHours(dataDeadline, -this.qtdeHorasAgendamentoPA);
        var hoje = new Date();
        if (hoje.getTime() >= dataRef.getTime())
          mensagens.push("Agendamento #" + i + ", Não é possível fazer agendamentos de coleta para esse navio/viagem pois a data atual ultrapassa o limite de " + this.qtdeHorasAgendamentoPA + " horas de antecedência do deadline.");
        else {
          //se tem mensagem de quantidade mínima de dias, pode mostrar
          if (msgQtdMin != '')
            mensagens.push(msgQtdMin);

          if ((this.novoBooking || this.edicaoBooking) && dataAgendamento.getTime() > dataRef.getTime())
            mensagens.push("Agendamento #" + i + ", A coleta deve ser agendada com " + this.qtdeHorasAgendamentoPA + " horas de antecedência (respeitando finais de semana e feriados). Data/Hora no agendamento deve ser inferior a " + dataRef.toLocaleString() + ".");
        }
      }
      else if (msgQtdMin != '')
        //se tem mensagem de quantidade mínima de dias, pode mostrar
        mensagens.push(msgQtdMin);

    });

    //if ((maiorDataNova != undefined) && (maiorDataCadastrada != undefined) && (maiorDataNova.getTime() > maiorDataCadastrada.getTime()))
    //  mensagens.push("Não é permitido cadastrar uma nova coleta em data e horário superior as coletas já cadastradas");

    return mensagens;
  }

  formataTelefoneEnderecoAgendaColeta(telefone: string): void {
    if (telefone) {
      telefone = telefone.replace(/\D/g, '');
      if (telefone && telefone.length > 9) {
        this.dadosAba3.ddd = this._shared.obtemDDDdoTelefone(telefone);
        this.dadosAba3.telefone = this._shared.obtemTelefoneSemDDD(telefone);
      }
    }
  }

  houveAlteracaoContainers(): boolean {
    return this.booking.qtdeContainer > this.qtdeContainersInicial;
  }

  exibirAdicionarNovo() {
    this.enderecoColeta.novo = true;
    this.enderecoColeta.permiteEditarEndereco = true;

    this.enderecoColeta.endereco = new Endereco();
    this.enderecoColeta.endereco.municipio = this.dadosAba3.municipio;
    this.enderecoColeta.endereco.uf = this.dadosAba3.uf;
    this.enderecoColeta.endereco.idMunicipio = this.dadosAba3.idMunicipio;

    //this.contatoBKP = this.dadosAba3.contato;
    //this.telefoneAgendaColetaBKP = this.telefoneAgendaColeta;

    //this.telefoneAgendaColeta = '';
    //this.dadosAba3.contato = '';
  }
}
