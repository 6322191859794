export class TrackingListaRequest {
  cnpjCli: string;
  filtroGenerico: string;
  filtroGenerico2: string;
  numBooking: string;
  idsPortoOri: string;
  idsPortoDes: string;
  idsMunicOri: string;
  idsMunicDes: string;
  idUsuario: number;
  idNavioViagem: number;
  dataIni: Date;
  dataFim: Date;
  status: boolean;
  idBooking: number;
  numContainer: string;
}
